import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip } from "@mui/material";
import { format } from "date-fns";

interface OverdueIconProps {
  dueDate?: string;
}

const OverdueIcon = (props: OverdueIconProps) => {
  const { dueDate } = props;
  const due = new Date(dueDate ? dueDate : "");

  //   const due_status =
  //     userItem.status === "COMPLETE"
  //       ? "COMPLETE"
  //       : getOverdueStatus(userItem.due_date);

  const readableDate = format(due, "dd/MM/yyyy");
  return (
    <>
      <Tooltip
        title={
          <>
            Overdue
            <br /> Due Date: {readableDate}
          </>
        }
      >
        <WarningIcon sx={{ fontSize: "1rem", color: "#f44336" }} />
      </Tooltip>
    </>
  );
};

export default OverdueIcon;
