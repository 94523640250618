import { OPERATIONTYPES } from "../../../types/enums";
import audiencePrepareForSubmit from "../../audience/utils/audiencePrepareForSubmit";
import { rulePrepareForSubmit } from "../../rules";
import itemPrepareItemDataForSubmit from "./itemPrepareItemDataForSubmit";

interface ItemPreparedForSubmitOptionsProps {
  operation: string;
  user?: string;
  adminClevel?: string;
}

const itemPrepareForSubmit = (
  item: ItemCompleteFormProps | undefined,
  options: ItemPreparedForSubmitOptionsProps
) => {
  if (!!item) {
    let itemPreparedForSubmit: ItemSubmittable = {
      ...item,
      item_audience: item.item_audience
        ?.map((audience) => audiencePrepareForSubmit(audience))
        .filter((audience) => audience !== undefined),
      item_rule: item.item_rule
        ?.map((rule) => rulePrepareForSubmit(rule))
        .filter((rule) => rule !== undefined),
      item_data: itemPrepareItemDataForSubmit(
        item.item_data,
        item.item_type,
        options.operation
      )
    };

    if (options?.operation === OPERATIONTYPES.CREATE.KEY) {
      delete itemPreparedForSubmit["id"];
      delete itemPreparedForSubmit["created_by"];
      delete itemPreparedForSubmit["created_date"];
      delete itemPreparedForSubmit["deleted_flag"];
      delete itemPreparedForSubmit["last_updated_timestamp"];
      // FIX
      itemPreparedForSubmit.administrative_level = options?.adminClevel;

      if (!!options?.user) itemPreparedForSubmit.created_by = options?.user;
    }

    if (options?.operation === OPERATIONTYPES.UPDATE.KEY) {
      //delete itemPreparedForSubmit["id"];
      delete itemPreparedForSubmit["created_by"];
      delete itemPreparedForSubmit["created_date"];
      delete itemPreparedForSubmit["deleted_flag"];
      delete itemPreparedForSubmit["last_updated_timestamp"];
    }

    return itemPreparedForSubmit;
  }
  return undefined;
};

export default itemPrepareForSubmit;
