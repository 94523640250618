import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import { useState } from "react";
//import { ClientAvatar } from "../../client/sections/ClientAvatar";

import LogoutIcon from "@mui/icons-material/Logout";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import InfoIcon from "@mui/icons-material/Info";
import RateReviewIcon from "@mui/icons-material/RateReview";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import { useLocation, useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { userAdminAtom, userAtom } from "../../../globalstates";
import { ClientAvatar } from "../../../features/client";

const ClientProfileMenu = () => {
  const location = useLocation();
  const user = useAtomValue(userAtom);
  const userAdminState = useAtomValue(userAdminAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <Tooltip title="Menu">
        <IconButton onClick={handleClick}>
          <ClientAvatar />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: "300px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            navigate("/me");
          }}
        >
          <ClientAvatar /> {user?.displayName} (Edit)
          {/* <ListItemIcon>
            
          </ListItemIcon> */}
        </MenuItem>
        {/* <MenuItem>
          
        </MenuItem> */}
        <Divider />
        {location.pathname.indexOf("admin") > -1 && (
          <MenuItem
            onClick={() => {
              navigate("/");
            }}
          >
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            Home
          </MenuItem>
        )}
        {userAdminState.isAdmin &&
          location.pathname.indexOf("admin") === -1 && (
            <MenuItem
              onClick={() => {
                navigate("/admin");
              }}
            >
              <ListItemIcon>
                <BusinessOutlinedIcon />
              </ListItemIcon>
              Admin
            </MenuItem>
          )}

        {userAdminState.isSysAdmin && (
          <MenuItem
            onClick={() => {
              navigate("/preferences");
            }}
          >
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            Preferences
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            navigate("/about");
          }}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          About
        </MenuItem>

        <MenuItem
          // onClick={() => {

          //   window?.open("https://forms.office.com/r/fW8Hmfpa7m", "_blank").focus();
          // }}
          component="a"
          target="_blank"
          href="https://forms.office.com/r/fW8Hmfpa7m"
        >
          <ListItemIcon>
            <RateReviewIcon />
          </ListItemIcon>
          Feedback
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ClientProfileMenu;
