import { Alert, Box, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useItem } from "../../../../../hooks";
import ItemIcon from "../../../components/ui/ItemIcons";

//type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
interface JourneyItemReadOnlyProps {
  //item_id: number;
  item: ItemJourneyGroupItemProps;
}

const JourneyGroupItemDataReadOnly = (props: JourneyItemReadOnlyProps) => {
  const { item } = props;
  //console.log(item);
  //console.log();
  const { item: fetchedItem } = useItem({
    id: item.item_id,
    initialFetch: item?.item ? false : true
  });

  const [itemData, setItemData] = useState<ItemCompleteFormProps>(
    item?.item || fetchedItem
  );

  useEffect(() => {
    //console.log("fetchedItem", fetchedItem);
    //console.log("Fetched Item", fetchedItem);
    if (!!fetchedItem) setItemData(fetchedItem);
  }, [fetchedItem]);

  return (
    <>
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        {item && <ItemIcon name={itemData?.item_type || ""} />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flex: "auto",
            gap: "1rem",
            alignItems: "center"
          }}
        >
          <Box>{item?.sequence_number}.</Box>
          <Box sx={{ fontWeight: "bolder" }}>{itemData?.item_title}</Box>{" "}
          <Box>(ID: {itemData?.id})</Box>
          {itemData?.deleted_flag && (
            <Alert
              variant="filled"
              severity="warning"
              sx={{ padding: "0.2rem 1.2rem" }}
            >
              This Item has been deleted and will not be visible to the user.
            </Alert>
          )}
        </Box>
      </Box>
    </>
  );
};

export default JourneyGroupItemDataReadOnly;
