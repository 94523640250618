import {
  Component,
  ErrorInfo,
  PropsWithChildren,
  ReactNode,
  useState
} from "react";

import { Card } from "@mui/material";

interface Props {
  children: ReactNode;
  errorCode?: string;
  errorDescription?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h2>Unable to display the item. </h2>
          {this.props.errorCode && (
            <p style={{ color: "red" }}>Error Code : {this.props.errorCode}</p>
          )}
          {this.props.errorDescription && (
            <ShowMore>{this.props.errorDescription}</ShowMore>
          )}
        </>
      );
    }

    return this.props.children;
  }
}

function ShowMore(props: PropsWithChildren) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div>
        {!show && (
          <button
            onClick={() => {
              setShow(true);
            }}
          >
            Show More
          </button>
        )}
        {show && (
          <button
            onClick={() => {
              setShow(true);
            }}
            value="Hide"
          >
            Show Less
          </button>
        )}
        {show && <div style={{ padding: "0.4rem" }}>{props.children}</div>}
      </div>
    </>
  );
}
