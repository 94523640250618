const ProfilePage = () => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        Coming Soon. You will be able update your personal information and
        details from this location.
      </div>
    </>
  );
};

export default ProfilePage;
