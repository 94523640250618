import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
  PublicClientApplication
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication
} from "@azure/msal-react";
import { PropsWithChildren, useEffect } from "react";
import LoadingFullScreen from "../../../components/LoadingFullScreen";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_CLIENT_ID as string,
    authority: process.env.REACT_APP_AUTHORITY as string,
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.href,
    state: window.location.search
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: true,
    handleRedirectCallback: (error: any, response: any) => {
      if (error) {
        console.error(error);
      } else {
        console.log(response);

        // Access the state value from the response
        const state = response.state;
        console.log(state);
      }
    }
  }
};

console.info(msalConfig);

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
// export const loginRequest = {
//   scopes: ["User.Read"]
// };

const msalInstance = new PublicClientApplication(msalConfig);

const extraClaims = {
  id_token: {
    auth_time: { essential: true },
    email: null,
    given_name: null,
    family_name: null
  }
};

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  // console.log("ACCOUNT", accounts[0]);
  // if (accounts[0].idTokenClaims?.roles) {
  //   console.log("Account Roles", accounts[0].idTokenClaims?.roles);
  // }
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  //console.log("Login CB", event);
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    //console.info("Login Success");
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    //console.error("Failed to Login");
    //msalInstance.acquireTokenRedirect()
  }
});

//console.info(msalConfig, msalInstance);

const SsoAuth = (props: PropsWithChildren) => {
  return (
    <MsalProvider instance={msalInstance}>
      <SsoInner>{props.children}</SsoInner>
    </MsalProvider>
  );
};

const SsoInner = (props: PropsWithChildren) => {
  // useMsalAuthentication(InteractionType.Redirect, {
  //   claims: JSON.stringify(extraClaims)
  // });
  useMsalAuthentication(InteractionType.Redirect);
  //console.log("SsoInner called");
  //   const isAuth = useIsAuthenticated();
  //   const { instance } = useMsal();
  //   console.log(
  //     isAuth,
  //     instance,
  //     msalInstance.getActiveAccount(),
  //     msalInstance.getAllAccounts()
  //   );
  //   useEffect(() => {
  //     if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
  //       instance.setActiveAccount(instance.getAllAccounts()[0]);
  //     }
  //   }, [instance]);

  return (
    <>
      <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoadingFullScreen title="You are not logged in. Attempting to log you in now..." />
      </UnauthenticatedTemplate>
    </>
  );
};

export default SsoAuth;
