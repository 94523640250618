import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { OPERATION, OPERATIONTYPES } from "../../types/enums";
import { useAtomValue } from "jotai";
import { userAdminAtom, userAtom } from "../../globalstates";
import { upperCaseCompare } from "../../utils";
import useApiPost from "./useApiPost";
import useApiDelete from "./useApiDelete";
import useApiPatch from "./useApiPatch";
import itemPrepareForSubmit from "../../features/items/utils/itemPrepareForSubmit";

const API_PATH = process.env.REACT_APP_API_PATH;

interface SubmittableItem {
  id: string | number;
  item_type: string;
  item_title?: string;
  item_description?: string;
  status?: string;
  due_period: string;
  item_data: any;
  item_audience?: SubmittableAudience[] | null[];
  item_rule?: SubmittableRule[] | null[];
}

interface SubmittableAudience {
  audience_type: string;
  excluded_flag: boolean;
  operation?: string;
  value: string;
  item_id: string | number;
  id?: string | null | number;
}

interface SubmittableRule {
  id: string | number;
  rule_type: string;
  operation?: string;
  priority: number;
  rule_data: string;
  visible: boolean;
  item_id?: string | number;
}

const useItemSubmit = () => {
  const user = useAtomValue(userAtom);
  const userAdminState = useAtomValue(userAdminAtom);
  // const mutation = useMutation((updatedItem: SubmittableItem) => {
  //   return axios.patch(`${API_PATH}/admin/item/${updatedItem.id}`, updatedItem);
  // });

  const mutation = useApiPatch({
    api: (updatedItem: SubmittableItem) => {
      return `${API_PATH}/admin/item/${updatedItem.id}`;
    }
  });

  // const createMutation = useMutation((createdItem: ItemCompleteFormProps) => {
  //   return axios.post(`${API_PATH}/admin/item`, createdItem);
  // });

  const createMutation = useApiPost({
    api: (createdItem: ItemCompleteFormProps) => `${API_PATH}/admin/item`
  });

  // const deleteMutation = useMutation((deleteId: number) => {
  //   return axios.delete(`${API_PATH}/admin/item/${deleteId}`);
  // });

  const deleteMutation = useApiDelete({
    api: (deleteId: number) => {
      return `${API_PATH}/admin/item/${deleteId}`;
    }
  });

  const postItem = (item: ItemCompleteFormProps) => {
    const preparedItem = itemPrepareForSubmit(item, {
      operation: OPERATIONTYPES.UPDATE.KEY,
      user: user?.userPrincipalName
    });

    // const item_audience = item.item_audience?.filter((aud) => {
    //   return aud.operation && !upperCaseCompare(aud.operation, OPERATION.NONE);
    // });

    // const rules = item.item_rule?.map((rule) => {
    //   let ruleIttem: SubmittableRule = {
    //     id: rule.id,
    //     rule_type: rule.rule_type,
    //     operation: rule.operation?.toUpperCase(),
    //     priority: rule.priority,
    //     rule_data: rule.rule_data,
    //     visible: rule.visible,
    //     item_id: item.id
    //   };

    //   return ruleIttem;
    // });

    // const filteredRules = rules?.filter((rule) => {
    //   return rule.operation && rule.operation !== OPERATION.NONE;
    // });

    // const modifiedItem: SubmittableItem = {
    //   id: item.id,
    //   item_title: item.item_title,
    //   item_description: item.item_description,
    //   status: item.status,
    //   item_type: item.item_type,
    //   item_data: item.item_data,
    //   due_period: item.due_period,
    //   item_audience: item_audience?.map((aud) => {
    //     let audItem: SubmittableAudience = {
    //       audience_type: aud.audience_type.toUpperCase(),
    //       excluded_flag: aud.excluded_flag,
    //       operation: aud.operation.toUpperCase(),
    //       value: aud.value,

    //       item_id: item.id
    //     };

    //     if (!upperCaseCompare(aud.operation, OPERATION.CREATE)) {
    //       audItem.id = aud.id;
    //     }

    //     return audItem;
    //   }),
    //   item_rule: filteredRules
    // };
    // console.log("posting Item modifiedItem", modifiedItem);
    //console.log("posting Item preparedItem", preparedItem);

    if (preparedItem) mutation.mutate(preparedItem);
  };

  const createItem = (item: ItemCompleteFormProps) => {
    // const itemToCreate: ItemCompleteFormProps = {
    //   ...item,
    //   ...{
    //     item_type: item.item_type.toUpperCase(),
    //     created_by: user?.mail,
    //     administrative_level: userAdminState?.currentLevel // "1000000000000000"
    //   }
    // };
    const preparedItem = itemPrepareForSubmit(item, {
      operation: "CREATE",
      user: user?.userPrincipalName,
      adminClevel: userAdminState?.currentLevel
    });
    console.log("Value Submitted preparedItem", preparedItem);
    if (preparedItem) createMutation.mutate(preparedItem);
  };

  const deleteItem = (itemId: number) => {
    if (itemId) deleteMutation.mutate(itemId);
  };

  const resetPosting = () => {
    mutation.reset();
  };

  const resetCreate = () => {
    createMutation.reset();
  };

  const [
    isPosting,
    isPostingError,
    isPostingSuccess,
    isCreating,
    isCreateError,
    isCreateSuccess,
    isDeleting,
    isDeleteError,
    isDeleteSuccess
  ] = [
    mutation.isLoading,
    mutation.isError,
    mutation.isSuccess,
    createMutation.isLoading,
    createMutation.isError,
    createMutation.isSuccess,
    deleteMutation.isLoading,
    deleteMutation.isError,
    deleteMutation.isSuccess
  ];

  return {
    isPostingError,
    isPosting,
    isPostingSuccess,
    postItem,
    resetPosting,
    isCreating,
    isCreateError,
    isCreateSuccess,
    createItem,
    resetCreate,
    deleteItem,
    isDeleting,
    isDeleteError,
    isDeleteSuccess
  };
};

export default useItemSubmit;
