import { useEffect, useState } from "react";
import useApiInfiniteCall from "./useApiInfiniteCall";
interface UseItemsAdminProps {
  types: string[] | undefined;
  id: string | number | undefined;
  upn?: string | undefined;
  name?: string;
  page?: number | null | undefined;
  size?: number | null | undefined;
  clevels?: string[] | undefined;
  deleted?: boolean;
  options?: {};
}

/**
 * Hook for Admin Items
 */
const useItemsAdmin = (props: Partial<UseItemsAdminProps>) => {
  const {
    types = [],
    upn = undefined,
    name,
    page,
    size,
    options = {},
    clevels,
    deleted = false
  } = props;

  const [items, setItems] = useState<ItemCompleteFormProps[]>([]);
  const [item, setItem] = useState<ItemCompleteFormProps | undefined>();

  const upnQuery = upn && upn.length > 0 ? `&upn=${upn}` : "";

  const pageSizeFetch =
    typeof page === "number" && typeof size === "number"
      ? `&page=${page}&size=${size}`
      : "";

  const item_types =
    types && types?.length > 0
      ? `&item_type=${types?.join("&item_type=")}`
      : "";

  const deletedString = deleted ? "&deleted=true" : "&deleted=false";

  const adminClevels =
    clevels && clevels.length > 0 ? `&clevel=${clevels?.join("&clevel=")}` : "";

  const apiCallString = `/admin/item${
    item_types || pageSizeFetch || upnQuery || adminClevels
      ? "?" +
        `${upnQuery}${item_types}${adminClevels}${pageSizeFetch}${deletedString}`.slice(
          1
        )
      : ""
  }`;

  //console.info(apiCallString);

  const {
    data,
    //isLoading,
    //refetch: apiRefetch,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    //hasNextPage,
    //hasPreviousPage,
    //remove,
    refetch
  } = useApiInfiniteCall({
    api: apiCallString,
    name: [name || "itemsadmin" + types[0]],
    pageParam: page || 0,
    options: {
      ...{ enabled: apiCallString.length > 0 },
      ...options,
      select: (
        data: any
      ): {
        pages: [];
        total: number | undefined;
        items: [];
        pageParams: [];
      } => {
        return {
          total: data.pages[0]?.data?.total,
          items: data.pages.flatMap((pg: any) => pg.data.items),
          pages: data.pages,
          pageParams: data.pageParams
        };
      }
    }
  });

  useEffect(() => {
    if (!isFetching && data) {
      const fetchedData = data as {
        pages: [];
        pageParams: [];
        items: [];
        total: number;
      };
      setItems(fetchedData.items);
    }
  }, [data, isFetching]);

  const clearItem = () => {
    setItem(undefined);
  };

  const [fetching] = [isFetching];

  return {
    items,
    fetching,
    item,
    clearItem,
    refetch,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage
  };
};

export default useItemsAdmin;
