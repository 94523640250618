import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import MuiIconSearch from "./MuiIconSearch";
import CloseIcon from "@mui/icons-material/Close";

export interface MuiIconSelectorDialogProps {
  open?: boolean;
  onSelect?: (value: string) => void;
  iconLimit: number;
  onClose?: () => void;
}

const MuiIconSelectorDialog = (props: MuiIconSelectorDialogProps) => {
  const { open = false, onSelect, iconLimit = 50, onClose } = props;

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        Find Icon{" "}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
              //color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <MuiIconSearch
          onSelect={(val) => {
            onSelect?.(val);
          }}
          iconLimit={iconLimit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MuiIconSelectorDialog;
