import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import JourneyGroupFormFields from "./JourneyGroupFormFields";

interface JourneyGroupSortableGroupProps {
  value?: ItemJourneyGroupProps;
  onChange?: Function;
  onEdit?: () => void;
  onDelete?: () => void;
  onRestore?: () => void;
  originalValue?: ItemJourneyGroupProps;
  selected?: boolean;
}

const JourneyGroupSortableGroup = (props: JourneyGroupSortableGroupProps) => {
  const {
    value,
    onChange,
    onEdit,
    onDelete,
    onRestore,
    selected,
    originalValue
  } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: value?.id || -1 });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: selected ? "0.2" : "1"
  };

  return (
    <div ref={setNodeRef} {...attributes} style={style}>
      <JourneyGroupFormFields
        value={value}
        onChange={onChange}
        onEdit={onEdit}
        onDelete={onDelete}
        onRestore={onRestore}
        sortProps={listeners}
        originalValue={originalValue}
      />
    </div>
  );
};

export default JourneyGroupSortableGroup;
