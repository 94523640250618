import journeyGetStats from "./journeyGetStats";

const setJourneyStateData = (journeys?: ItemUserJourney[]) => {
  let defaultData = {
    count: 0,
    dismissable: false,
    dismissed: false,
    selected: undefined
  };

  if (journeys) {
    const journeyStats = journeys.map((journey: ItemUserJourney) => {
      return {
        id: journey.id,
        dismissable: journey.item.item_data.dismissable,
        stats: journeyGetStats(journey.item)
      };
    });

    console.info(journeyStats);
    const nonDismissableCount = journeyStats.filter(
      (jstat: any) => !jstat.dismissable
    );

    console.info("nonDismissableCount", nonDismissableCount.length);

    return {
      count: journeys.length,
      dismissable: nonDismissableCount.length === 0,
      dismissed: false
    };
    //   count: results.total,
    //   dismissable: nonDismissableCount.length === 0,
    //   dismissed: false
  }

  return defaultData;
};

export default setJourneyStateData;
