import { parse, serialize } from "tinyduration";

const DurationReadOnly = ({ value }: { value: string | undefined }) => {
  if (value && value[0] === "P") {
    const durationObj = parse(value);
    const durationValue = `${
      durationObj?.years && durationObj?.years > 0
        ? durationObj?.years + " Year" + (durationObj?.years > 1 ? "s " : " ")
        : ""
    }${
      durationObj?.months && durationObj?.months > 0
        ? durationObj?.months +
          " Month" +
          (durationObj?.months > 1 ? "s " : " ")
        : ""
    }${
      durationObj?.weeks && durationObj?.weeks > 0
        ? durationObj?.weeks + " Week" + (durationObj?.weeks > 1 ? "s " : " ")
        : ""
    }${
      durationObj?.days && durationObj?.days > 0
        ? durationObj?.days + " Day" + (durationObj?.days > 1 ? "s " : " ")
        : ""
    }${
      durationObj?.hours && durationObj?.hours > 0
        ? durationObj?.hours + " Hour" + (durationObj?.hours > 1 ? "s " : " ")
        : ""
    }${
      durationObj?.minutes && durationObj?.minutes > 0
        ? durationObj?.minutes +
          " Minute" +
          (durationObj?.minutes > 1 ? "s " : " ")
        : ""
    }`;
    return <>{durationValue}</>;
  }

  return <></>;
};

export default DurationReadOnly;
