import {
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography
} from "@mui/material";

import { ChangeEvent, useEffect } from "react";
import { RTEditor } from "../../../../../components";
import JourneyGroupsFormFields from "./JourneyGroupsFormFields";
import useItemJourneyFormFields from "./useItemJourneyFormFIelds";
import TextFieldDebounce from "../../../../../components/form/inputs/textfielddebounce/TextFieldDebounce";

const ItemJourneyFormFields = (props: ItemTypeFormProps) => {
  const { value: initialValue, onChange, onValid, originalValue } = props;

  const { value, valid, validFields, updateValue } =
    useItemJourneyFormFields(initialValue);

  const handleTitleChange = (value: string) => {
    updateValue({ title: value });
  };

  const handleDescriptionChange = (val: string) => {
    updateValue({ description: val });
  };

  const handleOnDisimissableChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateValue({ dismissable: event.target.checked });
  };

  const handleOnGroupChange = (val: any) => {
    updateValue({ groups: val });
  };

  useEffect(() => {
    onChange?.(value);
  }, [value, initialValue]);

  useEffect(() => {
    onValid?.(valid);
  }, [valid]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        paddingTop: "1rem"
      }}
    >
      <Box>
        <Typography variant="overline">Display Title *</Typography>

        <TextFieldDebounce
          fullWidth
          error={!validFields.title}
          value={value.title}
          onChange={handleTitleChange}
        />
      </Box>
      <Box>
        <Typography variant="overline">Short Description *</Typography>
        <RTEditor
          error={!validFields.description}
          initialValue={value.description}
          onChange={handleDescriptionChange}
        />
      </Box>
      <Box>
        <Typography variant="overline">Dismissable?</Typography>
        <Switch
          checked={value.dismissable}
          onChange={handleOnDisimissableChange}
        />
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={value.dismissable}
              onChange={handleOnDisimissableChange}
            />
          }
          label="Dismissable"
        /> */}
      </Box>
      <JourneyGroupsFormFields
        error={!validFields.groups}
        value={value.groups}
        onChange={handleOnGroupChange}
        originalValue={originalValue?.groups}
      />
    </Box>
  );
};

export default ItemJourneyFormFields;
