import { Box, Paper, Typography } from "@mui/material";
import { DisplayHtml } from "../../../../../components";
import JourneyItemReadOnly from "./JourneyItemReadOnly";

const JourneyGroupReadOnly = ({ group }: { group: ItemJourneyGroupProps }) => {
  return (
    <Paper variant="outlined">
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0.2rem 1rem"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "auto",
              gap: "1rem"
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="overline">Sequence</Typography>
                <Box>{group?.sequence_number}</Box>
              </Box>
              <Box>
                <Typography variant="overline">Title</Typography>
                <Box>{group?.title}</Box>
              </Box>
              <Box>
                <Typography variant="overline">
                  Secondary Description
                </Typography>
                <DisplayHtml html={group.secondary_description} />
              </Box>
            </Box>
            <Box>
              <Typography variant="overline">Description</Typography>
              <DisplayHtml html={group.description} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "1rem",
            gap: "0.6rem",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Typography variant="overline">Items</Typography>
          <Box sx={{ gap: "0.6rem", display: "flex", flexDirection: "column" }}>
            {group.items.map((item) => (
              <JourneyItemReadOnly key={item.id} item={item} />
            ))}
          </Box>
          {/* <JourneyGroupItemsFormFields items={items} /> */}
        </Box>
      </Box>
    </Paper>
  );
};

export default JourneyGroupReadOnly;
