import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Box, CircularProgress, Typography } from "@mui/material";

interface FetchingIndicatorProps {
  label?: string;
  iconSize?: number;
}

const FetchingIndicator = (props: FetchingIndicatorProps) => {
  const { iconSize = 68, label } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70px",
          width: "70px",
          position: "relative"
        }}
      >
        <CircularProgress
          size={68}
          color="primary"
          // sx={{
          //   position: "absolute",
          //   top: -6,
          //   left: -6,
          //   zIndex: 1
          // }}
        />

        <CloudDownloadIcon
          color="secondary"
          sx={{ position: "absolute" }}
          fontSize="large"
        />
      </Box>
      {label && <Typography>{label}</Typography>}
    </Box>
  );
};

export default FetchingIndicator;
