import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { userAtom, notificationsAtom } from "../../../../../globalstates";
import { useItems } from "../../../../../hooks";

interface useItemAnnouncementDialogProps {}

const useItemAnnouncementDialog = () => {
  const user = useAtomValue(userAtom);

  const [globalNotificationCount, setGlobalNotificationCount] =
    useAtom(notificationsAtom);

  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [count, setCount] = useState(0);

  const [item, setItem] = useState<ItemUserProps>();

  const { items, refetch } = useItems({
    name: "announcements",
    types: ["ANNOUNCEMENT"],
    status: ["VIEW", "DISPLAY"],
    options: { staleTime: Infinity }
  });

  useEffect(() => {
    if (items && items.length > 0) {
      setCount(items.length);
      setGlobalNotificationCount((prev) => ({
        ...prev,
        announcementsCount: items?.length
      }));
      setItem(items[currentIndex]);
    }
  }, [items]);

  const handleNext = () => {
    if (currentIndex < count) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const hideAnnouncements = () => {
    setGlobalNotificationCount((prev) => ({
      ...prev,
      announcementsDismissed: true
    }));
  };

  useEffect(() => {
    if (items && currentIndex >= 0) setItem(items[currentIndex]);
  }, [currentIndex, items]);

  const handleBack = () => {
    if (currentIndex < count) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  return {
    item,
    handleNext,
    handleBack,
    loading,
    count,
    currentIndex,
    setCurrentIndex,
    hideAnnouncements,
    refetch
  };
};

export default useItemAnnouncementDialog;
