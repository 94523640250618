import { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import "./timelineStyle.css";

interface TimelineProps {}

const timelineStyle = {
  // display: "flex",
  // flexDirection: "column",
  position: "relative",
  // height: "100%",

  "&:before": {
    position: "absolute",
    content: "''",
    width: "1px",
    left: "10px",
    top: "10px",
    borderRadius: "4px",
    backgroundColor: "#666",
    boxShadow: "0px 0px 1px 0px #333",
    height: "100%",
  },
};

const Timeline = (props: PropsWithChildren<TimelineProps>) => {
  const { children } = props;
  return (
    <Box className="timeline" sx={timelineStyle}>
      {children}
    </Box>
  );
};

export default Timeline;
