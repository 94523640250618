import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme
} from "@mui/material";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import { DatePickerWrapper, DurationPicker } from "..";

interface DueDatePickerProps {
  value: string;
  onChange?: (value: string) => void;
  error?: boolean;
  onIsValid?: (value: boolean) => void;
}

const MAXDATE = "9999-12-31";

const DueDatePicker = (props: DueDatePickerProps) => {
  const { value, onChange, error, onIsValid } = props;
  const theme = useTheme();

  const [dueDateType, setDueDateType] = useState(
    value && value.length > 0
      ? value[0] === "P"
        ? "DURATION"
        : value === MAXDATE
        ? "NONE"
        : "DATE"
      : "NONE"
  );

  const [dueDateVal, setDueDateVal] = useState(value || MAXDATE);
  const [focused, setFocused] = useState(false);

  const handleDueDateTypeChange = (e: SelectChangeEvent<string>) => {
    setDueDateType(e.target.value);

    if (e.target.value === "NONE") setDueDateVal(MAXDATE);
    if (e.target.value === "DATE") setDueDateVal("");
    if (e.target.value === "DURATION") setDueDateVal("PT0S");
  };

  useEffect(() => {
    onChange?.(dueDateVal);
    onIsValid?.(
      dueDateVal.length === 0 || dueDateVal === "PT0S" ? false : true
    );
  }, [dueDateVal]);

  return (
    <Box
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      sx={{
        border: error
          ? focused
            ? `solid 2px ${theme.palette.error.main}`
            : `solid 1px ${theme.palette.error.main}`
          : focused
          ? "solid 2px " + theme.palette.primary.main
          : `solid 1px rgba(0, 0, 0, 0.23)`,
        borderRadius: "4px",
        padding: "4px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Select
        onChange={handleDueDateTypeChange}
        value={dueDateType}
        fullWidth
        placeholder="Select a due date type..."
      >
        <MenuItem value="NONE">No Due Date</MenuItem>
        <MenuItem value="DURATION">Duration</MenuItem>
        <MenuItem value="DATE">Specific Date</MenuItem>
      </Select>
      {dueDateType === "DATE" && (
        <DatePickerWrapper
          value={parse(dueDateVal, "yyyy-MM-dd", new Date())}
          onChange={(val: any) => {
            //console.log("DatePickerWrapper", val);
            onIsValid?.(!!val);
            setDueDateVal(val ? format(val, "yyyy-MM-dd") : "");
          }}
          inputFormat="dd/MM/yyyy"
        />
      )}
      {dueDateType === "DURATION" && (
        <DurationPicker
          value={dueDateVal}
          onChange={(val) => {
            //console.log("Val from duration Picker", val);
            setDueDateVal(val);
          }}
          onIsValid={(val) => {
            onIsValid?.(val);
          }}
        />
      )}
    </Box>
  );
};

export default DueDatePicker;
