import React, { PropsWithChildren, ReactNode } from "react";
import { Grid, Paper, Box, Typography, Divider } from "@mui/material";

interface IRegion {
  title?: string;
  buttons?: ReactNode;
}

export default function Region(props: PropsWithChildren<IRegion>) {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.title && props.title?.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">{props.title}</Typography>
            <Box>{props.buttons}</Box>
          </Box>
          <Divider sx={{ marginBottom: "1rem" }} />
        </>
      )}
      <Box>{props.children}</Box>
    </Paper>
  );
}
