/**
 * Checks the length of actual text content in a HTML string. Used to verify there is actually text and not just html tags.
 * @param html string value to check
 * @returns number
 */
export default function htmlContentTextLength(html: string): number {
  //console.log("txt Length Checker");
  const str = new DOMParser().parseFromString(html, "text/html");
  if (str && str.body.textContent) {
    return str.body.textContent.replace(/ |\n/gi, "").length;
  }
  return 0;
}
