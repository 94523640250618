import { atom } from "jotai";
import { ItemFilterFormValueProps } from "../../../items/components/form/itemfilter/ItemFilterForm";
import { ItemSortFormValueProps } from "../../../items/components/form/itemfilter/ItemSortForm";

interface MainPageAtomProps {
  filtering?: boolean;
  sort?: ItemSortFormValueProps[]; //string;//{ values?: string; order: string };
  sortOrder?: string;
  filter?: ItemFilterFormValueProps; //{ itemTypes?: string[]; statuses?: string[] };
}

export const mainPageAtomDefault = {
  filtering: false,
  sort: [{ property: "user_item.due_date", direction: "asc" }], // [], //"user_item.due_date asc", //{ values: "due_date", order: "desc" },
  filter: {
    itemTypes: ["VIDEO", "INFORMATION", "MEETING", "ECENTRE"], //["INFORMATION", "VIDEO", "MEETING"],
    statuses: ["VIEW", "DISPLAY"]
  }
};

const mainPageAtom = atom<MainPageAtomProps>(mainPageAtomDefault);

export default mainPageAtom;
