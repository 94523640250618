import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { About, Privacy, TermsOfUse, NotFound, ProfilePage } from "../../pages";

//import useApp from "./useApp";
import LoadingFullScreen from "../../components/LoadingFullScreen";
import ClientPage from "../client";
import { useAtomValue } from "jotai";
import { userAdminAtom } from "../../globalstates";

/**
 * Lazy Load Department Admin part of the application.
 */

const Admin = lazy(() => import("../../features/admin"));

export default function AppRoutes() {
  //const { globalState, loading } = useApp();
  const userAdminState = useAtomValue(userAdminAtom);

  return (
    <>
      <Suspense fallback={<LoadingFullScreen title="Fetching assets..." />}>
        <Routes>
          {/* <Route path="me" element={<ProfilePage />} /> */}
          {/* <Route path="preferences/*" element={<Preferences />} /> */}
          {userAdminState?.isAdmin && (
            <Route path="admin/*" element={<Admin />} />
          )}
          <Route path="about" element={<About />} />
          {/* <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacy" element={<Privacy />} /> */}
          <Route path="/*" element={<ClientPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
}
