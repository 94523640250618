import ScrollableContainer from "../../ScrollableContainer";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { SxProps } from "@mui/material";

const SCALE = 1;
const DURATION = 0.2;

export const pageVariants = {
  hidden: { opacity: 0, scale: SCALE, height: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    height: "auto",
    transition: { duration: DURATION, ease: "easeIn" }
  },
  exit: {
    opacity: 0,
    scale: SCALE,
    height: 0,
    transition: { duration: DURATION, ease: "easeOut" }
  }
};

interface SubPageProps {
  scrollStyle?: SxProps;
  scrollInnerStyle?: SxProps;
  animate?: boolean;
  topOffset?: number;
}

export default function SubPage({
  children,
  scrollStyle,
  scrollInnerStyle,
  animate = false,
  topOffset = 64
}: PropsWithChildren<SubPageProps>) {
  return (
    <motion.div
      variants={pageVariants}
      initial={animate ? "hidden" : false}
      animate={animate ? "visible" : false}
      exit={animate ? "exit" : undefined}
    >
      <ScrollableContainer
        sx={{
          ...{ height: `calc(100vh - ${topOffset}px)`, padding: 0 },
          ...scrollStyle
        }}
        sxinner={{
          ...{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          },
          ...scrollInnerStyle
        }}
        showscrolltotop={true}
      >
        {children}
      </ScrollableContainer>
    </motion.div>
  );
}
