import { useEffect, useState } from "react";
import useApiCall from "./useApiCall";
import { useAtomValue } from "jotai";
import { userAtom } from "../../globalstates";
import useApiInfiniteCall from "./useApiInfiniteCall";
import { InfiniteData } from "@tanstack/react-query";
interface UseItemsProps {
  types?: string[];
  name?: string;
  fullFetch?: boolean;
  status?: string[];
  page?: number | null | undefined;
  size?: number | null | undefined;
  sorting?: string;
  options?: {};
}

/**
 * Hook for all items for the current user
 */
export default function useItems(props?: UseItemsProps) {
  const user = useAtomValue(userAtom);

  const [items, setItems] = useState<ItemUserProps[]>([]);
  const [total, setTotal] = useState<number | null | undefined>();
  const [page, setPage] = useState<number | null | undefined>();
  const [size, setSize] = useState<number | null | undefined>();

  const pageSizeFetch =
    typeof props?.page === "number" && typeof props?.size === "number"
      ? `&page=${props.page}&size=${props.size}`
      : "";

  const fullFetch =
    typeof props?.fullFetch === "boolean"
      ? `&full_check=${props.fullFetch.toString()}`
      : "";

  const item_types =
    props?.types && props?.types?.length > 0
      ? `&item_type=${props?.types?.join("&item_type=")}`
      : "";

  const item_status =
    props?.status && props?.status?.length > 0
      ? `&user_item_status=${props?.status?.join("&user_item_status=")}`
      : "";

  const item_sorting =
    props?.sorting && props?.sorting?.length > 0
      ? `&sort=${props.sorting}`
      : "";
  // const { data, isFetching, isLoading, refetch } = useApiCall({
  //   api: `/user/item?upn=${user?.userPrincipalName}${item_types}${item_status}${fullFetch}${pageSizeFetch}`,
  //   name: [props?.name || "useritems"],
  //   options: props?.options
  // });

  const apiCall = `/user/item?upn=${user?.userPrincipalName}${item_types}${item_status}${item_sorting}${fullFetch}${pageSizeFetch}`;

  //console.info("apiCall", apiCall);
  const {
    data,
    isLoading,
    refetch: apiRefetch,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    remove
  } = useApiInfiniteCall({
    api: apiCall, //`/user/item?upn=${user?.userPrincipalName}${item_types}${item_status}${item_sorting}${fullFetch}${pageSizeFetch}`,
    name: [props?.name || "useritems"],
    pageParam: props?.page || 0,
    options: {
      ...props?.options,
      select: (
        data: any
      ): {
        pages: [];
        total: number | undefined;
        items: [];
        pageParams: [];
      } => {
        return {
          total: data.pages[0]?.data?.total,
          items: data.pages.flatMap((pg: any) => pg.data.items),
          pages: data.pages,
          pageParams: data.pageParams
        };
      }
    }
  });

  // useEffect(() => {
  //   //console.log("Page Changed", props?.page);
  //   fetchNextPage();
  // }, [props?.page]);

  // useEffect(() => {
  //   if (data?.pages && data?.pages?.length > 0) {
  //     const flattenedPages = data?.pages.reduce((acc, curVal) => {
  //       //if (!total)
  //       setTotal(curVal.data.total);
  //       return acc.concat(curVal.data.items);
  //     }, []);
  //     //console.log("flattedPages", flattedPages);
  //     setItems(flattenedPages);
  //     //setItems(data.pages[0].data?.items);
  //     //console.log("data.pages", data.pages);
  //     //  setPage(data.page);
  //     //  setTotal(data.data.total);
  //     //  setSize(data.data.size);
  //   }
  //   //console.log(data);
  // }, [data?.pages, total]);

  useEffect(() => {
    const userData = data as {
      pages: [];
      pageParams: [];
      items: [];
      total: number;
    };
    //console.log("useItems data", userData);
    if (userData?.total) setTotal(userData.total);

    if (userData?.items) setItems(userData?.items || []);
  }, [data]);

  const refetch = () => {
    apiRefetch();
  };

  //const [total] = [data?.total];

  return {
    items,
    isFetching,
    isLoading,
    refetch,
    total,
    data,
    page,
    size,
    fetchNextPage,
    isFetchingNextPage,
    remove
  }; // useQuery(["allitems"], () => getItems());
}
