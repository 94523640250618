import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Switch
} from "@mui/material";

interface OperatorSelectorProps {
  value: string;
  onChange?: (value: string) => void;
  excludedOperators?: string[];
}

const OperatorSelector = (props: OperatorSelectorProps) => {
  const { value, onChange, excludedOperators } = props;

  const operatorValues = selectorValues
    .map((selector: { id: string; operator: string; description: string }) => {
      return {
        value: selector.id,
        label: `${selector.description} (${selector.operator}) `
      };
    })
    .filter((val) => {
      return !excludedOperators?.some((eo) => eo == val.value);
    });

  const handleChange = (event: SelectChangeEvent) => {
    onChange?.(event.target.value as string);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Operator</InputLabel>
      <Select value={value || ""} onChange={handleChange} label="Operator">
        {operatorValues.map((sv) => {
          return (
            <MenuItem key={sv.value} value={sv.value}>
              {sv.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default OperatorSelector;

const selectorValues = [
  {
    id: "EQ",
    operator: "==",
    description: "Equal to"
  },
  {
    id: "GE",
    operator: ">=",
    description: "Greater than or equal to"
  },
  {
    id: "GT",
    operator: ">",
    description: "Greater than"
  },
  {
    id: "LE",
    operator: "<=",
    description: "Less than or equal to"
  },
  {
    id: "LT",
    operator: "<",
    description: "Less than"
  },
  {
    id: "NE",
    operator: "!=",
    description: "Not equal to"
  }
];
