import { useEffect, useState } from "react";
import useGraph from "./useGraph";

interface IGraphUserProfile {
  aboutMe: string;
  businessPhones: string[];
  city: string;
  displayName: string;
  givenName: string;
  surname: string;
  companyName: string;
  department: string;
  userPrincipalName: string;
  officeLocation: string;
  jobTitle: string;
  manager: any;
  mail: string;
  photoUrl?: string | null;
}

export default function useGraphUser(
  upn: string,
  fetchImage?: boolean
): [IGraphUserProfile | undefined, boolean, () => void] {
  const [fetching, setFetching] = useState(true);

  const [userProfile, setUserProfile] = useState<
    IGraphUserProfile | undefined
  >();

  const [user, userFetching, doUserFetch, userError] = useGraph(
    `/users/${upn}?$select=aboutMe,givenName,surname,displayName,businessPhones,city,companyName,department,userPrincipalName,officeLocation,manager,jobTitle,mail,onPremisesExtensionAttributes`,
    null,
    true
  );
  const [userPhoto, photoFetching, doPhotoFetch, photoError] = useGraph(
    `/users/${upn}/photo/$value`,
    null,
    fetchImage ? true : false
  );

  const [photoObj, setPhotoObj] = useState<{ photoUrl: string } | undefined>();

  const refetch = () => {
    doUserFetch();
    if (fetchImage) {
      doPhotoFetch();
    }
  };

  useEffect(() => {
    if (user && upn !== user.userPrincipalName) {
      refetch();
    }
  }, [upn, user]);

  useEffect(() => {
    if (user && photoObj) {
      if (photoObj) {
        setUserProfile({ ...user, ...photoObj });
        setFetching(false);
      }
    }
  }, [user, photoObj]);

  useEffect(() => {
    if (fetchImage) {
      if (userPhoto && !photoFetching && !photoError) {
        const photoUrl = window.URL || window.webkitURL;
        const photoBlobUrl = photoUrl.createObjectURL(userPhoto);
        const photoObj = {
          photoUrl: photoBlobUrl
        };
        setPhotoObj(photoObj);
      }

      if (photoError) {
        setPhotoObj({ photoUrl: "" });
      }
    }
    if (!fetchImage) {
      setPhotoObj({ photoUrl: "" });
    }
  }, [userPhoto, photoFetching, photoError, fetchImage]);

  return [userProfile, fetching, refetch];
}
