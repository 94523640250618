import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

const useJourneyItemDialogForm = (value?: ItemProps) => {
  const [formValue, setFormValue] = useState<any>(() => {
    return value || "";
  });
  const [isValid, setIsValid] = useState(false);
  const [validFields, setValidFields] = useState({
    item: false
  });

  const validate = () => {
    setValidFields({ item: !isEmpty(formValue) });
  };

  const updateFormValue = (
    val: Partial<ItemCompleteFormProps> | ItemProps | null | undefined
  ) => {
    setFormValue(val);
  };

  useEffect(() => {
    validate();
  }, [formValue]);

  useEffect(() => {
    setIsValid(validFields.item);
  }, [validFields]);

  useEffect(() => {
    if (value) setFormValue(value);
  }, [value]);

  return { formValue, updateFormValue, validFields, isValid };
};

export default useJourneyItemDialogForm;
