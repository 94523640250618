import { Badge, Box, Button, Tooltip } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import ClientProfileMenu from "./ClientProfileMenu";

import { ReactComponent as Logo } from "../../../components/logo/AppLogo.svg";
import { memo } from "react";
import MainMessageButton from "./MainMessageButton";
import RouteIcon from "@mui/icons-material/Route";
import { useLocation, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { journeyAtom, userAtom, userPreferences } from "../../../globalstates";
import { FormatColorResetOutlined } from "@mui/icons-material";

//import { MainMessageButton } from "./messagesmenu";

const MainNav = memo(() => {
  const nav = useNavigate();

  const location = useLocation();

  const [journeyState, setJourneyState] = useAtom(journeyAtom);
  const [userState] = useAtom(userAtom);
  const [userPrefs] = useAtom(userPreferences);

  const isTestMode =
    (userPrefs.userPrefsObj && userPrefs.userPrefsObj.admin_test_mode) || false;

  //console.log(location.pathname);

  const showHome =
    journeyState.dismissable || journeyState.count === 0 || isTestMode;
  return (
    <Box
      sx={{
        // display: "grid",
        // width: "100%",
        // gridAutoColumns: "1fr 3fr 1fr",
        // gridAutoFlow: "column",
        // alignItems: "center",
        // justifyItems: "center",
        // padding: "0 1rem"
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexWrap: "wrap",
        padding: "0rem 1rem"
      }}
    >
      <Box
        sx={{
          justifySelf: "flex-start",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          flexBasis: "25%"
        }}
      >
        <Logo height={50} width={undefined} />
        <span
          style={{
            fontFamily: "Passion One",
            fontSize: "4vmin",
            textShadow: "0px 3px 0px #ddd",
            color: "#00917D" //"#11999e"
          }}
        >
          {userState?.givenName ? userState.givenName : "Me"} @ CDU
        </span>
      </Box>
      <Box
        sx={{
          justifySelf: "center",
          order: { xs: 2, sm: 2, md: "initial" },
          width: { xs: "100%", sm: "100%", md: "initial" },
          display: "flex",
          justifyContent: { xs: "center", sm: "center", md: "initial" }
        }}
      >
        {showHome && (
          <Tooltip title="Home">
            <Button
              sx={{
                borderBottom:
                  location.pathname === "/"
                    ? "solid 2px"
                    : "solid 2px transparent"
              }}
              onClick={() => {
                // if (
                //   (journeyState.dismissable && !journeyState.dismissed) ||
                //   isTestMode
                // ) {
                //console.log("Dismissing");
                setJourneyState((prev) => ({ ...prev, dismissed: true }));
                // }
                nav("/");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <HomeIcon />
                Home
              </Box>
            </Button>
          </Tooltip>
        )}
        {journeyState.count > 0 && (
          <Tooltip title="Journey">
            <Button
              sx={{
                borderBottom:
                  location.pathname.indexOf("/journey") > -1
                    ? "solid 2px"
                    : "solid 2px transparent"
              }}
              onClick={() => {
                nav("/journey");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <Badge
                  badgeContent={journeyState.count}
                  color="error"
                  overlap="circular"
                >
                  <RouteIcon />
                </Badge>
                Journey
              </Box>
            </Button>
          </Tooltip>
        )}
        <MainMessageButton />
      </Box>
      <Box
        sx={{
          justifySelf: "flex-end",
          display: "flex",
          justifyContent: "end",
          flexBasis: "25%"
        }}
      >
        <ClientProfileMenu />
      </Box>
    </Box>
  );
});

export default MainNav;
