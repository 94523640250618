import TextField, { TextFieldProps } from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface DatePickerWrapperProps extends Partial<DatePickerProps<any, any>> {
  //interface DatePickerWrapperProps extends DesktopDatePickerProps<any, any> {
  label?: string;
  //value: string;
  onChange: (date: unknown) => void;
  required?: boolean;
  error?: boolean;
  //renderInput?: any;
}

const DatePickerWrapper = (props: DatePickerWrapperProps) => {
  const { required = false, error = false } = props;
  const {
    value,
    onChange,
    renderInput = (params: TextFieldProps) => (
      <TextField {...params} required={required} error={error} fullWidth />
    ),

    ...rest
  } = props;

  //const [value, setValue] = useState<string | null>(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        renderInput={renderInput}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DatePickerWrapper;
