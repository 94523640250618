import { OPERATIONTYPES } from "../../../../types/enums";

const journeyItemPrepareForSubmit = (
  journey: ItemJourneyData,
  operation: string
) => {
  let submittableJourney: ItemJourneyDataSubmittable | undefined = undefined;

  if (operation === OPERATIONTYPES.CREATE.KEY) {
    if (journey.groups) {
      const journeyGroups: (ItemJourneyGroupSubmittable | undefined)[] =
        journey.groups
          .map((group) => {
            return prepareItemJourneyGroupForSubmit(group);
          })
          .filter((group) => !!group);
      submittableJourney = { ...journey };
      submittableJourney.groups = journeyGroups;
      //delete submittableJourney["isNew"];
    }
  }

  if (operation === OPERATIONTYPES.UPDATE.KEY) {
    if (journey.groups) {
      const journeyGroups: (ItemJourneyGroupSubmittable | undefined)[] =
        journey.groups
          .map((group) => {
            return prepareItemJourneyGroupForSubmit(group);
          })
          .filter((group) => !!group);
      submittableJourney = { ...journey };
      submittableJourney.groups = journeyGroups;
      delete submittableJourney["item_id"];
      delete submittableJourney["id"];
    }
  }

  //console.log("submittableJourney", operation, submittableJourney);

  return submittableJourney;
};

export default journeyItemPrepareForSubmit;

const prepareItemJourneyGroupItemForSubmit = (
  item: ItemJourneyGroupItemProps
) => {
  let itemForSubmit: ItemJourneyGroupItemSubmittable | undefined = undefined;

  if (item.operation === OPERATIONTYPES.CREATE.KEY) {
    itemForSubmit = { ...item };
    delete itemForSubmit["id"];
    delete itemForSubmit["journey_group_id"];
  }

  if (item.operation === OPERATIONTYPES.UPDATE.KEY) {
    itemForSubmit = { ...item };
  }

  if (item.operation === OPERATIONTYPES.DELETE.KEY) {
    itemForSubmit = { id: item.id, operation: item.operation };
  }

  return itemForSubmit;
};

const prepareItemJourneyGroupForSubmit = (group: ItemJourneyGroupProps) => {
  let groupForSubmit: ItemJourneyGroupSubmittable | undefined = undefined;

  //   if (!group.operation) {
  //     if (group.items?.some((item) => !!item.operation)) {
  //       console.log("Group has no operation but items do!");
  //     }
  //   }

  if (group.operation === OPERATIONTYPES.CREATE.KEY) {
    groupForSubmit = { ...group };
    groupForSubmit.items = group.items
      .map((item) => prepareItemJourneyGroupItemForSubmit(item))
      .filter((item) => !!item);
    delete groupForSubmit["id"];
    delete groupForSubmit["status"];
  }

  if (group.operation === OPERATIONTYPES.UPDATE.KEY) {
    groupForSubmit = { ...group };
    groupForSubmit.items = group.items
      .map((item) => prepareItemJourneyGroupItemForSubmit(item))
      .filter((item) => !!item);
    delete groupForSubmit["journey_item_id"];
    delete groupForSubmit["status"];
  }

  if (group.operation === OPERATIONTYPES.DELETE.KEY) {
    groupForSubmit = {
      id: group.id,
      operation: group.operation
    };
  }

  return groupForSubmit;
};
