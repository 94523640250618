import { Masonry } from "@mui/lab";
import {
  Alert,
  AlertColor,
  Backdrop,
  Box,
  CircularProgress,
  Snackbar,
  useMediaQuery
} from "@mui/material";
import { useAtom } from "jotai";
//import { some } from "lodash";
import { useEffect, useRef, useState } from "react";
import { FetchingIndicator, SubPage } from "../../../../components";
import {
  useIsInViewport,
  useItems,
  useItemsEcentre,
  useUpdateEffect
  //useOnScreen
} from "../../../../hooks";
import { UserItem } from "../../../items/item";
import mainPageAtom from "./mainPageAtom";
import MainPageSpeedDial from "./MainPageSpeedDial";

const MainPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [mainPageState, setMainPageState] = useAtom(mainPageAtom);

  const [sortedItems, setSortedItems] = useState<ItemUserProps[]>([]);

  //const [dismissedItems, setDismissedItems] = useState<number[]>([]);
  const [snackOpen, setSnack] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: "",
    severity: "success"
  });

  const {
    items,
    isFetching,
    isLoading,
    refetch,
    total,
    size,
    page,
    data,
    isFetchingNextPage,
    remove,
    fetchNextPage
  } = useItems({
    name: "mainpage",
    types: mainPageState.filter?.itemTypes || [
      "INFORMATION",
      "VIDEO",
      "MEETING",
      "ECENTRE"
    ],
    status: mainPageState.filter?.statuses || ["VIEW", "DISPLAY"],
    size: 100,
    page: currentPage,
    sorting:
      //`user_item.${mainPageState.sort?.values} ${mainPageState.sort?.order}` ||
      mainPageState.sort
        ?.map((obj) => `${obj.property} ${obj.direction}`)
        .join(", ") || "", //"user_item.due_date asc",
    options: {
      //staleTime: Infinity,
      onSuccess: (results: any) => {
        //console.log("Fetching Finished Main Page", results);
        if (mainPageState.filtering)
          setMainPageState((prevState) => ({ ...prevState, filtering: false }));
      }
    }
  });

  useEffect(() => {
    // console.log("mainPageState.filtering", mainPageState.filtering);
    // console.log(
    //   "mainPageState.sort",
    //   mainPageState.sort,
    //   mainPageState.sort
    //     ?.map((obj) => `${obj.property} ${obj.direction}`)
    //     .join(", ")
    // );
    if (mainPageState.filtering) {
      refetch();
      //setMainPageState((prevState) => ({ ...prevState, filtering: true }));
    }
  }, [mainPageState.sort, mainPageState.filter, mainPageState.filtering]);

  const {
    items: ecentreItems,
    isFetching: ecentreIsFetching,
    isLoading: ecentreIsLoading
  } = useItemsEcentre();

  const endOfItemsRef = useRef<HTMLElement>(null);
  const isInViewPort = useIsInViewport(endOfItemsRef);

  useEffect(() => {
    if (items && ecentreItems && mainPageState.filter?.itemTypes) {
      const includeEcentre =
        mainPageState.filter?.itemTypes.indexOf("ECENTRE") > -1;
      const allItems = items.concat(
        includeEcentre
          ? ecentreItems.filter(
              (eItem: ItemUserProps) =>
                eItem.item.item_data.status !== "Complete"
            )
          : []
      );
      // const sortItems = allItems.sort(
      //   (a, b) =>
      //     new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
      // );
      //console.info(allItems);
      setSortedItems(allItems);
    }
  }, [items, ecentreItems, mainPageState.filter?.itemTypes]);

  useEffect(() => {
    if (isInViewPort) {
      if (!isFetching && !isFetchingNextPage) {
        if (total && items.length < total) {
          fetchNextPage();
        }
      }
    }
  }, [isInViewPort, items.length, total, isFetchingNextPage, isFetching]);

  const handleOnItemActionPerformed = (success: boolean) => {
    if (success) {
      remove();
      setCurrentPage(1);
    }
  };

  const handleSnackClose = () => {
    setSnack((prev) => ({ ...prev, open: false }));
  };

  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  return (
    <>
      <Backdrop
        sx={{
          color: "#333",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(255, 255, 255, 0.7)"
        }}
        open={mainPageState.filtering || false}
        //onClick={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Box sx={{ fontWeight: "bolder", fontSize: "2rem" }}>
            Applying Filters
          </Box>
          <CircularProgress color="primary" />
        </Box>
      </Backdrop>
      <SubPage
        scrollInnerStyle={{
          height: "100%",
          display: "block"
        }}
        scrollStyle={{ display: "block" }}
        topOffset={isLarge ? 80 : 180}
      >
        <MainPageSpeedDial />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "1rem"
          }}
        >
          {isLoading && (
            <Box
              sx={{
                height: "100vh",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <FetchingIndicator label="Fetching your items" />
            </Box>
          )}
          {!isLoading &&
            sortedItems.length > 0 &&
            data &&
            data?.pages.length > 0 && (
              <>
                <Masonry
                  sx={{ alignContent: "center" }}
                  defaultColumns={2}
                  columns={{ xs: 1, sm: 1, md: 2, lg: 2 }}
                  spacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}
                >
                  {sortedItems?.map((item, index) => (
                    <UserItem
                      key={item.id}
                      item={item}
                      onItemActionPerformed={(success, message) => {
                        if (success) {
                          refetch();
                        }
                        if (message)
                          setSnack({
                            open: true,
                            message: message,
                            severity: success ? "success" : "error"
                          });
                      }}
                    />
                  ))}
                </Masonry>
                <Box
                  sx={{
                    paddingTop: "1rem",
                    paddingBottom: "2rem",
                    fontSize: "2rem",
                    color: "#AAA",
                    textAlign: "center",
                    fontWeight: "bolder"
                  }}
                >
                  {!isFetchingNextPage && (
                    <Box
                      ref={endOfItemsRef}
                      sx={{
                        fontSize: "2rem",
                        color: "#AAA",
                        textAlign: "center",
                        fontWeight: "bolder"
                      }}
                    >
                      You have reached the end. Well done!
                    </Box>
                  )}
                  {isFetchingNextPage && (
                    <FetchingIndicator label="Loading More Items..." />
                  )}
                </Box>
              </>
            )}
          {!isLoading && sortedItems.length === 0 && (
            <Box
              sx={{
                fontSize: "2rem",
                color: "#AAA",
                textAlign: "center",
                fontWeight: "bolder",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              You are all done. Nothing to display.
            </Box>
          )}
        </Box>
        <Box></Box>
      </SubPage>
      <Snackbar
        open={snackOpen.open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snackOpen.severity || "success"}
          sx={{ width: "100%" }}
        >
          {snackOpen.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MainPage;
