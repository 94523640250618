import { atom } from "jotai";

const journeyAtom = atom<{
  count: number;
  dismissable: boolean;
  dismissed: boolean;
  selected?: number | string | undefined | null;
}>({
  count: 0,
  dismissable: false,
  dismissed: false,
  selected: undefined
});

export default journeyAtom;
