import * as microsoftTeams from "@microsoft/teams-js";

//import React from 'react';

export function getClientSideToken() {
  return new Promise((resolve, reject) => {
    // display("1. Get auth token from Microsoft Teams");

    microsoftTeams.authentication.getAuthToken({
      successCallback: (result) => {
        resolve(result);
      },
      failureCallback: function (error) {
        reject("Error getting token: " + error);
      },
    });
  });
}

export function getServerSideToken(clientSideToken: any) {
  //display("2. Exchange for server-side token");

  return new Promise((resolve, reject) => {
    microsoftTeams.getContext((context) => {
      // console.log(context.tid);
      //console.log(context)
      //console.log('Client side Token',clientSideToken);
      //console.log('client_id',process.env.REACT_APP_AZURE_APP_CLIENT_ID);
      //console.log('secret',process.env.REACT_APP_AZURE_APP_CLIENT_SECRET);
      let url = process.env.REACT_APP_API_SERVER as string; //'https://cduslc-api.azurewebsites.net';

      fetch(`${url}/api/token`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tid: context.tid,
          token: clientSideToken,
          client_id: process.env.REACT_APP_AZURE_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_AZURE_APP_CLIENT_SECRET,
        }),
        mode: "cors",
        cache: "default",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            reject(response);
          }
        })
        .then((responseJson) => {
          const serverSideToken = responseJson;
          resolve(serverSideToken);
        });
    });
  });
}
