import { useAtomValue } from "jotai";
import { DisplayHtml } from "../../../../../components";
import { userAtom } from "../../../../../globalstates";
import { replaceUserValues } from "../../../../../utils";

const ItemAnnouncementDataDisplay = ({
  data
}: {
  data: ItemAnnouncementData;
}) => {
  const user = useAtomValue(userAtom);
  const { content } = data;
  return <DisplayHtml html={replaceUserValues(content, user)} />;
};

export default ItemAnnouncementDataDisplay;
