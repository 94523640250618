import { useState, useEffect } from "react";

export default function useDatabase(
  query: string,
  dependencies = []
): [any, boolean, any, (content: any) => void] {
  const [request, setDoRequest] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [result, setResult] = useState<any>();
  const [error, setError] = useState<any>();

  async function handleDoFetch() {
    await setFetching(true);
    await setDoRequest(true);
  }

  // useEffect(() => {
  //   if (request) {
  //     const fetchDBData = async () => {
  //       //console.log('Starting DB Fetch')
  //       setFetching(true);
  //       try {
  //         const data = await fetch(
  //           "https://cduslc-api.azurewebsites.net/api/queryDB",
  //           {
  //             headers: { "Content-Type": "application/json" },
  //             method: "POST",
  //             body: query,
  //           }
  //         );
  //         const results = await data.json();
  //         //console.log("useDatabase Fetch", results);

  //         if (results.status === 200) {
  //           await setResult(results);
  //         }
  //         await setError(results.err);
  //         await setFetching(false);
  //         await setDoRequest(false);
  //         //console.log('Finished DB Request')
  //       } catch (err) {
  //         setError(err);
  //         setFetching(false);
  //         setDoRequest(false);
  //       }
  //     };
  //     fetchDBData();
  //   }
  // }, [request]);

  // return useFetch('https://cduslc-api.azurewebsites.net/api/queryDB',{method:'POST',body:query},dependencies);

  return [result, fetching, error, handleDoFetch];
}
