import { InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

import { getGraphClient } from "../utils";

export default function useGraphClient(
  url: string,
  options?: any,
  enabled?: boolean
): [any, boolean, (content?: any) => void, any] {
  const { instance, accounts, inProgress } = useMsal();

  const [doRequest, setDoRequest] = useState<boolean>(enabled || true);
  const [client, setClient] = useState<any>(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<any>(null);

  async function handleDoRequest() {
    await setFetching(true);
    await setDoRequest(true);
    await setFetching(false);
  }

  useEffect(() => {
    if (doRequest) {
      setFetching(true);

      const fetchGraph = async () => {
        try {
          let acc = await instance.getActiveAccount(); //instance.getActiveAccount();

          const option = {
            account: acc, //instance.getActiveAccount(),
            scopes: options?.scopes || ["User.Read"], //protectedResources.graphMe.scopes,
            interactionType: InteractionType.Redirect
          };
          let gClient = getGraphClient(option)
            .api(url)
            .header(
              options?.eventual ? "ConsistencyLevel" : "ConsistencyLevel",
              options?.eventual ? "eventual" : "eventual"
            );

          await setClient(gClient);
          setFetching(false);
          setDoRequest(false);
        } catch (err) {
          console.log("FetchGraph called Error");

          await setError(err);
          await setFetching(false);
          setDoRequest(false);
        }
      };

      fetchGraph();
    }
  }, [url, options, doRequest, accounts]);

  return [client, fetching, handleDoRequest, error];
}
