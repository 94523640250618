import { createGuid } from ".";

const createEmptyAudience = () => {
  const newEmpty: AudienceFormItem = {
    id: createGuid(),
    value: "",
    excluded_flag: false,
    audience_type: "",
    operation: "CREATE"
  };
  return newEmpty;
};

export default createEmptyAudience;
