import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

interface useApiPatchProps {
  api: (data: any) => string;
  options?: {};
}

const loginRequests = {
  scopes: [process.env.REACT_APP_API_SCOPE as string]
};

const API_PATH = process.env.REACT_APP_API_PATH;

const useApiPatch = (props: useApiPatchProps) => {
  const { api, options } = props;

  const { instance, accounts } = useMsal();

  const mutation = useMutation((data: any) => {
    const path =
      api(data).indexOf("http") > -1 ? api(data) : API_PATH + api(data);
    //console.log("path", path, data);
    return instance
      .acquireTokenSilent({ ...loginRequests, account: accounts[0] })
      .then((response) => {
        return axios
          .patch(path, data, {
            headers: {
              Authorization: "Bearer " + response.accessToken,
              Accept: "application/json"
            }
          })
          .then((res: any) => res)
          .catch((err) => {});
      });
  }, options);

  return mutation;
};

export default useApiPatch;
