import { useAtom } from "jotai";
import { memo } from "react";
import { notificationsAtom } from "../../../../../globalstates";
import ItemAnnouncementDialog from "./ItemAnnouncementDialog";

const ItemAnnouncementContainer = () => {
  const [notifications, setNotifications] = useAtom(notificationsAtom);

  const handleOnClose = () => {
    setNotifications((prev) => ({
      ...prev,
      announcementsDismissed: true
    }));
  };
  return (
    <ItemAnnouncementDialog
      open={
        !notifications.announcementsDismissed &&
        notifications.announcementsCount > 0
      }
      onClose={handleOnClose}
    />
  );
};

export default memo(ItemAnnouncementContainer);
