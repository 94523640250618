import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Pagination
} from "@mui/material";
import { memo, useEffect } from "react";

import ItemAnnouncementDataDisplay from "./ItemAnnouncementDataDisplay";
import useItemAnnouncementDialog from "./useItemAnnouncementDialog";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useUserItemSubmit } from "../../../../../hooks/dataHooks";

interface ItemAnnouncementDialogProps {
  open?: boolean;
  onClose?: Function;
}

const ItemAnnouncementDialog = (props: ItemAnnouncementDialogProps) => {
  const { open = false, onClose } = props;
  //const [isOpen, setIsOpen] = useState(open);
  const {
    item,
    handleNext,
    handleBack,
    count,
    currentIndex,
    setCurrentIndex,
    refetch
  } = useItemAnnouncementDialog();

  const { isPosting, isPostingError, isPostingSuccess, postItem } =
    useUserItemSubmit();

  const handleClose = () => {
    //setIsOpen(false);
    onClose?.();
  };

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    selectedPage: number
  ) => {
    //console.log(selectedPage);
    setCurrentIndex(selectedPage - 1);
  };

  const handleMarkRead = () => {
    //setCurrentIndex(currentIndex + 1);
    postItem({ id: item?.id as number, status: "COMPLETE" });
    refetch();
  };

  // useEffect(() => {
  //   console.log("currentIndex", currentIndex);
  // }, [currentIndex]);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        PaperProps={{ sx: { minHeight: "90%" } }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box>Announcements</Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "2rem" }}>
          <ItemAnnouncementDataDisplay
            data={item?.item?.item_data || { content: "" }}
          />
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem"
            }}
          >
            <Pagination
              count={count}
              page={currentIndex + 1}
              defaultPage={1}
              onChange={handleChange}
              size="large"
              //hidePrevButton
              //hideNextButton
            />
            <Button
              variant="contained"
              onClick={() => {
                handleMarkRead();
              }}
              endIcon={<NavigateNextIcon />}
            >
              Mark Read
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(ItemAnnouncementDialog);
