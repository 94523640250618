import NotificationsActive from "@mui/icons-material/NotificationsActive";
import { motion } from "framer-motion";

interface AnimatedNotificationIconProps {
  animated?: boolean;
}

const AnimatedNotificationIcon = (props?: AnimatedNotificationIconProps) => {
  return (
    <>
      <motion.div
        animate={props?.animated ? { rotateZ: [0, 15, -15, 15, -15, 0] } : ""}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.1, 0.2, 0.3, 0.4, 0.5],
          repeat: Infinity,
          repeatDelay: 5,
        }}
        style={{ height: 24 }}
      >
        <NotificationsActive />
      </motion.div>
    </>
  );
};

export default AnimatedNotificationIcon;
