import { atom } from "jotai";

interface GlobalProps {
  environment: string;
  signedIn: boolean;
  displayWelcome?: boolean;
  // isSysAdmin?: boolean;
  // isDepAdmin?: boolean;
  // currentDepartment?: string;
}

export const globalAtom = atom<GlobalProps>({
  environment: "",
  signedIn: false,
  displayWelcome: false
});

export default globalAtom;
