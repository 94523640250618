import { ErrorBoundary } from "../../../../../components";
import { ITEMTYPES } from "../../../../../types/enums";
import { EcentreItemCard } from "../../../externalitems/ecentre";
import InformationItemCard from "../../../information/components/ui/InformationItemCard";
import MeetingItemCard from "../../../meeting/components/ui/MeetingItemCard";
import VideoItemCard from "../../../video/components/ui/VideoItemCard";

// export interface OnItemActionPerformedProps{
//   success: boolean;
//   message?: string;
//   value?: any;
// }

interface UserItemProps {
  item: ItemUserProps;
  onItemActionPerformed?: (
    success: boolean,
    message?: string,
    value?: any
  ) => void;
}

const UserItem = (props: UserItemProps) => {
  //console.log(props.item.item, props.item.item.item_type);
  if (props.item.item.item_type === ITEMTYPES.INFORMATION.KEY)
    return (
      <ErrorBoundary
        errorCode={`Error showing item id : ${props.item.item_id}`}
      >
        <InformationItemCard
          userItem={props.item}
          onItemActionPerformed={props.onItemActionPerformed}
        />
      </ErrorBoundary>
    );

  if (props.item.item.item_type === ITEMTYPES.VIDEO.KEY)
    return (
      <ErrorBoundary
        errorCode={`Error showing item id : ${props.item.item_id}`}
      >
        <VideoItemCard
          userItem={props.item}
          onItemActionPerformed={props.onItemActionPerformed}
        />
      </ErrorBoundary>
    );

  if (props.item.item.item_type === ITEMTYPES.ECENTRE.KEY)
    return (
      <ErrorBoundary
        errorCode={`Error showing item id : ${props.item.item_id}`}
      >
        <EcentreItemCard userItem={props.item} />
      </ErrorBoundary>
    );

  if (props.item.item.item_type === ITEMTYPES.MEETING.KEY)
    return (
      <ErrorBoundary
        errorCode={`Error showing item id : ${props.item.item_id}`}
      >
        <MeetingItemCard
          userItem={props.item}
          onItemActionPerformed={props.onItemActionPerformed}
        />
      </ErrorBoundary>
    );

  return <></>;
};

export default UserItem;
