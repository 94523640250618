import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

interface useApiCallProps {
  name?: string[];
  api: string;
  options?: {};
}

const loginRequests = {
  scopes: [process.env.REACT_APP_API_SCOPE as string]
};

const API_PATH = process.env.REACT_APP_API_PATH;

const useApiCall = (props: useApiCallProps) => {
  const { name, api, options } = props;

  const { instance, accounts } = useMsal();

  const queryObj = useQuery(
    name ? [...name] : ["apiCall"],
    () => {
      const path = api.indexOf("http") > -1 ? api : API_PATH + api;
      return instance
        .acquireTokenSilent({ ...loginRequests, account: accounts[0] })
        .then((response) => {
          return axios
            .get(path, {
              headers: {
                Authorization: "Bearer " + response.accessToken,
                Accept: "application/json"
              }
            })
            .then((res: any) => res);
          // .catch((err) => {
          //   console.log("acios api error", err);
          //   return err;
          // });
        });
    },
    { ...options }
  );

  // useEffect(() => {
  //   if (queryObj.error) console.log("apiCall Error :", queryObj.error);
  // }, [queryObj.error]);

  return queryObj;
};

export default useApiCall;
