import journeyGetGroupStats from "./journeyGetGroupStats";

const journeyGetStats = (journey: ItemUserJourneyCompleteProps) => {
  //ItemUserJourney
  //console.log("journeyGetStats journey", journey);
  const group_count = journey.item_data.groups.length;

  const groupsStats = journey.item_data.groups.map((group) => {
    const groupStats = journeyGetGroupStats(group);
    console.info(groupStats);
    return {
      group_id: group.id,
      group_stats: groupStats
    };
  });

  const groups_completed = groupsStats.filter(
    (groupstat) =>
      groupstat.group_stats.completed === groupstat.group_stats.count
  ).length;

  const item_counts = groupsStats.reduce(
    (acc, groupS) => {
      return {
        completed_items: acc.completed_items + groupS.group_stats.completed,
        total_items: acc.total_items + groupS.group_stats.count
      };
    },
    { completed_items: 0, total_items: 0 }
  );

  const group_percent = (100 / group_count) * groups_completed;
  const item_percent =
    (100 / item_counts.total_items) * item_counts.completed_items;
  //console.log(item_counts);

  const stats = {
    count: group_count,
    completed_count: groups_completed,
    completed: group_count == groups_completed,
    group_percent: group_percent,
    completed_items: item_counts.completed_items,
    total_items: item_counts.total_items,
    item_percent: item_percent
  };

  return stats;
};

export default journeyGetStats;
