import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useCallback, useDeferredValue, useEffect, useState } from "react";
import useGraph from "./useGraph";
import useUpdateEffect from "./useUpdateEffect";

// interface IUserProfile {
//   aboutMe: string;
//   businessPhones: string[];
//   city: string;
//   displayName: string;
//   givenName: string;
//   surname: string;
//   companyName: string;
//   department: string;
//   userPrincipalName: string;
//   officeLocation: string;
//   jobTitle: string;
//   manager: any;
//   mail: string;
//   sysAdmin?: boolean;
//   depAdmin?: boolean;
//   photoUrl?: string | null;
// }

const sysgroup = process.env.REACT_APP_SYSADMINGROUP as string;
const depgroup = process.env.REACT_APP_DEPADMINGROUP as string;

export default function useClientProfile(): [
  User | undefined,
  boolean,
  () => void
] {
  const [userProfile, setUserProfile] = useState<User | undefined>();
  const [fetching, setFetching] = useState(true);

  const { instance } = useMsal();

  const getGraphData = async () => {
    try {
      const scopes = ["User.Read"];
      const accessToken = await instance.acquireTokenSilent({ scopes });

      const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      //console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGraphData();
  }, []);

  const [meData, meFetching, doMeFetch, meError] = useGraph(
    "/me?$select=aboutMe,givenName,surname,displayName,businessPhones,city,companyName,department,userPrincipalName,officeLocation,manager,jobTitle,mail",
    null,
    true
  );
  const [mePhoto, photoFetching, doPhotoFetch, photoError] = useGraph(
    "/me/photo/$value",
    null,
    true
  );

  const [sysAdmin, sysAdminFetching, doSysAdminFetch, sysAdminError] = useGraph(
    `/me/memberOf/${sysgroup}`,
    null,
    true
  );

  const [depAdmin, depAdminFetching, doDepAdminFetch, depAdminError] = useGraph(
    `/me/memberOf/${depgroup}`,
    null,
    true
  );

  const deferredUser = useDeferredValue(userProfile);

  const [photoObj, setPhotoObj] = useState<{ photoUrl: string } | undefined>();

  const [sysAdminObj, setSysAdminObj] = useState<
    { sysAdmin: boolean } | undefined
  >();

  const [depAdminObj, setDepAdminObj] = useState<
    { depAdmin: boolean } | undefined
  >();

  //console.log("meData", meData);
  const handleDoRequest = useCallback(async () => {
    //console.log("Do Profile Fetch");
    await setFetching(true);
    await doMeFetch(true);
    await doPhotoFetch(true);
    await doSysAdminFetch(true);
    setFetching(false);
  }, []);

  useEffect(() => {
    //console.log(meFetching, meData, photoObj, sysAdminObj, depAdminObj);
    if (!meFetching && meData && photoObj && sysAdminObj && depAdminObj) {
      if (photoObj && sysAdminObj) {
        setUserProfile({
          ...meData,
          ...photoObj,
          ...sysAdminObj,
          ...depAdminObj
        });
        setFetching(false);
      }
    }
  }, [meData, photoObj, sysAdminObj, meFetching, depAdminObj]);

  useEffect(() => {
    if (mePhoto && !photoFetching && !photoError) {
      const photoUrl = window.URL || window.webkitURL;
      const photoBlobUrl = photoUrl.createObjectURL(mePhoto);
      const photoObj = {
        photoUrl: photoBlobUrl
      };
      setPhotoObj(photoObj);
    }

    if (photoError) {
      setPhotoObj({ photoUrl: "" });
    }
  }, [mePhoto, photoFetching, photoError]);

  useEffect(() => {
    if (!sysAdminFetching && sysAdmin && !sysAdminError) {
      const newObj = {
        sysAdmin: true,
        depAdmin: true
      };
      setSysAdminObj(newObj);
    }
    if (sysAdminError) {
      setSysAdminObj({
        sysAdmin: false
      });
    }
  }, [sysAdmin, sysAdminError, sysAdminFetching]);

  useEffect(() => {
    if (!depAdminFetching && depAdmin && !depAdminError) {
      //console.log(depAdmin);
      const newObj = {
        depAdmin: true
      };
      setDepAdminObj(newObj);
      //console.log(depAdmin);
    }
    if (depAdminError) {
      setDepAdminObj({
        depAdmin: false
      });
    }
  }, [depAdmin, depAdminError, depAdminFetching]);

  // useEffect(() => {
  //   console.count("useClientProfile Fetching");
  //   console.log(fetching);
  // }, [fetching]);

  // useEffect(() => {
  //   console.count("useClientProfile meFetching");
  //   console.log(meFetching);
  // }, [meFetching]);

  // useEffect(() => {
  //   console.log("meError", meError);
  //   console.log("photoError", photoError);
  //   console.log("sysAdminError", sysAdminError);
  // }, [meError, photoError, sysAdminError]);

  return [deferredUser, fetching, handleDoRequest];
}
