import { Box, Typography } from "@mui/material";

import ItemAnnouncementDataDisplay from "./ItemAnnouncementDataDisplay";

const AnnouncementReadOnly = ({
  item
}: {
  item: ItemCompleteFormProps | ItemCompleteProps;
}) => {
  return (
    <>
      <Typography variant="overline">Announcement Content:</Typography>
      <Box sx={{ padding: "1em" }}>
        <ItemAnnouncementDataDisplay data={item.item_data} />
      </Box>
    </>
  );
};

export default AnnouncementReadOnly;
