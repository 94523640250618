import * as MuiIcons from "@mui/icons-material";
import { IconProps } from "@mui/material";

interface MuiIconProps extends IconProps {
  iconName: string;
}

const MuiIcon = (props: Partial<MuiIconProps>) => {
  const { iconName, color, sx, ...rest } = props;
  //console.log(iconName as keyof typeof MuiIcons);
  //console.log(MuiIcons.hasOwnProperty(iconName));
  if (iconName) {
    if (iconName in MuiIcons) {
      const Icon = MuiIcons[iconName as keyof typeof MuiIcons];
      return <Icon color={color} sx={sx} />;
    }
  }

  // try {
  //   const Icon = MuiIcons[iconName as keyof typeof MuiIcons];
  //   return <Icon color={color} sx={sx} />;
  // } catch (err) {
  //   return <></>;
  // }

  return <></>;
};
export default MuiIcon;
