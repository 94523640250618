import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/material";
import LoadingFullScreen from "../../components/LoadingFullScreen";
import { AppTheme } from "./AppTheme";
import useApp from "./useApp";
import AppRoutes from "./AppRoutes";

function App() {
  const { loading } = useApp();
  return (
    <div className="App">
      <ThemeProvider theme={AppTheme}>
        {loading && (
          <>
            <LoadingFullScreen title="Fetching your details...." />
          </>
        )}
        {!loading && (
          <Box className="AppContainer">
            <Box className="AppContentContainer">
              <Router>
                <AppRoutes />
              </Router>
            </Box>
          </Box>
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
