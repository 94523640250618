import { OPERATIONTYPES } from "../../types/enums";
import { useAtomValue } from "jotai";
import { userAtom } from "../../globalstates";
import useApiPost from "./useApiPost";
import useApiDelete from "./useApiDelete";
import useApiPatch from "./useApiPatch";
import itemPrepareForSubmit from "../../features/items/utils/itemPrepareForSubmit";

const API_PATH = process.env.REACT_APP_API_PATH;

interface SubmittableUserItem {
  id: number;
  status: string;
}

interface UseUserItemSubmitProps {
  onSuccess?: (value: any) => void;
}

const useUserItemSubmit = (props?: UseUserItemSubmitProps) => {
  const user = useAtomValue(userAtom);

  const mutation = useApiPatch({
    api: (updatedItem: SubmittableUserItem) => {
      return `${API_PATH}/user/item/status?id=${updatedItem.id}`;
    },
    options: { onSuccess: props?.onSuccess }
  });

  //   const createMutation = useApiPost({
  //     api: (createdItem: ItemCompleteFormProps) => `${API_PATH}/admin/item`
  //   });

  //   const deleteMutation = useApiDelete({
  //     api: (deleteId: number) => {
  //       return `${API_PATH}/admin/item/${deleteId}`;
  //     }
  //   });

  const postItem = (item: SubmittableUserItem) => {
    if (!!item) mutation.mutate(item);
  };

  //   const postItem = (item: ItemCompleteFormProps) => {
  //     const preparedItem = itemPrepareForSubmit(item, {
  //       operation: OPERATIONTYPES.UPDATE.KEY,
  //       user: user?.userPrincipalName
  //     });

  //     if (preparedItem) mutation.mutate(preparedItem);
  //   };

  //   const createItem = (item: ItemCompleteFormProps) => {
  //     const itemToCreate: ItemCompleteFormProps = {
  //       ...item,
  //       ...{
  //         item_type: item.item_type.toUpperCase(),
  //         created_by: user?.mail,
  //         administrative_level: "1000000000000000"
  //       }
  //     };
  //     const preparedItem = itemPrepareForSubmit(item, {
  //       operation: "CREATE",
  //       user: user?.userPrincipalName
  //     });
  //     console.log(preparedItem, itemToCreate);

  //   };

  //   const deleteItem = (itemId: number) => {
  //     if (itemId) deleteMutation.mutate(itemId);
  //   };

  const resetPosting = () => {
    mutation.reset();
  };

  //   const resetCreate = () => {
  //     createMutation.reset();
  //   };

  const [
    isPosting,
    isPostingError,
    isPostingSuccess
    // isCreating,
    // isCreateError,
    // isCreateSuccess,
    // isDeleting,
    // isDeleteError,
    // isDeleteSuccess
  ] = [
    mutation.isLoading,
    mutation.isError,
    mutation.isSuccess
    // createMutation.isLoading,
    // createMutation.isError,
    // createMutation.isSuccess,
    // deleteMutation.isLoading,
    // deleteMutation.isError,
    // deleteMutation.isSuccess
  ];

  return {
    isPostingError,
    isPosting,
    isPostingSuccess,
    postItem,
    resetPosting
    // isCreating,
    // isCreateError,
    // isCreateSuccess,
    // createItem,
    // resetCreate,
    // deleteItem,
    // isDeleting,
    // isDeleteError,
    // isDeleteSuccess
  };
};

export default useUserItemSubmit;
