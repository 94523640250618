import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { ITEMUSERSTATUSTYPES } from "../../../../../types/enums";

export interface ItemStatusFilterForm {
  onChange?: (value: string[]) => void;
  value?: string[];
}

type ItemStatusKey = keyof typeof ITEMUSERSTATUSTYPES;

interface ItemStatusState {
  [key: string]: boolean;
}

const defaults = {
  DISPLAY: true,
  VIEW: true,
  COMPLETE: false
};

const valueToObj = (value: string[] | undefined) => {
  const initialValue = value?.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as ItemStatusState);
  return initialValue;
};

const objToArray = (value: ItemStatusState) => {
  const result = Object.entries(value)
    .filter(([_, val]) => val === true)
    .map(([key, _]) => key);
  return result;
};

const ItemStatusFilterForm = (props: ItemStatusFilterForm) => {
  const { onChange, value } = props;
  const initialValue = value?.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as ItemStatusState);

  const [selectedStatus, setSelectedStatus] = useState<
    ItemStatusState | undefined
  >(initialValue || defaults);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: ItemStatusKey
  ) => {
    //setSelectedItems((prev)=>{...prev,[itemType.]:event.target.checked});
    setSelectedStatus((prevState) => ({
      ...prevState,
      [ITEMUSERSTATUSTYPES[key].KEY]: event.target.checked
    }));
  };

  useEffect(() => {
    // const result = Object.entries(selectedStatus)
    //   .filter(([_, value]) => value === true)
    //   .map(([key, _]) => key);
    if (selectedStatus) {
      const result = objToArray(selectedStatus);
      onChange?.(result);
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (value) {
      const initValue = valueToObj(value);
      if (!isEqual(selectedStatus, initValue)) setSelectedStatus(initValue);
    }
  }, [value]);

  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                selectedStatus?.[ITEMUSERSTATUSTYPES.DISPLAY.KEY] || false
              }
              onChange={(event) => {
                handleChange(event, ITEMUSERSTATUSTYPES.DISPLAY.KEY);
              }}
              inputProps={{ "aria-label": ITEMUSERSTATUSTYPES.DISPLAY.DISPLAY }}
            />
          }
          label={
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              {ITEMUSERSTATUSTYPES.DISPLAY.DISPLAY}
            </Box>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedStatus?.[ITEMUSERSTATUSTYPES.VIEW.KEY] || false}
              onChange={(event) => {
                handleChange(event, ITEMUSERSTATUSTYPES.VIEW.KEY);
              }}
              inputProps={{ "aria-label": ITEMUSERSTATUSTYPES.VIEW.DISPLAY }}
            />
          }
          label={
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              {ITEMUSERSTATUSTYPES.VIEW.DISPLAY}
            </Box>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={
                selectedStatus?.[ITEMUSERSTATUSTYPES.COMPLETE.KEY] || false
              }
              onChange={(event) => {
                handleChange(event, ITEMUSERSTATUSTYPES.COMPLETE.KEY);
              }}
              inputProps={{
                "aria-label": ITEMUSERSTATUSTYPES.COMPLETE.DISPLAY
              }}
            />
          }
          label={
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              {ITEMUSERSTATUSTYPES.COMPLETE.DISPLAY}
            </Box>
          }
        />
      </FormGroup>
    </Box>
  );
};

export default ItemStatusFilterForm;
