import { lazy, Suspense } from "react";

import {
  AppBar,
  Box,
  Container,
  ThemeProvider,
  Toolbar,
  useMediaQuery
} from "@mui/material";

import { Navigate, Route, Routes } from "react-router-dom";

import { ClientTheme } from "../themes";
import { MainNav } from "./components";
import { NotFound, ProfilePage } from "..";

import { MainPage, Preferences } from "../../features/client";

import { SubPage } from "../../components";
import { ItemAnnouncementContainer } from "../../features/items/announcement/components/ui";
import LoadingFullScreen from "../../components/LoadingFullScreen";
import { useAtomValue } from "jotai";
import { journeyAtom } from "../../globalstates";

const JourneyPage = lazy(() => import("../../features/client/pages/journey"));

const ClientPage = () => {
  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.up("md"));
  const journeyState = useAtomValue(journeyAtom);
  //console.log(journeyState);
  return (
    <ThemeProvider theme={ClientTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          backgroundColor: "#f6f3ee"
        }}
      >
        {/* Top Nav Bar */}
        <AppBar position="fixed" elevation={0}>
          <Toolbar sx={{ color: "#333" }}>
            <MainNav />
          </Toolbar>
        </AppBar>
        {/* Main Content Section */}
        <Container maxWidth="xl">
          <Box
            className="ClientMainContent"
            sx={{ paddingTop: isLarge ? "80px" : "180px" }}
          >
            <Suspense
              fallback={<LoadingFullScreen title="Fetching assets..." />}
            >
              <Routes>
                <Route
                  index
                  element={
                    (journeyState.count > 0 && !journeyState.dismissed) ||
                    (journeyState.count > 0 &&
                      journeyState.dismissable &&
                      !journeyState.dismissed) ? (
                      <Navigate replace to="/journey" />
                    ) : (
                      <MainPage />
                    )
                  }
                />
                <Route path="journey/*" element={<JourneyPage />} />
                <Route path="preferences/*" element={<Preferences />} />
                <Route path="me" element={<ProfilePage />} />
                <Route
                  path="*"
                  element={
                    <SubPage>
                      <NotFound />
                    </SubPage>
                  }
                />
              </Routes>
            </Suspense>
          </Box>
        </Container>
      </Box>
      <ItemAnnouncementContainer />
    </ThemeProvider>
  );
};

export default ClientPage;
