import { Box, Paper } from "@mui/material";
import ItemIcon from "../../../components/ui/ItemIcons";

const JourneyItemReadOnly = ({ item }: { item: ItemJourneyGroupItemProps }) => {
  console.log(item);
  return (
    <>
      <Paper variant="outlined" sx={{ padding: "0.5rem" }}>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Box>{item && <ItemIcon name={item?.item?.item_type} />}</Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flex: "auto"
            }}
          >
            <Box>{item?.sequence_number}</Box>
            <Box>{item?.item.item_title}</Box> <Box>ID: {item?.item.id}</Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default JourneyItemReadOnly;
