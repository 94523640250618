import { ITEMTYPES } from "../../../types/enums";
import { journeyItemPrepareForSubmit } from "../journey/utils";

const itemPrepareItemDataForSubmit = (
  item_data: any,
  item_type: string,
  operation: string
) => {
  item_data.item_type = item_type;
  if (item_type === ITEMTYPES.JOURNEY.KEY) {
    return journeyItemPrepareForSubmit(item_data, operation);
  }

  return item_data;
};

export default itemPrepareItemDataForSubmit;
