import JoditRTE from "./JoditRTE";

interface RTEditorProps {
  initialValue: string | undefined;
  onChange: (htmlText: string) => void;
  error?: boolean;
  debounceTime?: number;
  displayUserReplace?: boolean;
  displayEmailReplace?: boolean;
}

/**
 * Rich Text Editor with default options set.
 * Set up to return HTML value.
 * Set up to have a custom button that allows users to insert custom replace values into the text.
 * @param props IRTEditor
 * @returns JSX Element
 */
export default function RTEditor({
  initialValue,
  onChange,
  error,
  debounceTime,
  displayEmailReplace,
  displayUserReplace
}: RTEditorProps) {
  return (
    <JoditRTE
      onChange={onChange}
      error={error}
      value={initialValue || ""}
      debounceTime={debounceTime}
    />
  );
}
