import { Box, TextField, useTheme } from "@mui/material";
import { validateDate } from "@mui/x-date-pickers/internals";
import { useEffect, useState } from "react";
import { parse, serialize } from "tinyduration";

interface DurationPickerProps {
  value: string;
  onChange?: (value: string) => void;
  error?: boolean;
  onIsValid?: (value: boolean) => void;
  allowNegativeValues?: boolean;
  fields?: {
    years?: boolean;
    months?: boolean;
    weeks?: boolean;
    days?: boolean;
    hours?: boolean;
    minutes?: boolean;
  };
}

const ClampToZero = (value: number | null | undefined) => {
  if (value) return Math.min(Math.max(value, 0), Infinity);
  return value;
};

const DurationPicker = (props: DurationPickerProps) => {
  const {
    value = "PT0S",
    onChange,
    error = false,
    onIsValid,
    allowNegativeValues = false,
    fields = {
      years: true,
      months: true,
      weeks: true,
      days: true,
      hours: true,
      minutes: true
    }
  } = props;

  const [focused, setFocused] = useState(false);

  const theme = useTheme();

  const [valueObj, setValueObj] = useState(
    value && /\d/.test(value) ? parse(value) : { seconds: 0 }
  );

  const [isValid, setIsValid] = useState(false);

  const handleYears = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(e.target.value)
    //const limitedValue = Number(e.target.value) > 99  ? 99 : Number(e.target.value); //Math.max(0, parseInt(e.target.value) ).toString().slice(0,2);
    //const limitedValue = ClampToZero(e.target.valueAsNumber); //Math.max(0, Math.min(Number(e.target.value), 99));

    setValueObj((prev) => ({
      ...prev,
      years: Number(ClampToZero(e.target.valueAsNumber))
    }));
  };

  const handleMonths = (e: React.ChangeEvent<HTMLInputElement>) => {
    //const limitedValue = ClampToZero(e.target.valueAsNumber);
    setValueObj((prev) => ({
      ...prev,
      months: Number(ClampToZero(e.target.valueAsNumber))
    }));
  };

  const handleWeeks = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueObj((prev) => ({
      ...prev,
      weeks: Number(ClampToZero(e.target.valueAsNumber))
    }));
  };

  const handleDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueObj((prev) => ({
      ...prev,
      days: Number(ClampToZero(e.target.valueAsNumber))
    }));
  };

  const handleHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueObj((prev) => ({
      ...prev,
      hours: Number(ClampToZero(e.target.valueAsNumber))
    }));
  };

  const handleMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueObj((prev) => ({
      ...prev,
      minutes: Number(ClampToZero(e.target.valueAsNumber))
    }));
  };

  const validate = () => {
    ///\d/.test(value);
    const isValid =
      (valueObj?.years && valueObj?.years > 0) ||
      (valueObj?.months && valueObj?.months > 0) ||
      (valueObj?.weeks && valueObj?.weeks > 0) ||
      (valueObj?.days && valueObj?.days > 0) ||
      (valueObj?.hours && valueObj?.hours > 0) ||
      (valueObj?.minutes && valueObj?.minutes > 0);

    onIsValid?.(!!isValid);
  };
  const durationToString = () => {};

  useEffect(() => {
    if (valueObj) {
      //   const durationValue = `P${
      //     valueObj?.years && valueObj?.years > 0 ? valueObj?.years + "Y" : ""
      //   }${
      //     valueObj?.months && valueObj?.months > 0 ? valueObj?.months + "M" : ""
      //   }${valueObj?.weeks && valueObj?.weeks > 0 ? valueObj?.weeks + "W" : ""}${
      //     valueObj?.days && valueObj?.days > 0 ? valueObj?.days + "D" : ""
      //   }T`;
      const durationValue = serialize(valueObj);

      //console.log(durationValue, valueObj);
      validate();
      onChange?.(durationValue);
    }
  }, [valueObj]);

  return (
    <Box
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      sx={{
        border: error
          ? focused
            ? `solid 2px ${theme.palette.error.main}`
            : `solid 1px ${theme.palette.error.main}`
          : focused
          ? "solid 2px " + theme.palette.primary.main
          : `solid 1px rgba(0, 0, 0, 0.23)`,
        borderRadius: "4px",
        padding: "4px",
        boxSizing: "border-box"
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          gap: "0.2rem",
          padding: "0.2rem"
        }}
      >
        {fields.years && (
          <TextField
            type="number"
            label="Years"
            value={valueObj?.years || 0}
            onChange={handleYears}
            sx={{ width: "6rem" }}
            //size="small"
            variant="filled"
            inputProps={{ sx: { textAlign: "center" } }}
          />
        )}
        {fields.months && (
          <TextField
            type="number"
            label="Months"
            value={valueObj?.months || 0}
            onChange={handleMonths}
            sx={{ width: "6rem" }}
            //size="small"
            variant="filled"
            inputProps={{ sx: { textAlign: "center" } }}
          />
        )}
        {fields.weeks && (
          <TextField
            type="number"
            label="Weeks"
            value={valueObj?.weeks || 0}
            onChange={handleWeeks}
            sx={{ width: "6rem" }}
            //size="small"
            variant="filled"
            inputProps={{ sx: { textAlign: "center" } }}
            //inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
        )}
        {fields.days && (
          <TextField
            type="number"
            label="Days"
            value={valueObj?.days || 0}
            onChange={handleDays}
            sx={{ width: "6rem" }}
            //size="small"
            variant="filled"
            inputProps={{ sx: { textAlign: "center" } }}
            //inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
        )}
        {fields.hours && (
          <TextField
            type="number"
            label="Hours"
            value={valueObj?.hours || 0}
            onChange={handleHours}
            sx={{ width: "6rem" }}
            //size="small"
            variant="filled"
            //inputProps={{ sx: { textAlign: "center" } }}
            //inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            inputProps={{
              sx: { textAlign: "center" },
              min: 0,
              max: 24
            }}
          />
        )}

        {fields.minutes && (
          <TextField
            type="number"
            label="Minutes"
            value={valueObj?.minutes || 0}
            onChange={handleMinutes}
            sx={{ width: "6rem" }}
            //size="small"
            variant="filled"
            //inputProps={{ sx: { textAlign: "center" } }}
            inputProps={{
              sx: { textAlign: "center" },
              min: 0,
              max: 60
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default DurationPicker;
