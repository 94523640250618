import { Avatar, Box, SxProps, Typography } from "@mui/material";
import useGraphUser from "../../hooks/useGraphUser";

interface PersonAvatarProps {
  upn: string;
  includeImage?: boolean;
  imageOnly?: boolean;
  avatarSx?: SxProps;
  wrapperSx?: SxProps;
  direction?: "column" | "row";
}

const PersonAvatar = (props: PersonAvatarProps) => {
  const {
    upn,
    includeImage = true,
    imageOnly = false,
    avatarSx,
    wrapperSx,
    direction = "row"
  } = props;

  const [personProfile, fetchingPerson, refetchPerson] = useGraphUser(
    upn,
    includeImage
  );
  //const targetDetails = useGraphUser(targetUserDetails?.userPrincipalName)
  //console.log("target user", personProfile);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: direction,
        alignItems: "center",
        gap: "0.4rem",
        justifyContent: "center",
        ...wrapperSx
      }}
    >
      <Avatar
        src={personProfile?.photoUrl || ""}
        sx={{
          //border: "solid 0.2rem #FFF",
          //borderColor: "primary.main",
          height: "3rem",
          width: "3rem",
          fontSize: ".8rem",
          //margin: "auto",
          //backgroundColor: personProfile?.photoUrl ? "white" : "primary.main",
          bgcolor: stringToColor(personProfile?.displayName || ""),
          color: "#FFF",
          ...avatarSx
        }}
        // {...stringAvatar(personProfile?.displayName || "")}
      >
        {personProfile &&
          personProfile?.givenName?.substring(0, 1) +
            personProfile?.surname?.substring(0, 1)}
      </Avatar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: direction === "column" ? "center" : "flex-start"
        }}
      >
        <Box>{personProfile?.displayName}</Box>
        <Box>
          <Typography variant="caption">{personProfile?.jobTitle}</Typography>
        </Box>
        <Box>
          <a
            href={`mailto:${personProfile?.mail}`}
            style={{ color: "inherit" }}
          >
            <Typography variant="caption">{personProfile?.mail}</Typography>
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonAvatar;

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
  };
}
