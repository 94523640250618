const journeyGetGroupStats = (group: ItemUserJourneyGroupProps) => {
  const count = group.items?.length || 0;
  const completed =
    group.items?.filter((item) => {
      return item.item_detail?.status === "COMPLETE";
    }).length || 0;
  const percent = (100 / count) * completed;

  const stats = {
    count: count,
    completed: completed,
    percent: percent
  };
  return stats;
};

export default journeyGetGroupStats;
