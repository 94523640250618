import { useRef, useState, useEffect } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useItemsAdmin } from "../../../../../hooks";
//import { useAtomValue } from "jotai";
//import { userAtom } from "../../../../../globalstates";
import ItemIcon from "../../ui/ItemIcons";

interface AutocompleteItemProps {
  onChange?: Function;
  onChangeItem?: (
    val: Partial<ItemCompleteFormProps> | null | undefined
  ) => void;
  value?: any;
  displayId?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  error?: boolean;
  options?: { item_types?: string[] };
}

const AutocompleteItem = (props: AutocompleteItemProps) => {
  const {
    onChange,
    onChangeItem,
    value,
    displayId,
    fullWidth,
    required,
    error,
    options
  } = props;

  //const user = useAtomValue(userAtom);
  const { items, fetching } = useItemsAdmin({
    //upn: user?.userPrincipalName,
    types: options?.item_types || []
  });

  // const [autocompleteOptions, setAutocompleteOptions] = useState<
  //   { label: string; value: string; item: ItemProps }[]
  // >([]);

  const searchField = useRef();
  const [selectedValue, setSelectedValue] = useState<
    ItemCompleteFormProps | undefined | null
  >(null);

  const [selectedItemId, setSelectedItemId] = useState<
    number | undefined | null
  >();

  //   const [selectedValue, setSelectedValue] = useState<
  //     | {
  //         label: string;
  //         value: string;
  //       }
  //     | null
  //     | undefined
  //   >(null);

  //   useEffect(() => {
  //     if (items) {
  //       console.log(items);
  //       setAutocompleteOptions(items
  //         items?.map((item) => {
  //           return {
  //             label: `(${item.id}): ${item.item_title}`,
  //             value: item.id as string
  //           };
  //         })
  //       );
  //     }
  //   }, [items]);

  const handleSearchField = () => {};

  const handleOnChange = (val: ItemCompleteFormProps | null) => {
    //console.log("AutocompleteItem handleOnChange", val);

    onChange?.(val?.id);

    setSelectedValue(val);

    onChangeItem?.(val);
  };

  return (
    <>
      <Autocomplete
        fullWidth={props.fullWidth || false}
        id="auto-complete-item"
        options={items}
        ref={searchField}
        value={selectedValue}
        // getOptionLabel={(option: { label: string; value: string }) =>
        //   option.label
        // }

        //getOptionLabel={(option:{label:string;value:ItemCompleteFormProps})=>option.label}
        getOptionLabel={(option) => `${option.id} ${option.item_title}`}
        loading={fetching}
        // isOptionEqualToValue={(
        //   option: { label: string; value: string },
        //   value: { label: string; value: string }
        // ) => option.value === value.value}

        // isOptionEqualToValue={(
        //   option: { label: string; value: ItemCompleteFormProps },
        //   value: ItemCompleteFormProps
        // ) => option.value.id === value.id}

        // isOptionEqualToValue={(
        //     option: { label: string; value: ItemCompleteFormProps },
        //     value: ItemCompleteFormProps
        //   ) => option.value.id === value.id}

        onChange={(event, val) => {
          handleOnChange(val);
        }}
        onSelect={(e) => {
          //console.log(e.target);
          //console.log(e.target.value);
        }}
        onInputChange={handleSearchField}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={error}
            label="Item"
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <ItemIcon name={option.item_type} />
                <span>
                  {option.id}. {option.item_title}
                </span>
              </Box>
            </li>
          );
        }}
      />
    </>
  );
};

export default AutocompleteItem;
