import { useRef, useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import useGraph from "../../../../hooks/useGraph";
//import { Departments } from "../../../DEMO/DemoData";
import { useClevels } from "../../../../hooks";
import { useAtomValue } from "jotai";
import { userAdminAtom } from "../../../../globalstates";

interface AutocompleteHierarchyProps {
  onChange?: Function;
  value?: any;
  displayId?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  error?: boolean;
  restrictToAdminLevel?: boolean;
}

let timer: any;
const typeTimeout = 1000;

const AutocompleteHierarchy = (props: AutocompleteHierarchyProps) => {
  const { required = false, error = false, restrictToAdminLevel } = props;
  const userAdminState = useAtomValue(userAdminAtom);
  const { clevels, search, fetching } = useClevels({
    format: (val: Clevel) => {
      return {
        label: `${val.unit}${props.displayId ? " (" + val.clevel + ")" : ""}`,
        value: val.clevel
      };
    },
    restrictToLevel:
      restrictToAdminLevel &&
      userAdminState &&
      userAdminState.currentLevel &&
      userAdminState.currentLevel.length > 0
        ? [userAdminState.currentLevel]
        : []
  });

  const [hierarchyData, setHierarchyData] = useState<
    { label: string; value: string }[]
  >(clevels || []);

  const searchField = useRef();

  const [selectedValue, setSelectedValue] = useState<
    | {
        label: string;
        value: string;
      }
    | null
    | undefined
  >(null);

  useEffect(() => {
    if (props.value && hierarchyData && hierarchyData.length > 0) {
      try {
        const match = hierarchyData.find((h: any) => {
          return h.value === props.value;
        });
        //console.log("AutocompleteHierarchy match", match);
        match
          ? setSelectedValue(match)
          : setSelectedValue({ label: props.value, value: props.value });
      } catch (err) {
        console.error("No Match", err);
        setSelectedValue({ label: props.value, value: props.value });
      }
    }
  }, [props.value, hierarchyData]);

  // useEffect(() => {
  //   // if (props.value) {
  //   console.log("AutocompleteHierarchy props.value", props.value);
  //   //}
  // }, [props.value]);

  useEffect(() => {
    if (!fetching && clevels) {
      setHierarchyData(clevels);
    }
  }, [clevels, fetching]);

  function handleSearchField(event: any) {
    if (event?.type === "change") {
      clearTimeout(timer);
    }
  }

  return (
    <>
      <Autocomplete
        fullWidth={props.fullWidth || false}
        id="auto-complete-hierarchy"
        options={hierarchyData}
        ref={searchField}
        value={selectedValue}
        getOptionLabel={(option: { label: string; value: string }) =>
          option.label
        }
        loading={fetching}
        isOptionEqualToValue={(
          option: { label: string; value: string },
          value: { label: string; value: string }
        ) => option.value === value.value}
        onChange={(event, val) => {
          props.onChange?.(val);
          setSelectedValue(val);
        }}
        onSelect={(e) => {
          //console.log(e.target);
          //console.log(e.target.value);
        }}
        onInputChange={handleSearchField}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={error}
            label="Hierarchy"
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }}
      />
    </>
  );
};

export default AutocompleteHierarchy;
