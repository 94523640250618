import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { Box } from "@mui/material";
import { MultiFormDisplay } from "../../../../../components";

import JourneyGroupItemDataReadOnly from "../ui/JourneyGroupItemDataReadOnly";

interface JourneyGroupItemProps {
  item?: ItemJourneyGroupItemProps;
  sortProps?: SyntheticListenerMap | undefined;
  onDelete?: () => void;
  onRestore?: () => void;
  dragHandle?: JSX.Element;
}

const JourneyGroupItem = (props: JourneyGroupItemProps) => {
  const { item, sortProps, onDelete, onRestore, dragHandle } = props;

  return (
    <MultiFormDisplay
      operation={item?.operation}
      onDelete={() => {
        onDelete?.();
      }}
      onRestore={() => {
        onRestore?.();
      }}
      wrapperProps={sortProps}
    >
      {/* <Paper variant="outlined" sx={{ padding: "0.5rem" }} {...sortProps}> */}
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        {!!dragHandle && dragHandle}
        {item && <JourneyGroupItemDataReadOnly item={item} />}
      </Box>
    </MultiFormDisplay>
  );
};
export default JourneyGroupItem;
