import { ThemeOptions, createTheme } from "@mui/material/styles";

/**
 * Primarty MUI Theme overrides to customise the application.
 */
export const AppTheme = createTheme({
  // : ThemeOptions = {
  palette: {
    //type: "light",

    whitePrimary: {
      main: "#fff",
      contrastText: "#333"
    }
  }
  // palette: {
  //   background: {
  //     default: "#FAFDFF", //"#fafafa", //"#EAFCFF", //"#AAA", //#fafdff //#f2edf2 //#e3eeea
  //   },
  //   whitePrimary: {
  //     main: "#FFF",
  //     contrastText: "#000",
  //   },
  // },
  // typography: {
  //   regionheader: {
  //     fontWeight: "bolder",
  //     fontSize: "1.1em",
  //     opacity: "0.6",
  //   },
  // },
  // components: {
  //   MuiListItemButton: {
  //     styleOverrides: {
  //       root: {
  //         "&.Mui-selected": {
  //           backgroundColor: "rgba(0, 166, 221, 0.2)", //"rgba(8, 0, 255, 0.2)",
  //         },
  //         ":hover": {
  //           backgroundColor: "rgba(0, 166, 2215, 0.1)",
  //         },
  //         "&.Mui-selected:hover": {
  //           backgroundColor: "rgba(255, 184, 203, 0.4)",
  //         },
  //       },
  //     },
  //   },
  //   MuiPaper: {
  //     defaultProps: {
  //       elevation: 3,
  //     },
  //     styleOverrides: {
  //       root: {
  //         // "&.MuiPaper-rounded": { borderRadius: "0.4em" },
  //         // "&.MuiPaper-elevation3": {
  //         //   boxShadow: "0px 3px 3px 0px rgba(0, 166, 221, 0.4)",
  //         // },
  //       },
  //     },
  //   },
  // },
});

declare module "@mui/material/styles" {
  interface Palette {
    whitePrimary: Palette["primary"];
  }
  interface PaletteOptions {
    whitePrimary?: PaletteOptions["primary"];
  }

  interface TypographyVariants {
    regionheader: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    regionheader?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    regionheader: true;
  }
}
