import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { isObject } from "lodash";
import { useAtom } from "jotai";
import clevelAtom from "../../globalstates/clevelAtom";
import useApiCall from "./useApiCall";
import { StringLiteralLike } from "typescript";
//import { Clevel } from "../../items/itemtypes";

//const API_PATH = process.env.REACT_APP_API_PATH;

interface UseClevelSearchObj {
  value: string;
  level: "branch" | "division" | "section" | "unit";
}

interface UseClevelProps {
  searchString?: UseClevelSearchObj | string;
  caseSensitive?: boolean;
  format?: Function;
  restrictToLevel?: string[];
  options?: {};
  name?: string[];
}

export default function useClevels(props: UseClevelProps) {
  const [clevelState, setClevelState] = useAtom(clevelAtom);
  const {
    searchString = null,
    caseSensitive = false,
    format = null,
    restrictToLevel,
    options,
    name = ["clevels"]
  } = props;

  const [rawClevels, setRawClevels] = useState<Clevel[] | undefined>();
  const [clevels, setClevels] = useState<Clevel[] | undefined | any>();
  const [fetching, setFetching] = useState(false);

  // const { isLoading, error, data, isFetching, refetch } = useQuery(
  //   "clevels",
  //   () => axios.get(`${API_PATH}/reference/clevels`).then((res: any) => res),
  //   // .catch((err) => {
  //   //   console.log("Fetch Error", err);
  //   // })
  //   { staleTime: Infinity, cacheTime: Infinity }
  // );

  const apiString =
    restrictToLevel && restrictToLevel?.length > 0
      ? `/admin/administration/clevel?clevel=${restrictToLevel.join(
          "&clevel="
        )}`
      : `/reference/clevels`;
  //console.log("Clevel request api ", apiString);
  const { isLoading, error, data, isFetching, refetch } = useApiCall({
    name: name, //["clevels"],
    api: apiString, //`/reference/clevels`,
    options: options
    //options: { staleTime: Infinity, cacheTime: Infinity }
  });

  useEffect(() => {
    if (data) {
      setRawClevels(data.data);
    }
  }, [data, isFetching, clevelState]);

  useEffect(() => {
    if (rawClevels && rawClevels?.length > 0) {
      if (format) {
        setClevels(rawClevels.map((clevel) => format(clevel)));
      } else {
        setClevels(rawClevels);
      }
    }
  }, [rawClevels]);

  useEffect(() => {
    if (fetching && clevels && clevels.length > 0) setFetching(false);
  }, [clevels, fetching]);

  const search = (searchVal: string | UseClevelSearchObj) => {
    setFetching(true);
    if (rawClevels && rawClevels.length > 0) {
      const searched = rawClevels.filter((clevel: Clevel) => {
        if (searchVal && typeof searchVal === "string") {
          let hasVal = false;
          Object.entries(clevel).every((prop) => {
            hasVal = prop[1].toUpperCase().includes(searchVal.toUpperCase());
            return !hasVal;
          });
          return hasVal;
        }
        if (
          isObject(searchVal) &&
          searchVal.hasOwnProperty("value") &&
          searchVal.hasOwnProperty("level")
        ) {
          return clevel[searchVal.level]
            .toUpperCase()
            .includes(searchVal.value.toUpperCase());
        }
      });

      if (searched && searched.length > 0) {
        if (format) {
          setClevels(searched.map((clevel) => format(clevel)));
        } else {
          setClevels(searched);
        }
        // setClevels(searched);
      }
    }
  };

  return { clevels, search, fetching };
}
