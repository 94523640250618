import { PropsWithChildren } from "react";
import { MsalProvider } from "@azure/msal-react";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult
} from "@azure/msal-browser";

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_CLIENT_ID as string,
    authority: process.env.REACT_APP_AUTHORITY as string
    //redirectUri: window.location.origin //process.env.REACT_APP_REDIRECTURI as string
  }
  // system: {
  //   allowRedirectInIframe: true
  // }
};

export const msalInstance = new PublicClientApplication(configuration);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

interface AppAuthenticationProps {}

const AppAuthentication = (
  props: PropsWithChildren<AppAuthenticationProps>
) => {
  const { children } = props;
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default AppAuthentication;
