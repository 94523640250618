import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import { values } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { AutocompleteItem } from "../../../components";
import useJourneyItemDialogForm from "./useJourneyGroupItemDialogForm";

interface JourneyGroupItemDialogFormProps {
  value?: ItemProps;
  open: boolean;
  onSave?: Function;
  onClose?: Function;
  onCancel?: Function;
}

const JourneyGroupItemDialogForm = (props: JourneyGroupItemDialogFormProps) => {
  const { value, open, onSave, onClose, onCancel } = props;

  const { formValue, updateFormValue, validFields, isValid } =
    useJourneyItemDialogForm(value);

  // const [formValue, setFormValue] = useState(() => {
  //   return value || "";
  // });

  const handleLookupChange = (
    val: Partial<ItemCompleteFormProps> | null | undefined
  ) => {
    //console.log("handleLookupChange", val);
    updateFormValue(val);
  };

  const handleOnSave = () => {
    onSave?.(formValue);
  };

  useEffect(() => {
    if (value) updateFormValue(value);
  }, [value]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose?.();
      }}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>Add an Item to the Journey Group</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            paddingTop: "1rem"
          }}
        >
          <Box>
            <Typography variant="overline">Item *</Typography>
            <AutocompleteItem
              value={formValue}
              onChangeItem={handleLookupChange}
              options={{ item_types: ["INFORMATION", "VIDEO", "MEETING"] }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ marginRight: "auto" }}
          onClick={() => {
            onCancel?.();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" disabled={!isValid} onClick={handleOnSave}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JourneyGroupItemDialogForm;
