import { OPERATIONTYPES } from "../../../types/enums";

const rulePrepareForSubmit = (formRule: RuleFormItem) => {
  /**
   * If Rule is has no operation then return undefined.
   */
  let preparedRule: RuleSubmittable | undefined = undefined;
  //console.log(OPERATIONTYPES.CREATE.KEY, formRule.operation);
  if (formRule.operation === OPERATIONTYPES.CREATE.KEY) {
    preparedRule = { ...formRule };
    delete preparedRule["isNew"];
    delete preparedRule["id"];
    delete preparedRule["item_id"];
  }

  if (formRule.operation === OPERATIONTYPES.UPDATE.KEY) {
    preparedRule = { ...formRule };
    delete preparedRule["isNew"];
    delete preparedRule["rule_type"];
    delete preparedRule["item_id"];
  }

  if (formRule.operation === OPERATIONTYPES.DELETE.KEY) {
    preparedRule = { id: formRule.id, operation: formRule.operation };
  }

  return preparedRule;
};

export default rulePrepareForSubmit;
