import { Box } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { ErrorBoundary, ErrorDisplay } from "../../../../components";
import { journeyAtom } from "../../../../globalstates";

import { useItems } from "../../../../hooks";
import { ItemJourney } from "../../../items/journey";
import JourneySelection from "../../../items/journey/components/ui/JourneySelection";
import { setJourneyStateData } from "../../../items/journey/utils";

const JourneyPage = () => {
  const nav = useNavigate();
  const path = useLocation();
  const [journeyState, setJourneyState] = useAtom(journeyAtom);
  const journeyParams = useParams();

  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [item, setItem] = useState<ItemUserJourney | undefined>();
  const { items, refetch } = useItems({
    types: ["JOURNEY"],
    status: ["VIEW", "DISPLAY"],
    name: "journeypage",
    fullFetch: true,
    options: {
      onSuccess: (res: any) => {
        //console.log("JourneyPage fetched", res);

        const journeyStateData = setJourneyStateData(res.items);
        setJourneyState((prev) => ({
          ...prev,
          ...journeyStateData
        }));

        if (journeyStateData.count === 0) {
          nav("/");
        }
      }
    }
  });

  useEffect(() => {
    if (items && items.length === 1) {
      setItem(items[0]);
      //nav(items[0].item.id.toString());
    }

    if (items && selectedItemId) {
      const itemId = items.find(
        (item) => (item.id as number) === selectedItemId
      );
      //console.info("Selected Item", itemId);
      setItem(itemId);
      //if (selectedItemId) nav(selectedItemId.toString());
    }
  }, [items, selectedItemId]);

  const handleOnChange = () => {
    refetch();
  };

  const handleOnComplete = () => {
    //setItem(undefined);
    //setSelectedItemId(undefined);

    nav("/journey");
    refetch();
  };

  const handleOnJourneySelect = (id: number) => {
    //console.log(id, item);
    nav("/journey/" + id.toString());
    setSelectedItemId(id);
  };

  // useEffect(() => {
  //   if (item) {
  //     //console.info(selectedItemId);
  //     nav("/journey/" + item.id.toString());
  //     //nav(("/journey/" + item.id) as string);
  //   }
  // }, [item]);

  useEffect(() => {
    if (path.pathname === "/journey") {
      setItem(undefined);
      setSelectedItemId(undefined);
    }
  }, [path]);

  /* FIX THIS */
  useEffect(() => {
    //console.log(journeyParams["*"]);
    if (journeyParams["*"]) setSelectedItemId(Number(journeyParams["*"]));
  }, [journeyParams]);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <JourneySelection
              journeys={items}
              onSelect={handleOnJourneySelect}
            />
          }
        />
        <Route
          path=":journeyId"
          element={
            <ErrorBoundary errorCode="Error Displaying Journey Page">
              {item && (
                <ItemJourney
                  item={item}
                  onChange={handleOnChange}
                  onComplete={handleOnComplete}
                  // onBack={() => {
                  //   setSelectedItemId(undefined);
                  //   setItem(undefined);
                  // }}
                />
              )}
              {selectedItemId && !item && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "2rem"
                  }}
                >
                  <ErrorDisplay
                    error={`No Item matching id ${selectedItemId}`}
                  />
                </Box>
              )}
              {/* {!item && <Navigate replace to="/" />} */}
            </ErrorBoundary>
          }
        />
      </Routes>
      {/* {items && items.length > 1 && !item && (
        <ErrorBoundary>
          <JourneySelection journeys={items} onSelect={handleOnJourneySelect} />
        </ErrorBoundary>
      )} */}
      {/* {item && (
        <ErrorBoundary errorCode="Error Displaying Journey Page">
          <ItemJourney
            item={item}
            onChange={handleOnChange}
            onBack={() => {
              setSelectedItemId(undefined);
              setItem(undefined);
            }}
          />
        </ErrorBoundary>
      )} */}
    </>
  );
};

export default JourneyPage;
