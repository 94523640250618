import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import usePreferences from "../../../../hooks/dataHooks/usePreferences";

const Preferences = () => {
  const {
    prefs: userPrefs,
    prefsObject: userPrefsObject,
    setPreference,
    getPreference
  } = usePreferences();

  //console.log("Preferences Values", userPrefs, userPrefsObject);

  // const [adminTestMode, setAdminTestMode] = useState<any>(
  //   userPrefsObject?.admin_test_mode || false
  // );

  // useEffect(() => {
  //   if (userPrefsObject && userPrefsObject.admin_test_mode)
  //     setAdminTestMode(userPrefsObject.admin_test_mode);
  // }, [userPrefsObject]);

  return (
    <>
      Test Mode?{" "}
      <Switch
        checked={(userPrefsObject && userPrefsObject?.admin_test_mode) || false}
        onChange={(val) => {
          //console.log(val.target.checked);
          setPreference("admin_test_mode", val.target.checked);
        }}
      ></Switch>
    </>
  );
};

export default Preferences;
