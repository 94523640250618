export interface LookupEnum {
  [key: string]: { KEY: string; DISPLAY: string };
}

/**
 * Global Enums
 */

/**
 * Item
 */

export const enum ITEM {
  INFORMATION = "INFORMATION",
  MEETING = "MEETING",
  VIDEO = "VIDEO"
}

export const ITEMTYPES: LookupEnum = {
  INFORMATION: { KEY: "INFORMATION", DISPLAY: "Information" },
  MEETING: { KEY: "MEETING", DISPLAY: "Meeting" },
  VIDEO: { KEY: "VIDEO", DISPLAY: "Video" },
  ANNOUNCEMENT: { KEY: "ANNOUNCEMENT", DISPLAY: "Announcement" },
  ECENTRE: { KEY: "ECENTRE", DISPLAY: "eCentre" },
  JOURNEY: { KEY: "JOURNEY", DISPLAY: "Journey" }
};

/**
 * Audience
 */
export enum AUDIENCE {
  HIERARCHY = "HIERARCHY",
  POSITION = "POSITION",
  PERSON = "PERSON",
  ROLE = "ROLE"
}

export const AUDIENCETYPES: LookupEnum = {
  HIERARCHY: { KEY: "HIERARCHY", DISPLAY: "Hierarchy" },
  PERSON: { KEY: "PERSON", DISPLAY: "Person" },
  ROLE: { KEY: "ROLE", DISPLAY: "Role" },
  POSITION: { KEY: "POSITION", DISPLAY: "Position" }
};

/**
 * Operation
 */
export enum OPERATION {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  NONE = "NONE"
}

export const OPERATIONTYPES: LookupEnum = {
  CREATE: { KEY: "CREATE", DISPLAY: "Create" },
  UPDATE: { KEY: "UPDATE", DISPLAY: "Update" },
  DELETE: { KEY: "DELETE", DISPLAY: "Delete" },
  NONE: { KEY: "NONE", DISPLAY: "None" }
};

/**
 * Item Status
 */

export const ITEMSTATUSTYPES: LookupEnum = {
  NEW: { KEY: "NEW", DISPLAY: "New" },
  OVERDUE: { KEY: "OVERDUE", DISPLAY: "Overdue" },
  COMPLETED: { KEY: "COMPLETED", DISPLAY: "Completed" },
  NONE: { KEY: "NONE", DISPLAY: "None" }
};

export const ITEMUSERSTATUSTYPES: LookupEnum = {
  DISPLAY: { KEY: "DISPLAY", DISPLAY: "Display" },
  VIEW: { KEY: "VIEW", DISPLAY: "View" },
  COMPLETE: { KEY: "COMPLETE", DISPLAY: "Complete" }
};

/**
 * Rules
 */
export enum RULES {
  STATUS = "STATUS"
}

export const RULETYPES: LookupEnum = {
  STATUS: { KEY: "STATUS", DISPLAY: "Status" },
  DEPEND: { KEY: "DEPEND", DISPLAY: "Dependent" }
};

/**
 * User Status
 */

export enum ITEMUSERSTATUS {
  COMPLETE = "COMPLETE",
  NEW = "NEW",
  INPROGRESS = "IN-PROGRESS"
}

export enum ITEMUSERSTATUSOPERATOR {
  EQ = "EQ",
  NE = "NE"
}

export const USERSTATUS: LookupEnum = {
  COMPLETE: { KEY: "COMPLETE", DISPLAY: "Complete" },
  INCOMPLETE: { KEY: "INCOMPLETE", DISPLAY: "Incomplete" },
  INPROGRESS: { KEY: "INPROGRESS", DISPLAY: "In Progress" }
};

export const USERSTATUSOPERATOR: LookupEnum = {
  EQ: { KEY: "EQ", DISPLAY: "Equals" },
  NE: { KEY: "NE", DISPLAY: "Not Equals" }
};
