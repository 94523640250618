import {
  Backdrop,
  Button,
  Box,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  SxProps
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

//import ItemIcon from "../itemicon";
import { format } from "date-fns";
import { useState, PropsWithChildren, useMemo, ReactNode } from "react";
import ItemIcon from "../../../components/ui/ItemIcons";
import getOverdueStatus from "../../../../../utils/getOverdueStatus";

// import { getOverdueStatus } from "../../../utils";

interface ItemCardProps {
  userItem: ItemUserProps;
  title?: ReactNode;
  onClick?: Function;
  actions?: ReactNode;
  hideHeader?: boolean;
  cardSx?: SxProps;
  contentSx?: SxProps;
  disabled?: boolean;
  disabledOnComplete?: boolean;
  showTypeIcon?: boolean;
}

interface ItemCardInteractionProps {
  userItem: ItemUserProps;
  onItemActionPerformed?: (
    success: boolean,
    message?: string,
    value?: any
  ) => void;
}
export type { ItemCardInteractionProps };

const ItemCard = (props: PropsWithChildren<ItemCardProps>) => {
  const {
    userItem,
    children,
    title = "",
    onClick,
    actions,
    hideHeader = false,
    cardSx,
    contentSx,
    disabled = false,
    disabledOnComplete = true,
    showTypeIcon = false
  } = props;
  const item = userItem.item;
  const dueDate = new Date(userItem.due_date);

  const due_status =
    userItem.status === "COMPLETE"
      ? "COMPLETE"
      : getOverdueStatus(userItem.due_date);

  const readableDate = format(dueDate, "dd/MM/yyyy");

  const handleStartClose = () => {};

  const isDisabled =
    disabled || (disabledOnComplete && userItem.status === "COMPLETE");

  const disabledStyle = isDisabled
    ? {
        opacity: "0.8",
        filter: "grayscale(1)"
        //pointerEvents:"none",
        // "&:before": {
        //   content: '""',
        //   height: "100%",
        //   width: "100%",
        //   backgroundColor: "rgba(255,255,255,0.7)",
        //   position: "absolute",

        //   zIndex: 2
        // },
        // "&:after": {
        //   width: "120px",
        //   position: "absolute",
        //   content: '"COMPLETE ✔"',
        //   border: "solid 3px green",
        //   padding: "1rem",
        //   rotate: "-45deg",
        //   textAlign: "center",
        //   color: "green",
        //   fontWeight: "bolder",
        //   borderRadius: "1rem",
        //   display: "inline-block",
        //   backgroundColor: "rgba(255,255,255,.5)",
        //   top: "40%",
        //   left: "50%",
        //   transform: "translate(-50%, -60px)",
        //   zIndex: 3
        // }
      }
    : {};

  //console.log(item);
  const card = (
    <Card
      elevation={2}
      sx={{
        ...{
          //cursor: "pointer",
          position: "relative",
          //flexBasis: "30%",
          // minWidth: "300px",
          minHeight: "200px",
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
          overflow: "visible",
          //boxShadow:"0px 5px 5px -3px rgb(2 183 255 / 20%), 0px 8px 10px 1px rgb(191 215 247 / 14%), 0px 3px 14px 2px rgb(44 114 201 / 12%)",
          "&:hover": {
            boxShadow:
              "0px 5px 5px -3px rgba(0, 145, 125, 0.6),0px 8px 10px 1px rgba(191, 215, 247, 0.14),0px 3px 14px 2px rgba(44, 114, 201, 0.12)"
            //            "0px 5px 5px -3px rgb(2 183 255 / 20%), 0px 8px 10px 1px rgb(191 215 247 / 14%), 0px 3px 14px 2px rgb(44 114 201 / 12%)"
          }
        },
        ...disabledStyle,
        ...cardSx
      }}
    >
      {!hideHeader && (
        <CardHeader
          avatar={<ItemIcon name={item.item_type} size="small" />}
          title={title}
          //subheader={`${readableDate}`}
          // action={
          //   <IconButton aria-label="settings">
          //     <MoreVertIcon />
          //   </IconButton>
          // }
          // action={
          //   <Box>
          //     <Box sx={{ fontSize: "0.5rem" }}>{due_status}</Box>
          //     {readableDate !== "31/12/9999" && (
          //       <Box sx={{ fontSize: "0.6rem" }}>
          //         Due: {readableDate === "31/12/9999" ? "Never" : readableDate}
          //       </Box>
          //     )}
          //   </Box>
          // }
        ></CardHeader>
      )}
      <CardContent
        sx={{
          ...{
            flex: "1 1 auto",
            display: "flex",
            borderRadius: "inherit",
            overflow: "hidden"
          },
          ...contentSx
        }}
      >
        {showTypeIcon && (
          <Box
            sx={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
              background: "white",
              borderRadius: "50%",
              // padding: "0.5rem",
              boxShadow: "0px 0px 3px -10px #333",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ItemIcon
              name={item.item_type}
              iconSx={{ height: "20px", width: "20px" }}
            />
          </Box>
        )}
        {/* //   <Box
      //     sx={{
      //       display: "flex",
      //       flexDirection: "column",
      //       justifyContent: "space-between",
      //       flexBasis: "100%",
      //       alignItems: "stretch"
      //       //height: "100%"
      //     }}
      //   >
      //     <Box sx={{ textAlign: "left", height: "100%" }}>{children}</Box>
      //   </Box> */}
        {children}
      </CardContent>

      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  );

  return <>{card}</>;
};

export default ItemCard;
