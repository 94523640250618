import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { mainPageAtomDefault } from "../../../../client/pages/main/mainPageAtom";
import ItemFilterForm, { ItemFilterFormValueProps } from "./ItemFilterForm";
import ItemSortForm, { ItemSortFormValueProps } from "./ItemSortForm";

interface ItemFilterDialogFormValueProps {
  sort?: ItemSortFormValueProps[];
  filter?: ItemFilterFormValueProps;
}

interface ItemFilterDialogFormProps {
  value?: ItemFilterDialogFormValueProps;
  open?: boolean;
  onClose?: () => void;
  onSubmit?: (value: ItemFilterDialogFormValueProps) => void;
  onReset?: () => void;
}

const ItemFilterDialogForm = (props: ItemFilterDialogFormProps) => {
  const { open = false, onClose, onSubmit, value, onReset } = props;
  const [filterResults, setFilterResults] = useState<
    ItemFilterDialogFormValueProps | undefined
  >(value);

  const handleFilterChange = useCallback(
    (val: ItemFilterFormValueProps | undefined) => {
      setFilterResults((prevState) => ({
        ...prevState,
        filter: val
      }));
    },
    []
  );

  const handleSortChange = useCallback((val: ItemSortFormValueProps[]) => {
    setFilterResults((prevState) => ({
      ...prevState,
      sort: val
    }));
  }, []);

  useEffect(() => {
    if (!isEqual(value, filterResults)) setFilterResults(value);
  }, [value]);

  const reset = () => {
    setFilterResults(mainPageAtomDefault);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      //hideBackdrop
      sx={{ alignItems: "start" }}
      PaperProps={{ sx: { top: "0px", position: "absolute" } }}
    >
      <DialogTitle>
        Filter and Sort
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box>
            <Typography variant="subtitle2">Filter</Typography>
            <Divider />
            <ItemFilterForm
              onChange={handleFilterChange}
              value={filterResults?.filter}
            />
          </Box>
          <Box>
            <Typography variant="subtitle2">Sort</Typography>
            <Divider />
            <ItemSortForm
              value={filterResults?.sort}
              onChange={handleSortChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            reset();
          }}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (filterResults) onSubmit?.(filterResults);
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemFilterDialogForm;
