import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle } from "@mui/icons-material";

import JourneyGroupItem from "./JourneyGroupItem";
//import { SortableItemProps } from "./JourneyGroupItemsFormFields";

interface JourneyGroupSortableItemProps {
  item?: ItemJourneyGroupItemProps;
  selected?: boolean;
  onDelete?: () => void;
  onRestore?: () => void;
}

const JourneyGroupSortableItem = (props: JourneyGroupSortableItemProps) => {
  const { item, selected, onDelete, onRestore } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item?.id || -1 });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: selected ? "0.2" : "1"
  };

  return (
    <div ref={setNodeRef} style={style}>
      <JourneyGroupItem
        item={item}
        onDelete={() => {
          onDelete?.();
        }}
        onRestore={() => {
          onRestore?.();
        }}
        dragHandle={<DragHandle {...attributes} {...listeners} />}
      />
    </div>
  );
};
export default JourneyGroupSortableItem;
