import { atom } from "jotai";

interface NotificationsAtomProps {
  announcementsCount: number;
  notificationsCount: number;
  announcementsDismissed: boolean;
}

const notificationsAtom = atom<NotificationsAtomProps>({
  announcementsCount: 0,
  notificationsCount: 0,
  announcementsDismissed: false
});

export default notificationsAtom;
