import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { userAtom } from "../../../globalstates";

export function ClientAvatar() {
  const user = useAtomValue(userAtom);
  //console.log(user);
  return (
    <Avatar
      src={user?.photoUrl || ""}
      sx={{
        border: "solid 0.2rem #FFF",
        borderColor: "primary.main",
        height: "2rem",
        width: "2rem",
        fontSize: ".8rem",
        margin: "auto",
        backgroundColor: user?.photoUrl ? "white" : "primary.main",
        color: "#FFF"
      }}
    >
      {user &&
        user?.givenName?.substring(0, 1) + user?.surname?.substring(0, 1)}
    </Avatar>
  );
}
