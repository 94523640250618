import { useDeferredValue, useEffect, useState } from "react";
import { useDebounce, useGraph } from "../../../../hooks";

interface UseAutocompletePersonProps {
  initialValue?: string;
  format?: (person: User) => {};
  returnValueFormat?: (person: User) => {};
  onSuccess?: (resuls: any) => void;
}

const useAutocompletePerson = (props: UseAutocompletePersonProps) => {
  const {
    initialValue,
    format = (user: User) => {
      return {
        label: `${user.displayName} (${user.userPrincipalName})`,
        value: user.userPrincipalName
      };
    },
    returnValueFormat = (user: User) => {
      return user;
    },
    onSuccess
  } = props;

  const [searchValue, setSearchValue] = useState<string>(initialValue || "");

  const [searchString, setSearchString] = useState<string>(searchValue);

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchResultsRaw, setSearchResultsRaw] = useState<any[]>([]);
  const [returnValue, setReturnValue] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [people, fetching, searchPeople, error] = useGraph(
    `https://graph.microsoft.com/v1.0/users?$filter=(startswith(displayName,'${searchString}') or startsWith(userPrincipalName,'${searchString}')) and endsWith(userPrincipalName,'@cdu.edu.au')&$count=true&$top=10&$select=displayName,userPrincipalName,givenName,surname`,
    { eventual: true },
    false,
    (res: any) => {
      setSearchResults(getValuesFromResults(res));
      formatResults(res.value);
      if (searchString) {
        getValueFromInitial(getValuesFromResults(res));
      }
      onSuccess?.(res);
    }
  );

  const formatResults = (results: any[]) => {
    setSearchResultsRaw(results);
    setSearchResults(results.map(format));
  };

  const getValueFromInitial = (values: []) => {
    if (values && searchString && !selectedValue) {
      const matchedVal = values.filter(
        (person: { label: string; value: string }) => {
          return person.value === searchString;
        }
      );
      if (matchedVal.length === 1) {
        setSelectedValue(matchedVal[0]);
      }
    }
  };

  const getValuesFromResults = (results: any) => {
    if (results) {
      if (format) {
        const formatted = results.value.map(format);
        return formatted;
      }
      if (results && results.value && results.value.length > 0) {
        return results.value;
      } else {
        return [];
      }
    }
    return [];
  };

  const getReturnValue = (person: User) => {
    if (person) {
      if (returnValueFormat) {
        const formattedValue = [person].map(returnValueFormat);
        console.log(formattedValue[0]);
        setReturnValue(formattedValue[0]);
      } else {
        setReturnValue(person);
      }
    } else {
      setReturnValue(undefined);
    }
  };

  const search = (val: string) => {
    setSearchValue(val);
  };

  const setSelected = (val: any) => {
    getReturnValue(
      searchResultsRaw.find(
        (person: any) => person.userPrincipalName == val?.value
      )
    );
    setSelectedValue(val);
  };

  useDebounce(
    () => {
      //console.log(searchValue);
      //console.log("Hook searchValue", searchValue);
      setSearchString(searchValue);
    },
    500,
    [searchValue]
  );

  useEffect(() => {
    if (searchString && searchString.length > 0) {
      searchPeople();
    }
  }, [searchString]);

  // useEffect(() => {
  //   if (searchResults && searchResults.length > 0) {
  //     console.log("searchResults", searchResults);
  //     //getValueFromInitial();
  //   }
  // }, [searchResults]);

  useEffect(() => {
    if (initialValue) {
      setSearchString(initialValue);
    }
  }, [initialValue]);

  // useEffect(() => {
  //   console.log("Selected Value", selectedValue);
  // }, [selectedValue]);

  return {
    people,
    fetching,
    search,
    searchResults,
    selectedValue,
    returnValue,
    setSelected
  };
};

export default useAutocompletePerson;
