import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useEffect, useState } from "react";
import { DisplayHtml, SubPage } from "../../../../../components";
import { ItemIcon } from "../../../components/ui";
import journeyGetGroupStats from "../../utils/journeyGetGroupStats";
import journeyGetStats from "../../utils/journeyGetStats";

interface JourneySelectionProps {
  journeys: ItemUserJourney[]; //ItemUserProps[];
  onSelect?: (id: number) => void;
}

interface JourneyStats {
  title: string;
  description: string;
  id: number;
  stats: {
    count: number;
    completed_count: number;
    completed: boolean;
    completed_items: number;
    total_items: number;
    group_percent: number;
    item_percent: number;
  };
}

const JourneySelection = (props: JourneySelectionProps) => {
  const { journeys, onSelect } = props;
  const [journeyStats, setJourneyStats] = useState<
    JourneyStats[] | undefined
  >();
  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  //   const journeyStats = journeys.map((journey: any) => ({
  //     title: journey.item.item_data.title,
  //     id: journey.id,
  //     stats: journeyGetStats(journey)
  //   }));

  const handleJourneySelect = (id: number) => {
    onSelect?.(id);
  };

  useEffect(() => {
    if (journeys && journeys?.length > 0) {
      //console.log(journeys);
      journeys?.map((journey: ItemUserJourney) => {
        console.log(journey, journeyGetStats(journey.item));
      });
      setJourneyStats(
        journeys?.map((journey: ItemUserJourney) => ({
          title: journey.item.item_data.title,
          description: journey.item.item_data.description,
          id: journey.id as number,
          stats: journeyGetStats(journey.item)
        }))
      );
    }
  }, [journeys]);

  //   useEffect(() => {
  //     console.log("journeyStats", journeyStats);
  //   }, [journeyStats]);

  return (
    <SubPage
      scrollInnerStyle={{
        height: "100%",
        display: "block"
      }}
      scrollStyle={{ display: "block" }}
      topOffset={isLarge ? 80 : 180}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "1rem",
          gap: "1rem",
          alignItems: "center"
        }}
      >
        {journeyStats?.map((journey) => (
          <JourneySelectionCard
            key={journey.id}
            journeyDetails={journey}
            onClick={() => handleJourneySelect(journey.id as number)}
          />
        ))}
      </Box>
    </SubPage>
  );
};

export default JourneySelection;

interface JourneySelectionCardProps {
  journeyDetails: JourneyStats;
  onClick?: () => void;
}

const JourneySelectionCard = (props: JourneySelectionCardProps) => {
  const { onClick, journeyDetails } = props;

  return (
    <Card key={journeyDetails.id} sx={{ minWidth: "600px", width: 1 }}>
      <CardActionArea onClick={() => onClick?.()}>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <ItemIcon name="JOURNEY" />
            <Typography>{journeyDetails.title}</Typography>
          </Box>
          <Divider></Divider>
          <Box>
            <DisplayHtml html={journeyDetails.description} />
          </Box>
          <CompletionBar
            label="Completed Steps"
            total={journeyDetails.stats.count}
            completedCount={journeyDetails.stats.completed_count}
            percent={journeyDetails.stats.group_percent}
          />
          <CompletionBar
            label="Completed Items"
            total={journeyDetails.stats.total_items}
            completedCount={journeyDetails.stats.completed_items}
            percent={journeyDetails.stats.item_percent}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

interface CompletionBarProps {
  label?: string;
  percent: number;
  total: number;
  completedCount: number;
}

const CompletionBar = (props: CompletionBarProps) => {
  const { label, percent, total, completedCount } = props;
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexBasis: "25%", alignItems: "center" }}>{label}</Box>
      <Box
        sx={{
          display: "flex",
          flexBasis: "75%",
          alignItems: "center",
          gap: "10px"
        }}
      >
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            color={percent === 0 ? "error" : percent < 100 ? "info" : "success"}
            value={percent}
            sx={{ height: "10px", borderRadius: "0.2rem" }}
          />
        </Box>
        <Box
          sx={{ fontWeight: "bold", minWidth: "60px" }}
        >{`${completedCount} of ${total}`}</Box>
      </Box>
    </Box>
  );
};
