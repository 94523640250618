import { createRoot } from "react-dom/client";
import React from "react";
import "./index.css";
import { App } from "./pages";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { AppAuthentication } from "./features";
import SsoAuth from "./features/authentication/components/SsoAuth";

// const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
// const queryClient: any = new QueryClient({
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//       refetchOnMount: true,
//       refetchOnReconnect: false,
//       retry: false,
//       staleTime: twentyFourHoursInMs
//     }
//   }
// });

const queryClient: any = new QueryClient();

const container: any = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <SsoAuth>
      {/* <AppAuthentication> */}
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
      {/* </AppAuthentication> */}
    </SsoAuth>
  </React.StrictMode>
);
