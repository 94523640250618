import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";

interface useApiDeleteProps {
  api: Function;
  options?: {};
}

const loginRequests = {
  scopes: [process.env.REACT_APP_API_SCOPE as string]
};

const API_PATH = process.env.REACT_APP_API_PATH;

const useApiDelete = (props: useApiDeleteProps) => {
  const { api, options } = props;

  const { instance, accounts } = useMsal();
  const mutation = useMutation((data: any) => {
    const pathString: string = api(data);

    const path =
      pathString.indexOf("http") > -1 ? pathString : API_PATH + pathString;

    return instance
      .acquireTokenSilent({ ...loginRequests, account: accounts[0] })
      .then((response) => {
        return axios
          .delete(path, {
            headers: {
              Authorization: "Bearer " + response.accessToken,
              Accept: "application/json"
            }
          })
          .then((res: any) => res)
          .catch((err) => {});
      });
  }, options);

  return mutation;
};

export default useApiDelete;
