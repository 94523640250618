import { PropsWithChildren, useState, useRef, useEffect } from "react";
import { Box, Fab, useScrollTrigger } from "@mui/material";
import useEventListener from "../hooks/useEventListener";

import UpIcon from "@mui/icons-material/KeyboardArrowUp";

interface IScrollableContainer {
  height?: string;
  sx?: any;
  sxinner?: any;
  direction?: "vertical" | "horizontal";
  showscrolltotop?: boolean;
}

const defaultStyle = {
  height: "100vh",
  overflowY: "hidden",
  display: "flex",
  flexDirection: "column",
};

const defaultInnerStyle = {
  display: "flex",
  justifyContent: "start",
  overflowY: "auto",
};

export default function ScrollableContainer({
  showscrolltotop = true,
  sx,
  height,
  direction,
  sxinner,
  children,
}: PropsWithChildren<IScrollableContainer>) {
  const [showScroll, setShowScroll] = useState(false);
  const scrollContainer = useRef<any>();

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    target: scrollContainer.current,
    threshold: 100,
  });

  function handleScrollToTop() {
    if (scrollContainer.current) {
      scrollContainer?.current?.scrollTo({ top: "0px", behavior: "smooth" });
    }
  }

  return (
    <Box sx={{ ...defaultStyle, ...sx }}>
      <Box
        className="hideScrollBar"
        ref={scrollContainer}
        sx={{
          ...defaultInnerStyle,
          ...{
            flexDirection: direction === "horizontal" ? "row" : "column",
          },
          ...sxinner,
        }}
      >
        {children}
      </Box>

      {showscrolltotop && scrollTrigger && (
        <Fab
          sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}
          color="primary"
          onClick={() => {
            handleScrollToTop();
          }}
        >
          <UpIcon />
        </Fab>
      )}
    </Box>
  );
}
