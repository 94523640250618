import { ItemCard } from "../../../item";

import EventIcon from "@mui/icons-material/Event";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { DisplayHtml, PersonAvatar } from "../../../../../components";
import CloseIcon from "@mui/icons-material/Close";
import { useUserItemSubmit } from "../../../../../hooks/dataHooks";
import MeetingItemCardForm from "./MeetingItemCardForm";
import { useGraphClient } from "../../../../../hooks";
import { ItemCardInteractionProps } from "../../../item/components/ui";
import useGraphUser from "../../../../../hooks/useGraphUser";
import { ItemIcon } from "../../../components/ui";
import OverdueIcon from "../../../../../components/icons/OverdueIcon";
import getOverdueStatus from "../../../../../utils/getOverdueStatus";

// interface MeetingItemCardProps {
//   userItem: ItemUserProps;
//   onItemActionPerformed?: (
//     success: boolean,
//     message?: string,
//     value?: any
//   ) => void;
// }

const MeetingItemCard = (props: ItemCardInteractionProps) => {
  const theme = useTheme();
  const { userItem, onItemActionPerformed } = props;
  const [open, setOpen] = useState(false);

  const [timeSelected, setTimeSelected] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [targetUserDetails, setTargetUserDetails] = useState<User | null>(null);

  const { isPosting, isPostingError, isPostingSuccess, postItem } =
    useUserItemSubmit({
      onSuccess: () => {
        setOpen(false);

        onItemActionPerformed?.(
          true,
          `Meeting with ${targetUserDetails?.displayName} has been booked.`
        );
      }
    });

  const [client, fetchingClient] = useGraphClient("/me/events");

  const item = userItem?.item;
  const item_data: ItemMeetingData = item?.item_data;

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = () => {
    postItem({ id: userItem.id as number, status: "COMPLETE" });
  };

  const handleOnTimeSelected = (
    selected: boolean,
    targetPersonDetails: any,
    selectedTime: any
  ) => {
    console.log("Time Selected ", selected, selectedTime, targetPersonDetails);
    setTimeSelected(selected);
    setSelectedTime(selectedTime);
    setTargetUserDetails(targetPersonDetails);
  };

  //console.log("BookMeeting Props", props);

  function bookMeeting(selectedTime: any) {
    if (client && item_data.target_value && targetUserDetails && selectedTime) {
      const event = {
        subject: `CDUSLC - ${item_data.title}`,
        body: {
          contentType: "HTML",
          content:
            "<p>I am new and would like to meet up with you to discuss the following.</p>" +
            item_data.content
        },
        start: selectedTime.meetingTimeSlot.start,
        end: selectedTime.meetingTimeSlot.end,
        attendees: [
          {
            emailAddress: {
              address: targetUserDetails?.mail,
              name: targetUserDetails?.displayName
            },
            type: "required"
          }
        ],
        isOnlineMeeting: true,
        onlineMeetingProvider: "teamsForBusiness"
      };

      //   const response = await client.post(event);
      //   console.log(response);
      //   console.log("Meeting Booked", response);
      client
        .post(event)
        .then((res: any) => {
          console.log("Meeting Booked", res);
          handleAction();
          const meetingDate = new Date(
            selectedTime.meetingTimeSlot.start.dateTime + "Z"
          );
          //   const formatedDate = dateFormat(meetingDate, "PPPP 'at' pp");
          //   setFeedbackSeverity("success");
          //   setFeedbackMessage(
          //     `Your meeting has been booked for ${formatedDate} with ${targetUser?.displayName}`
          //   );
          //   setLocalTaskStatus(true);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }

  const handleBookMeeting = () => {
    console.log("Book the Meeting");
    bookMeeting(selectedTime);
  };

  return (
    <ItemCard
      cardSx={{ padding: "0px" }}
      contentSx={{ padding: "0px", "&:last-child": { padding: "0px" } }}
      hideHeader
      title={item_data?.title}
      userItem={userItem}
      // actions={
      //   <Button
      //     sx={{ marginLeft: "auto" }}
      //     variant="text"
      //     //color="secondary"
      //     endIcon={<EventIcon />}
      //     onClick={() => {
      //       setOpen(true);
      //       setSelectedTime(null);
      //       setTimeSelected(false);
      //     }}
      //   >
      //     Book
      //   </Button>
      // }
    >
      <Box
        sx={{
          display: "flex",
          flex: "0 0 100%",
          [theme.breakpoints.down("md")]: { flexDirection: "column" }
        }}
      >
        <Box
          sx={{
            //minWidth: "30%",
            //backgroundColor: "primary.main",
            bgcolor: "#5c3f60",
            background: "radial-gradient(circle at 50% 40%,#5C1818,#5c3f60)",
            padding: "1rem",
            display: "flex",
            color: "white",
            [theme.breakpoints.down("md")]: { justifyContent: "center" }
          }}
        >
          <PersonAvatar
            upn={item_data?.target_value}
            includeImage
            direction="column"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            flex: "1",
            padding: "0.5rem"
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bolder" }}>
                {item_data.title}
              </Typography>
              {getOverdueStatus(userItem?.due_date) === "OVERDUE" && (
                <OverdueIcon dueDate={userItem?.due_date} />
              )}
            </Box>

            <ItemIcon name={item.item_type} />
          </Box>
          <Box sx={{ flexGrow: "1" }}>
            <DisplayHtml html={item_data?.description} />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{ marginLeft: "auto" }}
              variant="text"
              //color="secondary"
              endIcon={<EventIcon />}
              onClick={() => {
                setOpen(true);
                setSelectedTime(null);
                setTimeSelected(false);
              }}
            >
              Book
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{ sx: { minHeight: "50vh" } }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography>{item_data?.title}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            [theme.breakpoints.down("md")]: { flexDirection: "column" }
          }}
        >
          <Box sx={{ flexBasis: "50%" }}>
            <DisplayHtml html={item_data?.content} />
          </Box>

          <Divider
            sx={{ marginBottom: "1rem" }}
            orientation={
              theme.breakpoints.down("md") ? "horizontal" : "vertical"
            }
          />

          <Box sx={{ flexBasis: "50%" }}>
            <Typography>
              Select from an available time below. This can be changed later in
              Outlook.
            </Typography>
            <MeetingItemCardForm
              data={item_data}
              onSelectedTime={handleOnTimeSelected}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {userItem?.status !== "COMPLETE" && (
            <Button
              sx={{ marginLeft: "auto" }}
              variant="outlined"
              //color="secondary"
              onClick={handleBookMeeting}
              disabled={!timeSelected}
            >
              Book the Selected Meeting
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ItemCard>
  );
};

export default MeetingItemCard;

const MeetingCard = () => {
  return <></>;
};
