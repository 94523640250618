import { Box, useTheme } from "@mui/material";
import JoditEditor, { JoditProps } from "jodit-react";
import { isEqual } from "lodash";

import { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

interface JoditRTEProps extends JoditProps {
  error?: boolean;
  debounceTime?: number;
}

const JoditRTE = (props: JoditRTEProps) => {
  const { config, error, debounceTime = 500, onChange, value, ...rest } = props;

  const theme = useTheme();
  const editor: any = useRef(null);
  const [focused, setFocused] = useState(false);
  const [content, setContent] = useState(value);

  const [debouncedValue] = useDebounce(content, debounceTime);

  useEffect(() => {
    onChange?.(debouncedValue);
  }, [debouncedValue]);

  // useEffect(() => {
  //   //props?.onChange?.(debouncedValue);
  //   onChange?.(content);
  // }, [content]);

  const handleOnChange = (value: string) => {
    setContent(value);
  };

  // useEffect(() => {
  //   if (value)
  //     setContent((prev) => {
  //       if (prev && !isEqual(value, prev)) {
  //         return value;
  //       } else {
  //         return prev;
  //       }
  //     });
  // }, [value]);

  const localConfig: any = useMemo(
    () => ({
      //buttons: "bold | italic",
      readonly: config?.readonly, // all options from https://xdsoft.net/jodit/doc/,
      //placeholder: config?.placeholder || "Start typing...",
      uploader: {
        insertImageAsBase64URI: true
      },
      hidePoweredByJodit: true,
      tabIndex: config?.tabIndex || 0,
      removeButtons: [
        "video",
        "file",
        "fullsize",
        "print",
        "about",
        "classSpan"
      ],
      disablePlugins: ["add-new-line"]
    }),
    [config]
  );

  const handleOnBlur = (value: string) => {
    //console.log("blur value", value);
    if (props.onBlur) props.onBlur?.(value);
    //setContent(value);
  };

  /* wrap in memo to fix bug where cursor would go to start of the editor */
  const jeditor = useMemo(
    () => (
      <JoditEditor
        ref={editor}
        //value={props?.value}
        value={debouncedValue}
        //value={content}
        config={localConfig}
        //tabIndex="1" // tabIndex of textarea
        /* FIX THIS */
        //onBlur={handleOnChange} // preferred to use only this option to update the content for performance reasons
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        //onChange={props?.onChange}

        // onFocus={(e)=>{

        // }}
      />
    ),
    []
  );

  return (
    <>
      <Box
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        sx={{
          border: error
            ? focused
              ? `solid 2px ${theme.palette.error.main}`
              : `solid 1px ${theme.palette.error.main}`
            : focused
            ? "solid 2px " + theme.palette.primary.main
            : `solid 1px rgba(0, 0, 0, 0.23)`,
          borderRadius: "4px",
          padding: "4px",
          boxSizing: "border-box"
        }}
      >
        {jeditor}
      </Box>
    </>
  );
};

export default JoditRTE;
