import * as React from "react";

import MuiIcon from "./MuiIcon";
import * as MuiIcons from "@mui/icons-material";
import { Box, Divider, InputAdornment, TextField } from "@mui/material";

export interface MuiIconSearchProps {
  onSelect?: (iconName: string) => void;
  iconLimit?: number;
}

const MuiIconSearch: React.FC<MuiIconSearchProps> = ({
  onSelect,
  iconLimit = 20
}) => {
  const [search, setSearch] = React.useState("");
  const iconKeys = Object.keys(MuiIcons);

  const filteredKeys = iconKeys.filter(
    (key) =>
      !["Outlined", "Rounded", "Sharp", "TwoTone"].some((check) =>
        key.includes(check)
      )
  );

  const handleSelect = (value: string) => {
    console.log("selected ", value);
    onSelect?.(value);
  };

  const filteredIcons = React.useMemo(() => {
    const filtered = filteredKeys.filter((key) =>
      key.toLowerCase().includes(search.toLowerCase())
    );
    return filtered.slice(0, iconLimit);
  }, [iconKeys, search]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MuiIcon iconName="Search" />
            </InputAdornment>
          )
        }}
        fullWidth
        placeholder="Search icons..."
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        sx={{ position: "sticky", top: "1rem", backgroundColor: "white" }}
      />
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.5rem",
          justifyContent: "center"
        }}
      >
        {filteredIcons.map((label) => {
          return (
            <button
              key={label}
              onClick={() => handleSelect(label)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
                padding: "0.5rem",

                width: "300px",
                cursor: "pointer"
              }}
            >
              <MuiIcon iconName={label} /> {label}
            </button>
          );
        })}
      </Box>
    </Box>
  );
};

export default MuiIconSearch;
