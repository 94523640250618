import { Box } from "@mui/material";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges
} from "@dnd-kit/modifiers";

import JourneyGroupSortableItem from "./JourneyGroupSortableItem";
import { useEffect, useState } from "react";
import JourneyGroupItem from "./JourneyGroupItem";
import { isEqual } from "lodash";
import { OPERATIONTYPES } from "../../../../../types/enums";
import { DragHandle } from "@mui/icons-material";

interface JourneyGroupItemsFormFieldsProps {
  items?: ItemJourneyGroupItemProps[];
  onChange?: Function;
  originalValue?: ItemJourneyGroupItemProps[];
}

const JourneyGroupItemsFormFields = (
  props: JourneyGroupItemsFormFieldsProps
) => {
  const { items, onChange, originalValue } = props;

  const [activeId, setActiveId] = useState();

  const [formItems, setFormItems] = useState<ItemJourneyGroupItemProps[]>(
    items || []
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragStart(event: any) {
    //console.log(event.active.id);
    setActiveId(event.active.id);
  }

  const reSequence = (items: ItemJourneyGroupItemProps[]) => {
    console.log("reSequence Items", items, originalValue);
    const resequencedItem = items.map((item, index) => {
      const originalSeq = originalValue?.find(
        (fItem) => item.id.toString() === fItem.id.toString()
      )?.sequence_number;

      const newSequence = index + 1;
      const changedPosition =
        (!item.operation || item.operation === OPERATIONTYPES.UPDATE.KEY) &&
        originalSeq !== newSequence;

      let newItem = { ...item, sequence_number: newSequence };
      if (changedPosition) {
        newItem.operation = OPERATIONTYPES.UPDATE.KEY;
      }
      if (!changedPosition && newItem.operation === OPERATIONTYPES.UPDATE.KEY) {
        delete newItem["operation"];
      }
      return newItem;
    });
    return resequencedItem;
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const itemsCopy = [...formItems];
      const oldIndex = itemsCopy.findIndex((findItem) => {
        return findItem.id === active.id;
      });
      const newIndex = itemsCopy.findIndex((findItem) => {
        return findItem.id === over.id;
      });
      const updatedItems = arrayMove(itemsCopy, oldIndex, newIndex);
      const reSequencedItems = reSequence(updatedItems);
      setFormItems(reSequencedItems);
    }
    setActiveId(undefined);
  }

  const handleOnDelete = (id: string | number) => {
    if (id) {
      let item = formItems.find((item) => {
        return item.id === id;
      });
      if (item?.operation && item.operation === OPERATIONTYPES.CREATE.KEY) {
        let itemsCopy = formItems.filter((item) => item.id !== id);

        const reSequencedItems = reSequence(itemsCopy);
        setFormItems(reSequencedItems);
      }

      if (
        !item?.operation ||
        (item?.operation && item.operation !== OPERATIONTYPES.CREATE.KEY)
      ) {
        let itemsCopy = [...formItems];
        let itemIndex = itemsCopy.findIndex((item) => item.id === id);
        itemsCopy[itemIndex].operation = OPERATIONTYPES.DELETE.KEY;
        const reSequencedItems = reSequence(itemsCopy);
        setFormItems(reSequencedItems);
      }
    }
  };

  const handleOnRestore = (id: string | number) => {
    if (id) {
      let item = formItems.find((item) => {
        return item.id === id;
      });
      if (item?.operation && item.operation === OPERATIONTYPES.DELETE.KEY) {
        // console.log("Set Restore operation");
        let itemsCopy = [...formItems];
        // console.log("itemsCopy", itemsCopy);
        let itemIndex = itemsCopy.findIndex((item) => item.id === id);
        delete itemsCopy[itemIndex]["operation"];
        const reSequencedItems = reSequence(itemsCopy);
        setFormItems(reSequencedItems);
        //setFormItems(itemsCopy);
      }
    }
  };

  useEffect(() => {
    //console.log("items", items);
    if (items)
      setFormItems(
        items
          .map((item) => {
            // If id === 0 then sorting sets it to -1 (falsey 0)
            return {
              ...item,
              id: item.id === 0 ? item.id.toString() : item.id
            };
          })
          .sort((a, b) => a.sequence_number - b.sequence_number)
      );
  }, [items]);

  useEffect(() => {
    //console.log("Form Items", formItems, originalValue);

    if (!isEqual(formItems, items)) onChange?.(formItems);
  }, [formItems]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext
          items={formItems}
          strategy={verticalListSortingStrategy}
        >
          {formItems.map((item) => {
            return (
              <JourneyGroupSortableItem
                key={item.id}
                item={item}
                selected={activeId === item.id}
                onDelete={() => {
                  handleOnDelete(item.id);
                }}
                onRestore={() => {
                  handleOnRestore(item.id);
                }}
              />
            );
          })}
        </SortableContext>
        <DragOverlay style={{ scale: "0.98" }}>
          {activeId ? (
            <JourneyGroupItem
              item={formItems.find((item) => {
                return item.id === activeId;
              })}
              dragHandle={<DragHandle />}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};
export default JourneyGroupItemsFormFields;
