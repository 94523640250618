import { Box } from "@mui/material";
import { isEmpty, isEqual, isNil } from "lodash";

import { useEffect, useState } from "react";
import {
  OperatorSelector,
  UserStatusSelector
} from "../../../../../components";
import { USERSTATUS, USERSTATUSOPERATOR } from "../../../../../types/enums";

const DEFAULT_VALUE = {
  operator_type: USERSTATUSOPERATOR.NE.KEY,
  status: USERSTATUS.COMPLETE.KEY
};

const RuleStatusForm = (props: RuleTypeFormProps) => {
  const { value, onChange, onValid, itemId } = props;

  const [formValue, setValue] = useState<RuleStatus>(
    !isEmpty(value) ? value : DEFAULT_VALUE
  );

  const [validFields, setValidFields] = useState({
    operator_type: false,
    status: false
  });

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!isEqual(formValue, value)) {
      onChange?.(formValue);
    }
    validate();
  }, [formValue]);

  const validate = () => {
    const isValidFields = {
      operator_type: !isNil(formValue.operator_type),
      status: !isNil(formValue.status)
    };

    setValidFields(isValidFields);
  };

  useEffect(() => {
    setIsValid(validFields.operator_type && validFields.status);
  }, [validFields]);

  useEffect(() => {
    onValid?.(isValid);
  }, [isValid]);

  const handleOperatorChange = (val: string) => {
    setValue((prev) => ({ ...prev, operator_type: val }));
  };

  const handleStatusChange = (val: string) => {
    setValue((prev) => ({ ...prev, status: val }));
  };

  useEffect(() => {
    if (value) validate();
  }, [value]);

  return (
    <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <OperatorSelector
          value={formValue?.operator_type}
          onChange={handleOperatorChange}
          excludedOperators={["GE", "LE", "GT", "LT"]}
        />
        <UserStatusSelector
          value={formValue?.status || ""}
          onChange={handleStatusChange}
        />
      </Box>
    </Box>
  );
};

export default RuleStatusForm;
