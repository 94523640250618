import { Box, CircularProgress } from "@mui/material";

/**
    A full screen loading circle. Can be used when lazy loading content etc.
 */

export default function LoadingFullScreen(props: { title: string }) {
  return (
    <Box
      sx={{
        minWidth: "100vw",
        minHeight: "100vh",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
        alignContent: "center"
      }}
    >
      <CircularProgress title={props.title} />
      <Box>{props.title}</Box>
    </Box>
  );
}
