import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery
} from "@mui/material";
import JourneyTimeline from "./JourneyTimeline";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { UserItem } from "../../../item";
import { ErrorBoundary, ScrollableContainer } from "../../../../../components";
import { motion } from "framer-motion";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useParams } from "react-router-dom";
import { useUserItemSubmit } from "../../../../../hooks/dataHooks";
import journeyGetStats from "../../utils/journeyGetStats";

interface ItemJourneyDisplayProps {
  item: ItemUserJourney; //ItemUserJourneyCompleteProps; //ItemUserJourney;
  onChange?: () => void;
  onBack?: () => void;
  onComplete?: () => void;
}

const ItemJourney = (props: ItemJourneyDisplayProps) => {
  const { item, onChange, onBack, onComplete } = props;

  console.log(item);
  const [selectedJourneyGroup, setSelectedJourneyGroup] = useState(-1);
  const [manualSelect, setManualSelect] = useState(false);
  const [itemInteracted, setItemInteracted] = useState(false);
  const [openGroupCompleted, setOpenGroupCompleted] = useState(false);
  const [openJourneyCompleted, setOpenJourneyCompleted] = useState(false);

  const { postItem } = useUserItemSubmit({
    onSuccess: () => {
      //alert("Journey Complete");
      onComplete?.();
    }
  });

  const [journeyItems, setJourneyItems] = useState<
    ItemUserJourneyGroupItemProps[]
  >([]);

  const handleOnTimelineChange = (id: number) => {
    setSelectedJourneyGroup(id);
    setManualSelect(true);
    setItemInteracted(false);
  };

  const handleOnItemCompleted = (success: boolean) => {
    onChange?.();
    setItemInteracted(true);
  };

  const currentGroupComplete = () => {
    const newItems = item.item.item_data.groups.find((journey) => {
      return journey?.id === selectedJourneyGroup;
    });

    return !(
      newItems &&
      newItems?.items.some((item) => item.item_detail.status !== "COMPLETE")
    );
  };

  const updateItems = () => {
    const journeyStats = journeyGetStats(item.item);
    console.log("Journey Get Stats", journeyStats);

    const newItems = item.item.item_data.groups.find((journey) => {
      return journey?.id === selectedJourneyGroup;
    });
    if (!itemInteracted && !manualSelect && !currentGroupComplete()) {
      goToNextIncompleteGroup();
    }
    if (itemInteracted && currentGroupComplete() && !journeyStats.completed) {
      setOpenGroupCompleted(true);
      //findInitGroup();
      setItemInteracted(false);
    }

    if (journeyStats.completed) setOpenJourneyCompleted(true);

    if (newItems && newItems.items.length > 0)
      setJourneyItems(newItems?.items || []);
  };

  useEffect(() => {
    if (selectedJourneyGroup < 0) {
      goToNextIncompleteGroup();
    }
    updateItems();
  }, [selectedJourneyGroup]);

  const handleNextStep = () => {
    goToNextIncompleteGroup();
    setOpenGroupCompleted(false);
  };

  const handleStayHere = () => {
    setOpenGroupCompleted(false);
  };

  const handleJourneyComplete = () => {
    postItem({ id: item.id as number, status: "COMPLETE" });
    //onComplete?.();
    //setOpenJourneyCompleted(false);
  };

  const goToNextIncompleteGroup = useCallback(() => {
    const initGroup = item.item.item_data.groups.filter((group) => {
      const groupNotComplete = group.items?.filter((item) => {
        return item.item_detail?.status !== "COMPLETE";
      });

      return groupNotComplete.length > 0;
    });
    if (initGroup.length > 0)
      setSelectedJourneyGroup(initGroup[0].id as number);
    else
      setSelectedJourneyGroup(
        item.item.item_data.groups[item.item.item_data.groups.length - 1]
          .id as number
      );
  }, [item]);

  useEffect(() => {
    if (item) {
      if (item.item.item_data.groups) {
        updateItems();
      }
    }
  }, [item]);

  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        height: `calc(100vh - ${isLarge ? 80 : 180}px)`
      }}
    >
      <Box sx={{ flexBasis: "50%" }}>
        <ErrorBoundary errorCode="Error Displaying Journey Timeline">
          {/* <Button startIcon={<ChevronLeftIcon />} onClick={() => onBack?.()}>
            Back
          </Button> */}
          <JourneyTimeline
            onChange={handleOnTimelineChange}
            selected={selectedJourneyGroup}
            journey={item.item.item_data}
          />
        </ErrorBoundary>
      </Box>
      <ScrollableContainer
        sx={{
          ...{
            height: "100%", //`calc(100% - ${isLarge ? 64 : 180}px)`,
            padding: 0,
            width: "100%"
          }
        }}
        sxinner={{
          ...{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }
        }}
        showscrolltotop={true}
      >
        <ErrorBoundary errorCode="Error Displaying Journey Items">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            {journeyItems
              ?.sort((a, b) => a.sequence_number - b.sequence_number)
              .map((item, index) => (
                <ErrorBoundary
                  key={item.id}
                  errorCode="43"
                  errorDescription={`Error showing item id : ${item.id}`}
                >
                  <UserItem
                    key={item.id}
                    item={item.item_detail}
                    onItemActionPerformed={handleOnItemCompleted}
                  />
                </ErrorBoundary>
              ))}
          </Box>
        </ErrorBoundary>
      </ScrollableContainer>
      {openGroupCompleted && (
        <JourneyStepComplete
          open={openGroupCompleted}
          title={"You have completed this step."}
          cancelButton={{
            text: "Stay Here and look around",
            onClick: handleStayHere
          }}
          acceptButton={{ text: "Next Step", onClick: handleNextStep }}
        ></JourneyStepComplete>
      )}
      {openJourneyCompleted && (
        <JourneyStepComplete
          open={openJourneyCompleted}
          title={"You have have completed the entire journey!"}
          // cancelButton={{
          //   text: "Stay Here and look around",
          //   onClick: handleStayHere
          // }}
          acceptButton={{
            text: "Complete Journey",
            onClick: handleJourneyComplete
          }}
        ></JourneyStepComplete>
      )}
    </Box>
  );
};

export default ItemJourney;

const JourneyStepComplete = (
  props: PropsWithChildren<{
    open: boolean;
    title: string;
    cancelButton?: { onClick?: () => void; text: string };
    acceptButton?: { onClick?: () => void; text: string };
  }>
) => {
  const { open, title, acceptButton, cancelButton, children } = props;
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { minHeight: "50%" } }}
    >
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Typography variant="h4">Congratulations!</Typography>
            <Box>{title}</Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "8rem"
            }}
          >
            <motion.div
              style={{
                //width: "120px",
                position: "absolute",
                border: "solid 3px green",
                padding: "1rem",
                rotate: "-45deg",
                textAlign: "center",
                color: "green",
                fontWeight: "bolder",
                borderRadius: "1rem",
                display: "inline-block",
                backgroundColor: "rgba(255,255,255,.5)"
              }}
              initial={{
                scale: 3
              }}
              transition={{ duration: 0.5, delay: 0.5 }}
              animate={{
                scale: 1
              }}
            >
              Completed ✔
            </motion.div>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {cancelButton && (
          <Button onClick={() => cancelButton?.onClick?.()}>
            {cancelButton?.text}
          </Button>
        )}
        {acceptButton && (
          <Button variant="contained" onClick={() => acceptButton?.onClick?.()}>
            {acceptButton?.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
