import createEmptyAdministrator, {
  createEmptyAdminDelegation
} from "./createEmptyAdministrator";
import createEmptyAudience from "./createEmptyAudience";
import createEmptyItem from "./createEmptyItem";
import createEmptyRule from "./createEmptyRule";

const createEmpty = {
  Audience: createEmptyAudience,
  Item: createEmptyItem,
  Rule: createEmptyRule,
  Administrator: createEmptyAdministrator,
  AdminDelegation: createEmptyAdminDelegation
};

export default createEmpty;
