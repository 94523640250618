import { Box, SxProps } from "@mui/material";

export default function DisplayHtml({
  html,
  sx,
}: {
  html: string;
  sx?: SxProps;
}) {
  return (
    <Box
      sx={sx}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}
