import { ErrorBoundary } from "../../../../../components";

interface RuleStatusDisplayProps {
  value: RuleStatus;
}

const RuleStatusDisplay = (props: RuleStatusDisplayProps) => {
  return (
    <ErrorBoundary>
      <>
        Status - Display when users item status {props?.value?.operator_type}{" "}
        {props?.value?.status}.
      </>
    </ErrorBoundary>
  );
};

export default RuleStatusDisplay;
