import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";

const propertyOptions = [
  { value: "user_item.due_date", label: "Due Date" },
  { value: "item_list.item_type", label: "Item Type" },
  { value: "item_list.item_title", label: "Title" }
];

export interface ItemSortFormValueProps {
  property: string;
  direction?: string;
}

interface ItemSortFormProps {
  onChange?: (value: ItemSortFormValueProps[]) => void;
  value?: ItemSortFormValueProps[];
}

interface ItemSortSelectProps {
  id: number;
  value: ItemSortFormValueProps;
}

const ItemSortForm = (props: ItemSortFormProps) => {
  const { value, onChange } = props;
  const [sortValues, setSortValues] = useState<
    ItemSortSelectProps[] | undefined
  >(value?.map((val, index) => ({ id: index, value: val })));

  const handleSortValueChange = ({
    id,
    value
  }: {
    id: number;
    value: ItemSortFormValueProps;
  }) => {
    let vals = [];
    if (sortValues) {
      vals = [...sortValues];
      let sortVal = vals.findIndex((val) => val.id === id);
      if (sortVal > -1) vals[sortVal] = { ...vals[sortVal], value: value };
      else vals.push({ id: id, value: value });
      setSortValues(vals);
    } else {
      vals.push({ id: id, value: value });
      setSortValues(vals);
    }

    const sortedVals = vals.sort((a, b) => a.id - b.id);

    const stringVal = sortedVals
      .map((obj) => `${obj.value.property} ${obj.value.direction}`)
      .join(", ");

    const returnVals = sortedVals.map((obj) => obj.value);

    //console.log(stringVal, returnVals);
    onChange?.(returnVals);
    // setSortValues((prevState) => {
    //   //const vals = ...prevState;
    //   return { property: "user_item.due_date", direction: "desc" };
    // });
  };

  useEffect(() => {
    const initVals = value?.map((val, index) => ({
      id: index,
      value: val
    }));
    // console.log(
    //   "defaultValues",
    //   defaultValues?.sort((a, b) => a.id - b.id)
    // );
    if (!isEqual(sortValues, initVals)) setSortValues(initVals);
  }, [value]);
  return (
    <Box>
      <ItemSortField
        value={
          sortValues?.find((val) => val.id === 0)?.value || {
            property: "user_item.due_date",
            direction: "desc"
          }
        }
        onChange={(val) => {
          handleSortValueChange({ id: 0, value: val });
        }}
      />
      {/* <ItemSortField
        value={
          sortValues?.find((val) => val.id === 2)?.value || {
            property: "user_item.due_date",
            direction: "desc"
          }
        }
        onChange={(val) => {
          handleSortValueChange({ id: 2, value: val });
        }}
      /> */}
    </Box>
  );
};

export default ItemSortForm;

interface ItemSortFieldProps {
  value: ItemSortFormValueProps;
  onChange?: (value: ItemSortFormValueProps) => void;
}

const ItemSortField = (props: ItemSortFieldProps) => {
  const { value, onChange } = props;
  const { property, direction = "desc" } = value;
  const [selectValue, setSelectValue] = useState(value);

  useEffect(() => {
    onChange?.(selectValue);
  }, [selectValue]);
  return (
    <Box sx={{ display: "flex", gap: "1rem", paddingTop: "1rem" }}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id={`sort-${property}`}>Sorty By</InputLabel>
        <Select
          labelId={`sort-${property}`}
          value={property}
          label="Sort By"
          onChange={(val) => {
            setSelectValue((prevState) => ({
              ...prevState,
              property: val.target.value
            }));
          }}
        >
          {propertyOptions.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id={`sort-${direction}`}>Sort Order</InputLabel>
        <Select
          labelId={`sort-${direction}`}
          value={direction}
          label="Sort Order"
          onChange={(val) => {
            setSelectValue((prevState) => ({
              ...prevState,
              direction: val.target.value
            }));
          }}
        >
          <MenuItem value="asc">Ascending</MenuItem>
          <MenuItem value="desc">Descending</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
