import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import JourneyGroupItemsFormFields from "./JourneyGroupItemsFormFields";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useEffect, useState } from "react";
import JourneyGroupItemDialogForm from "./JourneyGroupItemDialogForm";
import { isEqual } from "lodash";
import { createGuid } from "../../../../../utils";
import { OPERATIONTYPES } from "../../../../../types/enums";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { VpnLock } from "@mui/icons-material";
import MuiIcon from "../../../../../components/icons";

interface JourneyGroupFormFieldsProps {
  value?: ItemJourneyGroupProps;
  onChange?: Function;
  onEdit?: () => void;
  onDelete?: () => void;
  onRestore?: () => void;
  sortProps?: SyntheticListenerMap | undefined;
  originalValue?: ItemJourneyGroupProps;
}

const JourneyGroupFormFields = (props: JourneyGroupFormFieldsProps) => {
  const {
    value,
    onChange,
    onEdit,
    onDelete,
    onRestore,
    sortProps,
    originalValue
  } = props;
  const theme = useTheme();
  const [openForm, setOpenForm] = useState(false);

  const [items, setItems] = useState<ItemJourneyGroupItemProps[]>(
    value?.items || []
  );

  const handleOnEdit = () => {
    onEdit?.();
  };

  const handleOnDelete = () => {
    onDelete?.();
  };

  //console.log("JourneyGroupFormFields originalValue", originalValue);
  const handleOnSaveNewItem = (val: ItemCompleteFormProps) => {
    const copy = [...items];

    copy.push({
      id: createGuid(),
      item: val,
      sequence_number: copy.length + 1,
      item_id: Number(val.id),
      operation: OPERATIONTYPES.CREATE.KEY
    });
    setItems(copy);
    handleOnClose();
  };

  const handleAddNewItem = () => {
    setOpenForm(true);
  };

  const handleOnClose = () => {
    setOpenForm(false);
  };

  const handleOnChange = (val: any) => {
    //console.log("handleOnChange", val);
    setItems(val);
  };

  useEffect(() => {
    if (value?.items) setItems(value?.items);
  }, [value?.items]);

  useEffect(() => {
    //console.log("items", value);
    if (!isEqual(items, value?.items))
      onChange?.({
        ...value,
        items: items,
        operation: !!value?.operation
          ? value?.operation
          : OPERATIONTYPES.UPDATE.KEY
      });
  }, [items]);

  let style = {
    borderColor: "",
    borderWidth: ""
  };
  switch (value?.operation) {
    case OPERATIONTYPES.DELETE.KEY: //OPERATION.DELETE:
      //bgColor = "#ffd5d5";
      style.borderColor = theme.palette.error.main;
      style.borderWidth = "2px";
      break;
    case OPERATIONTYPES.UPDATE.KEY: //OPERATION.UPDATE:
      //bgColor = "#e4f1ff";
      style.borderColor = theme.palette.info.main;
      style.borderWidth = "2px";
      break;
    case OPERATIONTYPES.CREATE.KEY: //OPERATION.CREATE:
      style.borderColor = theme.palette.success.main;
      style.borderWidth = "2px";
      //bgColor = "#efffef";
      break;
    default:
    //bgColor = undefined;
    //style.borderColor = theme.palette.primary.main;
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        position: "relative",
        borderColor: style.borderColor,
        borderWidth: style.borderWidth
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -10,
          left: -10,
          height: "1.5rem",
          backgroundColor: "white",
          borderRadius: "100%"
        }}
      >
        {value?.operation === OPERATIONTYPES.CREATE.KEY && (
          <AddCircleIcon sx={{ color: "green" }} />
        )}
        {value?.operation === OPERATIONTYPES.DELETE.KEY && (
          <RemoveCircleIcon sx={{ color: "red" }} />
        )}
        {value?.operation === OPERATIONTYPES.UPDATE.KEY && (
          <ChangeCircleIcon sx={{ color: "blue" }} />
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0.2rem 1rem"
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <MuiIcon iconName={value?.icon} />
              <Typography
                sx={{
                  fontWeight: "bolder",
                  cursor: sortProps ? "row-resize" : "initial"
                }}
                {...sortProps}
              >
                {value?.sequence_number}. {value?.title}{" "}
                {value?.required ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  <></>
                )}
              </Typography>
              <Tooltip title="Edit Journey Group Details">
                <IconButton
                  size="small"
                  sx={{ color: "lightgray" }}
                  onClick={handleOnEdit}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {value?.operation !== OPERATIONTYPES.DELETE.KEY && (
              <Tooltip title="Delete the Journey Group">
                <IconButton onClick={handleOnDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {value?.operation === OPERATIONTYPES.DELETE.KEY && (
              <Tooltip title="Cancel Deletion for the Journey Group">
                <IconButton onClick={onRestore}>
                  <RestoreFromTrashIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Add an Item to the Journey Group">
              <IconButton onClick={handleAddNewItem}>
                <PlaylistAddIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ padding: "1rem" }}>
          <JourneyGroupItemsFormFields
            items={items}
            onChange={handleOnChange}
            originalValue={originalValue?.items}
          />
        </Box>
      </Box>
      <JourneyGroupItemDialogForm
        open={openForm}
        onSave={handleOnSaveNewItem}
        onCancel={handleOnClose}
        onClose={handleOnClose}
      />
    </Paper>
  );
};

export default JourneyGroupFormFields;
