import { useCallback, useEffect, useState } from "react";
import { htmlContentTextLength } from "../../../../../utils";

const useItemJourneyFormFields = (props?: ItemJourneyData) => {
  const [value, setValue] = useState<ItemJourneyData>({
    ...{ title: "", description: "", groups: [], dismissable: true },
    ...props
  });

  const [valid, setValid] = useState(false);

  const [validFields, setValidFields] = useState({
    title: false,
    description: false,
    groups: false
  });

  const validate = () => {
    const validObj = {
      title: value.title.length > 0,
      description: htmlContentTextLength(value.description) > 0,
      groups: true //value.groups.length > 0
    };
    setValidFields(validObj);
  };

  const updateValue = (val: Partial<ItemJourneyData>) => {
    setValue((prev) => ({ ...prev, ...val }));
  };

  useEffect(() => {
    validate();
  }, [value]);

  useEffect(() => {
    const isValid =
      validFields.description && validFields.title && validFields.groups;
    setValid(isValid);
  }, [validFields]);

  return {
    value,
    updateValue,
    valid,
    validFields
  };
};

export default useItemJourneyFormFields;
