import {
  Box,
  Chip,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuProps
} from "@mui/material";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import AlarmIcon from "@mui/icons-material/Alarm";

import { useAtom } from "jotai";
import notificationsAtom from "../../../globalstates/notificationsAtom";

const MainMessageMenu = (props: MenuProps) => {
  const [notificationsCount, setNotificationsCount] =
    useAtom(notificationsAtom);

  const handleOpenAnnouncement = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsCount((prev) => ({
      ...prev,
      announcementsDismissed: false
    }));
    props?.onClose?.(event, "backdropClick");
  };

  return (
    <Menu
      id="messages-menu"
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: "300px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      {...props}
    >
      {3 > 0 && (
        <MenuItem onClick={handleOpenAnnouncement} divider>
          <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText>Announcements</ListItemText>
          <Chip
            label={notificationsCount?.announcementsCount || 0}
            size="small"
            sx={{ backgroundColor: "error.main", color: "white" }}
          />
        </MenuItem>
      )}
      <ListSubheader
        sx={{
          // backgroundColor: "primary.light",
          //color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Box>Notifications - (Coming soon)</Box>
        <Chip
          label="3"
          size="small"
          sx={{ backgroundColor: "secondary.main", color: "white" }}
        />
      </ListSubheader>

      {/* <Divider variant="middle" sx={{ marginBottom: "1rem" }} /> */}
      <MenuItem>
        <ListItemIcon>
          <WarningIcon />
        </ListItemIcon>
        You have 3 Overdue items to complete (DEMO).
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <AlarmIcon />
        </ListItemIcon>
        3 Items are approaching deadline (DEMO).
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        Your profile may require updating (DEMO).
      </MenuItem>
    </Menu>
  );
};

export default MainMessageMenu;
