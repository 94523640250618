import { useEffect, useState, lazy, Suspense } from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

import Typography from "@mui/material/Typography";

import { Box, LinearProgress, SxProps } from "@mui/material";
import { DisplayHtml } from "../../../../../components";
import journeyGetGroupStats from "../../utils/journeyGetGroupStats";
//import  MuiIcon  from "../../../../../components/icons/MuiIcon";

const MuiIcon = lazy(() => import("../../../../../components/icons/MuiIcon"));
interface JourneyTimelineGroupProps {
  group: ItemUserJourneyGroupProps;
  selected?: boolean;
  onClick?: (id: number) => void;
  stepPosition: string;
}

const JourneyTimelineGroup = (props: JourneyTimelineGroupProps) => {
  const { group, selected, onClick, stepPosition } = props;

  const [journeyStats, setJourneyStats] = useState({
    count: 0,
    completed: 0,
    percent: 0
  });

  useEffect(() => {
    // const count = group.items?.length || 0;
    // const completed =
    //   group.items?.filter((item) => {
    //     //console.log(item.item_detail.status);
    //     return item.item_detail?.status === "COMPLETE";
    //   }).length || 0;
    // const percent = (100 / count) * completed;

    // const stats = {
    //   count: count,
    //   completed: completed,
    //   percent: percent
    // };
    const stats = journeyGetGroupStats(group);
    //console.log(stats);
    setJourneyStats(stats);
  }, [group.items]);

  const selectedStyle: SxProps = selected
    ? { fontWeight: "bolder" }
    : {
        opacity: "0.5",
        cursor: "pointer",
        "&:hover": {
          opacity: "0.8"
        }
      };

  return (
    <TimelineItem
      sx={selectedStyle}
      onClick={() => {
        if (!selected) onClick?.(group.id as number);
      }}
    >
      <TimelineOppositeContent
        sx={{ m: "auto 0" }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {group.secondary_description}
      </TimelineOppositeContent>
      <TimelineSeparator>
        {/* {stepPosition !== "first" && stepPosition !== "only" && (
          <TimelineConnector sx={{ opacity: stepPosition !== "first" && stepPosition !== "only" ? 0 : 1}} />
        )} */}
        <TimelineConnector
          sx={{
            opacity: stepPosition !== "first" && stepPosition !== "only" ? 1 : 0
          }}
        />
        <TimelineDot
          color={
            selected
              ? "secondary"
              : group.status !== "COMPLETE"
              ? "primary"
              : undefined
          }
        >
          <Suspense fallback={<></>}>
            <MuiIcon
              iconName={group.icon}
              sx={{ scale: selected ? "1.2" : "" }}
            />
          </Suspense>
        </TimelineDot>
        {/* {stepPosition !== "last" && stepPosition !== "only" && (
          <TimelineConnector />
        )} */}

        <TimelineConnector
          sx={{
            opacity: stepPosition !== "last" && stepPosition !== "only" ? 1 : 0
          }}
        />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 2 }}>
        <Typography variant="h6" component="span" sx={{ fontWeight: "bolder" }}>
          {group.title}
        </Typography>
        <DisplayHtml html={group.description} />
        {/* <Typography>{group.description}</Typography> */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color={
                journeyStats.percent === 0
                  ? "error"
                  : journeyStats.percent < 100
                  ? "info"
                  : "success"
              }
              value={journeyStats.percent}
              sx={{ height: "10px", borderRadius: "0.2rem" }}
            />
          </Box>
          <Box>{`${journeyStats.completed}/${journeyStats.count}`}</Box>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default JourneyTimelineGroup;
