import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";

import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import JourneyGroupFormFields from "./JourneyGroupFormFields";
import JourneyGroupDialogForm from "./JourneyGroupDialogForm";
import { createGuid } from "../../../../../utils";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges
} from "@dnd-kit/modifiers";
import JourneyGroupSortableItem from "./JourneyGroupSortableGroup";
import { isEqual } from "lodash";
import { OPERATIONTYPES } from "../../../../../types/enums";

interface JourneyGroupsFormFieldsProps {
  value?: any[];
  onChange?: Function;
  error?: boolean;
  originalValue?: any[];
}

const JourneyGroupsFormFields = (props: JourneyGroupsFormFieldsProps) => {
  const { value, onChange, error, originalValue } = props;

  const [activeId, setActiveId] = useState();

  const [formOpen, setFormOpen] = useState(false);

  const [groups, setGroups] = useState<ItemJourneyGroupProps[]>(value || []);

  const [formValue, setFormValue] = useState<
    ItemJourneyGroupProps | undefined
  >();

  //console.log("JourneyGroupsFormFields originalValue", originalValue);

  //const originalGroups = originalValue as ItemJourneyGroupProps[];

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragStart(event: any) {
    //console.log(event.active.id);
    setActiveId(event.active.id);
  }

  const valuesChanged = (group: ItemJourneyGroupProps) => {
    const original: ItemJourneyGroupProps = originalValue?.find(
      (fGroup) => group.id.toString() === fGroup.id.toString()
    );
    //console.log("Checking valuesChanged", group, original);
    return !(
      original.title === group.title &&
      original.description === group.description &&
      original.icon === group.icon &&
      original.secondary_description === group.secondary_description &&
      original.required === group.required
    );
  };

  const reSequence = (groups: ItemJourneyGroupProps[]) => {
    const resequencedGroups = groups.map((group, index) => {
      const originalSeq = originalValue?.find(
        (fGroup) => group.id.toString() === fGroup.id.toString()
      )?.sequence_number;

      const newSequence = index + 1;
      const changedPosition =
        (!group.operation || group.operation === OPERATIONTYPES.UPDATE.KEY) &&
        originalSeq !== newSequence;

      let newGroup: ItemJourneyGroupProps = {
        ...group,
        sequence_number: newSequence
      };
      if (changedPosition) {
        newGroup.operation = OPERATIONTYPES.UPDATE.KEY;
      }
      if (
        !changedPosition &&
        newGroup.operation === OPERATIONTYPES.UPDATE.KEY
      ) {
        const hasItems = newGroup.items?.some((item) => {
          //console.log("!!item.operation" + item.item_id, !!item.operation);
          return !!item.operation;
        });
        const valsChanged = valuesChanged(newGroup);
        // console.log(
        //   "valsChanged / hasItems ",
        //   newGroup,
        //   newGroup.items,
        //   valsChanged,
        //   hasItems
        // );
        if (!hasItems && !valsChanged) {
          delete newGroup["operation"];
        }
      }

      if (
        !!!newGroup.operation &&
        (newGroup.items?.some((item) => !!item.operation) ||
          valuesChanged(newGroup))
      ) {
        newGroup.operation = OPERATIONTYPES.UPDATE.KEY;
      }

      // if (!newGroup.operation) {
      //   if (newGroup.items?.some((item) => !!item.operation)) {
      //     console.log("Group has no operation but items do!");
      //   }
      // }
      return newGroup;
    });

    return resequencedGroups;
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const groupsCopy = [...groups];
      const oldIndex = groupsCopy.findIndex((findGroup) => {
        return findGroup.id === active.id;
      });
      const newIndex = groupsCopy.findIndex((findGroup) => {
        return findGroup.id === over.id;
      });
      const updatedGroups = arrayMove(groupsCopy, oldIndex, newIndex);
      const resequencedGroups = reSequence(updatedGroups);
      setGroups(resequencedGroups);
      setActiveId(undefined);
    }
  }

  const handleFormNew = () => {
    setFormValue({
      id: createGuid(),
      title: "",
      description: "",
      secondary_description: "",
      icon: "",
      sequence_number: groups.length + 1,
      status: "ACTIVE",
      items: [],
      operation: OPERATIONTYPES.CREATE.KEY,
      required: false
    });
  };

  // const handleFormOpen = () => {
  //   setFormOpen(true);
  // };

  const handleOnDialogSave = (val: ItemJourneyGroupProps) => {
    const groupsCopy = [...groups];
    const updateIndex = groupsCopy.findIndex((group) => {
      return group.id === val.id;
    });

    if (updateIndex > -1) {
      const newVal = { ...val };
      newVal.operation = OPERATIONTYPES.UPDATE.KEY;
      groupsCopy[updateIndex] = newVal;
    } else {
      const newVal = { ...val };
      newVal.operation = OPERATIONTYPES.CREATE.KEY;
      groupsCopy.push(val);
    }

    const resequencedGroups = reSequence(groupsCopy);
    setGroups(resequencedGroups);

    handleOnDialogCancel();
  };

  const handleOnDialogCancel = () => {
    setFormValue(undefined);
    setFormOpen(false);
  };

  const handleOnEditGroupDetails = (val: ItemJourneyGroupProps) => {
    console.log("edit journey group", val);
  };

  const handleOnGroupDelete = (id: number | string) => {
    if (id) {
      let group = groups.find((grp) => {
        return grp.id === id;
      });
      if (group?.operation && group.operation === OPERATIONTYPES.CREATE.KEY) {
        let groupsCopy = groups.filter((item) => item.id !== id);

        const resequencedGroups = reSequence(groupsCopy);
        setGroups(resequencedGroups);
      }

      if (
        !group?.operation ||
        (group?.operation && group.operation !== OPERATIONTYPES.CREATE.KEY)
      ) {
        let groupsCopy = [...groups];
        let groupIndex = groupsCopy.findIndex((grp) => grp.id === id);
        groupsCopy[groupIndex].operation = OPERATIONTYPES.DELETE.KEY;
        const resequencedGroups = reSequence(groupsCopy);
        setGroups(resequencedGroups);
      }
    }
  };

  const handleOnGroupRestore = (id: string | number) => {
    if (id) {
      let group = groups.find((grp) => {
        return grp.id === id;
      });
      if (group?.operation && group.operation === OPERATIONTYPES.DELETE.KEY) {
        let groupsCopy = [...groups];
        let groupsIndex = groupsCopy.findIndex((grp) => grp.id === id);
        delete groupsCopy[groupsIndex]["operation"];

        const resequencedGroups = reSequence(groupsCopy);
        setGroups(resequencedGroups);
      }
    }
  };

  useEffect(() => {
    if (formValue) setFormOpen(true);
  }, [formValue]);

  useEffect(() => {
    //console.log(groups);
    if (!isEqual(groups, value)) onChange?.(groups);
  }, [groups, value]);

  useEffect(() => {
    if (value)
      setGroups(
        value.map((group) => ({
          ...group,
          id: group.id === 0 ? group.id.toString() : group.id
        }))
      );
  }, [value]);

  const handleOnGroupFormFieldsChange = (val: any) => {
    //console.log("handleOnGroupFormFieldsChange", val);
    const groupsCopy = [...groups];
    const updateIndex = groupsCopy.findIndex((group) => {
      return group.id === val.id;
    });

    groupsCopy[updateIndex] = val;
    const resequencedGroups = reSequence(groupsCopy);
    setGroups(resequencedGroups);
    handleOnDialogCancel();
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          borderColor: error ? "red" : "initial"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Typography variant="overline">Journey Groups</Typography>
          <Tooltip title="Add a Journey Group">
            <IconButton onClick={handleFormNew}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          >
            <SortableContext
              items={groups}
              strategy={verticalListSortingStrategy}
            >
              {groups.map((group) => {
                const oV = originalValue?.find((grp) => {
                  return grp.id.toString() === group.id.toString();
                });

                return (
                  <JourneyGroupSortableItem
                    key={group.id}
                    value={group}
                    onEdit={() => {
                      setFormValue(group);
                    }}
                    onChange={handleOnGroupFormFieldsChange}
                    onDelete={() => {
                      handleOnGroupDelete(group.id);
                    }}
                    onRestore={() => {
                      handleOnGroupRestore(group.id);
                    }}
                    originalValue={oV}
                  />
                );
              })}
            </SortableContext>
            <DragOverlay style={{ scale: "0.98" }}>
              {activeId ? (
                <JourneyGroupFormFields
                  value={groups.find((item) => {
                    return item.id === activeId;
                  })}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </Box>
      </Paper>
      <JourneyGroupDialogForm
        value={formValue}
        open={formOpen}
        onClose={handleOnDialogCancel}
        onSave={handleOnDialogSave}
        onCancel={handleOnDialogCancel}
      />
    </>
  );
};

export default JourneyGroupsFormFields;
