import { useEffect, useState } from "react";
import { htmlContentTextLength } from "../../../../../utils";

const useJourneyGroupDialogForm = (value?: ItemJourneyGroupProps) => {
  const [formValue, setFormValue] = useState<ItemJourneyGroupProps>(() => {
    return (
      value || {
        id: "",
        title: "",
        description: "",
        secondary_description: "",
        sequence_number: 0,
        items: [],
        icon: "",
        status: ""
      }
    );
  });

  const [validFields, setValidFields] = useState({
    title: false,
    description: false
    //secondary_description: false
  });

  const [isValid, setIsValid] = useState(false);

  const validate = () => {
    const validObj = {
      title: formValue.title.length > 0,
      description: htmlContentTextLength(formValue.description) > 0
    };
    setValidFields(validObj);
  };

  const updateFormValue = (val: Partial<ItemJourneyGroupProps>) => {
    setFormValue((prev) => ({ ...prev, ...val }));
  };

  useEffect(() => {
    validate();
  }, [formValue]);

  useEffect(() => {
    setIsValid(validFields.title && validFields.description);
  }, [validFields]);

  return { formValue, updateFormValue, validFields, isValid };
};

export default useJourneyGroupDialogForm;
