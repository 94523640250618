import Timeline from "@mui/lab/Timeline";
import Typography from "@mui/material/Typography";

import JourneyTimelineGroup from "./JourneyTimelineGroup";
export interface JourneyStepProps {
  id: number;
  title: string;
  description: string;
  iconName: string;
  time: string;
  selected: boolean;
  status: string;
  items?: any[];
}

interface JourneyTimelineProps {
  onChange?: (id: number) => void;
  selected?: number;
  journey: ItemUserJourneyData;
}

export default function JourneyTimeline(props: JourneyTimelineProps) {
  const { onChange, selected, journey } = props;
  const handleOnClick = (id: number) => {
    //console.log(id);
    onChange?.(id);
  };
  return (
    <Timeline position="right" sx={{ minWidth: "280px" }}>
      <Typography variant="h5">{journey.title}</Typography>

      {journey.groups?.map((group, index) => {
        const first = index === 0;
        const last = index === journey.groups.length - 1;
        const only = journey.groups.length === 1;
        // console.log(first, last, only);

        const position =
          first && !only
            ? "first"
            : last && !only
            ? "last"
            : only
            ? "only"
            : "none";

        // console.log(position);
        return (
          <JourneyTimelineGroup
            key={group.id}
            stepPosition={position}
            onClick={handleOnClick}
            group={group}
            selected={selected === group.id}
          />
        );
      })}
    </Timeline>
  );
}
