import createGuid from "./createGuid";

export const createEmptyAdministrator = (): Administrator => {
  const newEmpty = {
    id: createGuid(),
    upn: "",
    first_name: "",
    last_name: "",
    system_role: "",
    status: "",
    admin_delegation: [],
    created_by: ""
  };
  return newEmpty;
};

export const createEmptyAdminDelegation = (): AdminDelegation => {
  const newEmpty = {
    id: createGuid(),
    created_date: "",
    created_by: "",
    start_date: "",
    end_date: "",
    operation: "",
    //admin_id:"",
    delegated_clevel: ""
  };
  return newEmpty;
};

export default createEmptyAdministrator;
