import createGuid from "./createGuid";

// interface CreateEmptyItemProps {
//   generateId?: boolean;
//   type?: string;
// }

export const createEmptyItemBase = (generateId = false, type = "") => {
  const newEmpty: ItemProps = {
    id: generateId ? createGuid() : "",
    item_type: type,
    item_title: "",
    item_description: "",
    status: "NEW",
    item_data: {},
    due_period: "",
    administrative_level: ""
  };
  return newEmpty;
};

export const createEmptyItemComplete = (generateId: boolean, type: string) => {
  const newItem = createEmptyItemBase(generateId, type);
  const completeItem: ItemCompleteProps = {
    ...newItem,
    ...{
      item_audience: [],
      item_rule: [],
      created_by: "",
      created_date: "",

      deleted_flag: false
    }
  };
  return completeItem;
};

export const createEmptyItemCompleteForm = (
  generateId: boolean,
  type: string
) => {
  const newItem = createEmptyItemComplete(generateId, type);
  const completeItem: ItemCompleteFormProps = {
    ...newItem,
    ...{ item_audience: [], item_rule: [] }
  };
  return completeItem;
};

const createEmptyItem = (
  generateId = false,
  type = "",
  attributes?: "complete" | "completeform"
) => {
  if (attributes === "complete")
    return createEmptyItemComplete(generateId, type);
  if (attributes === "completeform")
    return createEmptyItemCompleteForm(generateId, type);
  return createEmptyItemBase(generateId, type);
};

export default createEmptyItem;
