import { forwardRef } from "react";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import InfoIcon from "@mui/icons-material/Info";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import TaskIcon from "@mui/icons-material/Task";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import CampaignIcon from "@mui/icons-material/Campaign";
import RouteIcon from "@mui/icons-material/Route";
//import ItemTypes from "../../../../items/item/itemEnum";
import { ITEMTYPES } from "../../../../types/enums";
import { SxProps } from "@mui/material";

const ItemIconSize = {
  small: "1rem",
  medium: "2rem",
  large: "3rem",
  undefined: null
};

const getItemSize = (size: string | null | undefined) => {
  const returnsize = null;
  switch (size) {
    case "small":
      return "1rem";
    case "medium":
      return "2rem";
    case "large":
      return "3rem";
    case "xlarge":
      return "5rem";
    default:
      return null;
  }
  return returnsize;
};

interface CustomIconProps {
  size?: string;
  iconSx?: SxProps;
}

interface ItemIconProps extends CustomIconProps {
  name: string;
}

export default function ItemIcon({ name, size, iconSx }: ItemIconProps) {
  //console.log("Item Icon", name);
  return getItemIcon(name, size, iconSx);
}

function getItemIcon(name: string, size?: string, iconSx?: SxProps) {
  if (name === ITEMTYPES.MEETING.KEY)
    return <MeetingItemIcon size={size} iconSx={iconSx} />;
  //if (name === ItemTypes.ITEM_INDUCTION) return <InductionItemIcon />;
  if (name === ITEMTYPES.ECENTRE.KEY)
    return <InductionItemIcon size={size} iconSx={iconSx} />;
  if (name === ITEMTYPES.JOURNEY.KEY)
    return <JourneyItemIcon size={size} iconSx={iconSx} />;
  if (name === ITEMTYPES.VIDEO.KEY)
    return <VideoItemIcon size={size} iconSx={iconSx} />;
  if (name === ITEMTYPES.INFORMATION.KEY)
    return <InformationItemIcon size={size} iconSx={iconSx} />;
  //if (name === ITEMTYPES.ANNOUNCEMENT.KEY) return <WelcomeItemIcon />;
  if (name === ITEMTYPES.ANNOUNCEMENT.KEY)
    return <AnnouncementItemIcon size={size} iconSx={iconSx} />;

  return null;
}

export const MeetingItemIcon = forwardRef((props: CustomIconProps, ref) => {
  return (
    <EmojiPeopleIcon
      sx={{
        color: "#67cfac",
        fontSize: getItemSize(props.size),
        ...props.iconSx
      }}
    />
  );
});

//export function InformationItemIcon(props: { size?: string }) {
export const InformationItemIcon = forwardRef((props: CustomIconProps, ref) => {
  return (
    <InfoIcon
      sx={{
        color: "#038cfc",
        fontSize: getItemSize(props.size),
        ...props.iconSx
      }}
    />
  );
});

export const InductionItemIcon = forwardRef((props: CustomIconProps, ref) => {
  return (
    <FactCheckIcon
      sx={{
        color: "#2297bf",
        fontSize: getItemSize(props.size),
        ...props.iconSx
      }}
    />
  );
});

export const TaskItemIcon = forwardRef((props: CustomIconProps, ref) => {
  return (
    <TaskIcon
      sx={{
        color: "#f54298",
        fontSize: getItemSize(props.size),
        ...props.iconSx
      }}
    />
  );
});

export const VideoItemIcon = forwardRef((props: CustomIconProps, ref) => {
  return (
    <OndemandVideoIcon
      sx={{ color: "red", fontSize: getItemSize(props.size), ...props.iconSx }}
    />
  );
});

export const JourneyItemIcon = forwardRef((props: CustomIconProps, ref) => {
  return (
    <RouteIcon
      sx={{
        color: "orange",
        fontSize: getItemSize(props.size),
        ...props.iconSx
      }}
    />
  );
});

// export const WelcomeItemIcon = forwardRef((props: { size?: string }, ref) => {
//   return (
//     <StickyNote2Icon
//       sx={{ color: "#038cfc", fontSize: getItemSize(props.size) }}
//     />
//   );
// });

export const AnnouncementItemIcon = forwardRef(
  (props: CustomIconProps, ref) => {
    return (
      <CampaignIcon
        sx={{
          color: "#038cfc",
          fontSize: getItemSize(props.size),
          ...props.iconSx
        }}
      />
    );
  }
);
