import { Badge, Box, Button, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import MainMessageMenu from "./MainMessageMenu";
import AnimatedNotificationIcon from "./AnimatedNotificationIcon";
import { useAtomValue } from "jotai";
import notificationsAtom from "../../../globalstates/notificationsAtom";

const MainMessageButton = () => {
  const notificationsCount = useAtomValue(notificationsAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [notificationCount, setNotificationCount] = useState(
    (notificationsCount?.announcementsCount || 0) +
      (notificationsCount?.notificationsCount || 0)
  );

  useEffect(() => {
    setNotificationCount(
      (notificationsCount?.announcementsCount || 0) +
        (notificationsCount?.notificationsCount || 0)
    );
  }, [notificationsCount]);

  return (
    <>
      <Tooltip title="Messages">
        <Button
          onClick={handleClick}
          sx={{ borderBottom: "2px solid transparent" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Badge
              badgeContent={notificationCount}
              color="error"
              overlap="circular"
            >
              <AnimatedNotificationIcon animated={notificationCount > 0} />
              {/* <NotificationsActiveIcon /> */}
              {/* <MailOutlineIcon /> */}
            </Badge>
            Notifications
          </Box>
        </Button>
      </Tooltip>
      <MainMessageMenu
        anchorEl={anchorEl}
        open={open}
        // onClick={handleClose}
        onClose={handleClose}
      />
    </>
  );
};

export default MainMessageButton;
