import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import adminitems from "../../testdata/adminitems";
import { useEffect, useState } from "react";
import adminItems from "../../testdata/adminitems";
import { type } from "os";
import useApiCall from "./useApiCall";

//const API_PATH = process.env.REACT_APP_API_PATH;

interface UseItemProps {
  id: string | number;
  initialFetch: boolean;
}

/**
 * Hook for Admin Items
 */
const useItem = ({ id, initialFetch = true }: Partial<UseItemProps>) => {
  const [item, setItem] = useState<ItemCompleteFormProps | undefined>();
  // const { isLoading, error, data, isFetching, refetch } = useQuery(
  //   ["useitem" + id],
  //   () =>
  //     axios
  //       .get(`${API_PATH}/admin/item?id=${id}`)
  //       .then((res: any) => res)
  //       .catch((err) => {
  //         console.log("Fetch Error", err);
  //       }),
  //   {
  //     enabled: (initialFetch && id && id.toString().length > 0) || false,
  //     cacheTime: 1,
  //     staleTime: 1,
  //     refetchOnMount: true
  //   }
  // );

  const { isLoading, error, data, isFetching, refetch } = useApiCall({
    name: ["useitem" + id],
    api: `/admin/item?id=${id}`,
    options: {
      enabled: (initialFetch && id && id.toString().length > 0) || false,
      cacheTime: 1,
      staleTime: 1,
      refetchOnMount: true
    }
  });

  useEffect(() => {
    if (!isFetching && data) {
      //console.log("useItem data", data);
      const refactoredItems = {
        ...data.data.items[0],
        ...{
          //created_date: data.data.create_date,
          item_type: data.data.items[0].item_type.toString().toUpperCase()
        }
      };
      //console.log("refactoredItems", refactoredItems);
      setItem(refactoredItems);
    }
  }, [data, isFetching]);

  return {
    item,
    isFetching,
    isLoading,
    error,
    refetch
  };
};

export default useItem;
