import { useEffect, useState } from "react";
import { useTeams } from "msteams-react-base-component";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useClientProfile } from "../../hooks";

import { useAtom } from "jotai";
import globalAtom, {
  userAtom,
  userAdminAtom,
  journeyAtom
} from "../../globalstates";
import { useApiCall, useItems } from "../../hooks/dataHooks";
import journeyGetStats from "../../features/items/journey/utils/journeyGetStats";
import usePreferences from "../../hooks/dataHooks/usePreferences";
import { setJourneyStateData } from "../../features/items/journey/utils";

const useApp = () => {
  const isAuthenticated = useIsAuthenticated();
  const [{ inTeams }] = useTeams();
  const [globalState, setGlobalState] = useAtom(globalAtom);

  const { instance, inProgress } = useMsal();
  const [clientProfile, fetchingClient, setClientProfile] = useClientProfile();
  const [loading, setLoading] = useState(true);
  const [displayWelcome, setDisplayWelcome] = useState(false);
  const [user, setUser] = useState<any | null>(null);

  const [userState, setUserState] = useAtom(userAtom);
  const [userAdminState, setUserAdminState] = useAtom(userAdminAtom);
  const [journeyState, setJourneyState] = useAtom(journeyAtom);

  const [globalNavState, setGlobalNavState] = useState<any>(null);

  const userAdministration = useApiCall({
    api: `/admin/administration?upn=${clientProfile?.userPrincipalName}`,
    name: ["userAdministrationFetch"],
    options: {
      enabled: !!clientProfile?.userPrincipalName,
      staleTime: Infinity
    }
  });

  useItems({
    types: ["JOURNEY"],
    name: "journeycount",
    fullFetch: true,
    page: 1,
    size: 20,
    status: ["DISPLAY", "VIEW"],
    options: {
      enabled: !!userState?.userPrincipalName,
      staleTime: Infinity,
      onSuccess: (results: any) => {
        //console.log("Journey Count Results", results);

        const journeyStateData = setJourneyStateData(results.items);
        setJourneyState((prev) => ({
          ...prev,
          ...journeyStateData
        }));

        // const journeyStats = results.items.map((journey: ItemUserJourney) => {
        //   return {
        //     id: journey.id,
        //     dismissable: journey.item.item_data.dismissable,
        //     stats: journeyGetStats(journey.item)
        //   };
        // });

        // console.info(journeyStats);
        // const nonDismissableCount = journeyStats.filter(
        //   (jstat: any) => !jstat.dismissable
        // );

        // console.info("nonDismissableCount", nonDismissableCount.length);
        //console.log(journeyStateData);

        // setJourneyState((prev) => ({
        //   ...prev,

        //   count: results.total,
        //   dismissable: nonDismissableCount.length === 0,
        //   dismissed: false
        // }));
      }
    }
  });

  const preferences = usePreferences({
    options: { fetchOptions: { staleTime: Infinity, enabled: false } }
  });

  // //console.log("preferences", preferences.getPreference("admin_default_level"));

  // useEffect(() => {
  //   if (preferences.prefsObject)
  //     console.log(
  //       "useApp preferences.userPreferences",
  //       preferences.prefsObject
  //     );
  //   //preferences.setPreference("admin_default_level", "CCCCCC");
  // }, [preferences.prefsObject]);

  /**
   * Run Only once when App mounts.
   */

  useEffect(() => {
    // console.info(
    //   clientProfile,
    //   fetchingClient,
    //   userAdministration.isInitialLoading
    // );
    if (
      clientProfile &&
      !fetchingClient &&
      !userAdministration.isInitialLoading
    ) {
      let isAdmin = clientProfile.sysAdmin || false;
      if (
        userAdministration?.data?.data &&
        userAdministration?.data?.data.length > 0
      ) {
        const adminData = userAdministration?.data?.data[0];
        const active = adminData.status === "ACTIVE";
        const deleted = adminData.deleted_flag;
        const delegation = adminData.admin_delegation;
        const role = adminData.system_role;
        if (delegation && delegation.length > 0) {
          const levels = delegation
            .map((del: AdminDelegation) => {
              const currentDate = new Date();
              const startDate = new Date(del.start_date);
              const endDate = new Date(del.end_date);

              if (startDate < currentDate && endDate > currentDate) {
                return del.delegated_clevel;
              }
              return undefined;
            })
            .filter((del: string | undefined) => !!del);
          const hasActiveDelegations = levels.length > 0;
          const currentAdmin = active && !deleted && hasActiveDelegations;
          setUserAdminState({
            isAdmin: currentAdmin,
            isSysAdmin:
              clientProfile?.sysAdmin || (currentAdmin && role === "SYSTEM"),
            allLevels: levels,
            accessLevel: role,
            currentLevel: hasActiveDelegations ? levels[0] : "",
            adminDetails: userAdministration.data.data
          });
        }
      }
      setUserState({ ...clientProfile, depAdmin: isAdmin });
      if (clientProfile?.sysAdmin) {
        setUserAdminState((prev) => ({
          ...prev,
          isAdmin: true,
          isSysAdmin: true
        }));
      }
      const globalValues = {
        environment: inTeams ? "teams" : "web",
        signedIn: true,
        displayWelcome: displayWelcome,
        isSysAdmin: false, //clientProfile?.sysAdmin || false,
        isDepAdmin: false, //clientProfile?.depAdmin || false,
        currentDepartment: "" //clientProfile?.sysAdmin ? "Global" : "ITMS"
        // isSysAdmin: clientProfile?.sysAdmin || false,
        // isDepAdmin: isAdmin, //clientProfile?.depAdmin || false,
        // currentDepartment: clientProfile?.sysAdmin ? "Global" : "ITMS"
      };

      setGlobalState(globalValues);
      setLoading(false);
    }
  }, [
    clientProfile,
    inTeams,
    fetchingClient,
    userAdministration.isInitialLoading,
    userAdministration.data?.data
  ]);

  /**
   * Get and user profile if there isn't any user details.
   */

  useEffect(() => {
    if (userState) {
      setLoading(false);
      preferences.fetch.refetch();
    } else {
      setClientProfile();
    }
  }, [userState]);

  useEffect(() => {
    if (!clientProfile && !fetchingClient) {
      setClientProfile();
    }
  }, [clientProfile, fetchingClient]);

  /**
   * Perform Login if user is not Authenticated and the user is not in teams.
   */
  useEffect(() => {
    // console.log("useApp instance", instance, inProgress, isAuthenticated);

    if (inTeams === false && inProgress === "none" && !isAuthenticated) {
      console.log("Not in Teams and not logged in. Do a login");
      //instance.loginRedirect();
      // {
      //   scopes: ["api://0de5c018-c659-434a-9120-ff200d5eadb0/Invoke"]
      // }
    }
  }, [inTeams, inProgress, isAuthenticated, instance]);

  return {
    globalState,
    isAuthenticated,
    //welcomeCount,
    loading,
    fetchingClient,
    inProgress,
    displayWelcome,
    user,
    setUser,
    globalNavState,
    setGlobalNavState,
    journeyState
  };
};

export default useApp;
