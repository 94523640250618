import { Box, Typography } from "@mui/material";
import { DisplayHtml } from "../../../../../components";
import JourneyGroupReadOnly from "./JourneyGroupReadOnly";

const JourneyReadOnly = ({
  item
}: {
  item: ItemCompleteFormProps | ItemCompleteProps;
}) => {
  return (
    <>
      <Box>
        <Typography variant="overline">Journey Title:</Typography>
        <Box sx={{ padding: "1em" }}>{item.item_data.title}</Box>
      </Box>
      <Box>
        <Typography variant="overline">Journey Description:</Typography>
        <Box sx={{ padding: "1em" }}>
          <DisplayHtml html={item.item_data.description} />
        </Box>
      </Box>
      <Box>
        <Typography variant="overline">Journey Groups and Items:</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          {item.item_data.groups.map((group: ItemJourneyGroupProps) => (
            <JourneyGroupReadOnly key={group.id} group={group} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default JourneyReadOnly;
