import { useAtomValue } from "jotai";
import { env } from "process";
import { useEffect, useState } from "react";

import { userAtom } from "../../globalstates";
import { createGuid } from "../../utils";

interface IEcentreTasks {
  upn: string;
  tasks: ItemEcentreData[];
}

interface IEcentreTask {
  type: string;
  inductionItemName: string;
  daysLeft: number;
  endDate: string;
  status: string;
  link: string;
}

const x_correlation_id = process.env
  .REACT_APP_ECENTRE_TASKS_X_CORRELATION_ID as string;

const btoaval = `${process.env.REACT_APP_ECENTRE_TASKS_CLIENT_ID}:${process.env.REACT_APP_ECENTRE_TASKS_CLIENT_SECRET}`;

const idmurl = process.env.REACT_APP_ECENTRE_TASKS_API_URL;

/**
 * Hook for all ecentre items for current user
 * @param defaultUser
 * @returns
 */
export default function useEcentreTasks(
  defaultUser: string | null
): [IEcentreTasks | undefined, (content: any) => void] {
  const [ecentreItems, setEcentreItems] = useState<IEcentreTasks>();

  const userState = useAtomValue(userAtom);

  const handleFetch = () => {
    const path = `${idmurl}/Task/${userState?.userPrincipalName}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mode: "cors",
        "interface-id": "test",
        "x-correlation-id": x_correlation_id, //"6be44f91-9ad4-4fc4-b778-6c866182bcb3",
        Authorization: "Basic " + btoa(btoaval)
        //"22b07897c6f34ca7aead731cc5959d2a:09A80F193e6944859bD6dBC5CD3E9f4e"
      }
    };
    fetch(path, options)
      .then((res) => res.json())
      .then((res) => {
        console.log("Ecentre Response", res);
        setEcentreItems({
          upn: res.upn,
          tasks: res.tasks.map((task: IEcentreTask) => {
            return {
              id: createGuid(),
              induction_item_name: task.inductionItemName,
              link: task.link,
              days_left: task.daysLeft,
              status: task.status,
              type: task.type,
              end_date: task.endDate
            };
          })
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return [ecentreItems, handleFetch];
}
