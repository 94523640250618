import { SpeedDial, SpeedDialAction, SxProps } from "@mui/material";
import { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import { useAtom } from "jotai";
import mainPageAtom, { mainPageAtomDefault } from "./mainPageAtom";
import { ItemFilterDialogForm } from "../../../items/components";

interface MainPageSpeedDialProps {
  sx?: SxProps;
}

const MainPageSpeedDial = (props: MainPageSpeedDialProps) => {
  const [mainPageState, setMainPageState] = useAtom(mainPageAtom);
  const [openFilter, setOpenFilter] = useState(false);
  const { sx = {} } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const actions = [
    {
      icon: <FilterListIcon />,
      name: "Filter/Sort",
      onClick: () => {
        // setMainPageState((prev) => {
        //   return { ...prev, filter: { itemTypes: ["VIDEO"] } };
        // });
        setOpenFilter(true);
      }
    }
    // { icon: <SortIcon />, name: "Sort" }
  ];

  return (
    <>
      <SpeedDial
        ariaLabel="Filter"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        icon={<FilterListIcon />}
        direction="left"
        sx={{ position: "sticky", top: "30px", ...sx }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            // tooltipOpen
            // tooltipPlacement="bottom"
            onClick={() => {
              action.onClick?.();
            }}
          />
        ))}
      </SpeedDial>
      <ItemFilterDialogForm
        value={{ filter: mainPageState.filter, sort: mainPageState.sort }}
        open={openFilter}
        onClose={() => {
          setOpenFilter(false);
        }}
        onSubmit={(val) => {
          //console.log("submit filter", val);

          setMainPageState((prev) => {
            return {
              ...prev,
              sort: val.sort,
              filtering: true,
              filter: val.filter
            };
          });

          setOpenFilter(false);
        }}
        onReset={() => {
          setMainPageState(mainPageAtomDefault);
        }}
      />
    </>
  );
};

export default MainPageSpeedDial;
