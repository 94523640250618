import { isEmpty } from "lodash";

interface ItemValidationObject {
  id: boolean;
  item_title: boolean;
  item_description: boolean;
  administration_level?: boolean;
  item_type: boolean;
  status: boolean;
}

export const itemValidator = (
  item: ItemCompleteProps | ItemCompleteFormProps
) => {
  const validatedItem = itemObjectValidator(item);

  const isValid =
    validatedItem.administration_level &&
    validatedItem.id &&
    validatedItem.item_description &&
    validatedItem.item_type &&
    validatedItem.status &&
    validatedItem.item_title;

  return isValid;
};

export const itemObjectValidator = (
  item: ItemCompleteProps | ItemCompleteFormProps
) => {
  const isValidId = !isEmpty(item.id.toString());
  const isValidTitle = !isEmpty(item.item_title);
  const isValidAdminLevel = !isEmpty(item.administrative_level);
  const isValidDescription = !isEmpty(item.item_description);
  const isValidItemType = !isEmpty(item.item_type);
  const isValidStatus = !isEmpty(item.status);

  const validObj: ItemValidationObject = {
    id: isValidId,
    item_title: isValidTitle,
    item_description: isValidDescription,
    administration_level: isValidAdminLevel,
    item_type: isValidItemType,
    status: isValidStatus
  };
  return validObj;
};

export const ItemValidation = {
  item: itemValidator,
  itemObj: itemObjectValidator
};

export default ItemValidation;
