import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { RTEditor } from "../../../../../components";
import MuiIcon from "../../../../../components/icons";
import MuiIconSearch from "../../../../../components/icons/MuiIconSearch";
import MuiIconSelectorDialog from "../../../../../components/icons/MuiIconSelectorDialog";
import useJourneyGroupDialogForm from "./useJourneyGroupDialogForm";

interface JourneyGroupDialogFormProps {
  value?: ItemJourneyGroupProps;
  open: boolean;
  onSave?: Function;
  onClose?: Function;
  onCancel?: Function;
}

const JourneyGroupDialogForm = (props: JourneyGroupDialogFormProps) => {
  const { value, open, onSave, onCancel, onClose } = props;

  const [openIcon, setOpenIcon] = useState(false);

  const { formValue, updateFormValue, isValid, validFields } =
    useJourneyGroupDialogForm(value);

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFormValue({ title: event.target.value });
  };

  const handleDescriptionChange = (val: string) => {
    updateFormValue({ description: val });
  };

  const handleIconChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFormValue({ icon: event.target.value });
  };

  const handleRequiredChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFormValue({ required: event.target.checked });
  };

  const handleSecondaryDescriptionChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    updateFormValue({ secondary_description: event.target.value });
  };

  const handleOnSave = () => {
    onSave?.(formValue);
  };

  useEffect(() => {
    if (value) updateFormValue(value);
  }, [value]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose?.();
      }}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>Create/Edit Journey Group</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            paddingTop: "1rem"
          }}
        >
          <Box>
            <Typography variant="overline">Group Title *</Typography>
            <TextField
              fullWidth
              error={!validFields.title}
              value={formValue.title}
              onChange={handleTitleChange}
            />
          </Box>
          <Box>
            <Typography variant="overline">Description *</Typography>
            <RTEditor
              error={!validFields.description}
              initialValue={formValue.description}
              onChange={handleDescriptionChange}
            />
          </Box>
          <Box>
            <Typography variant="overline">Required?</Typography>
            <Switch
              checked={formValue.required}
              onChange={handleRequiredChange}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="overline">Icon Name</Typography>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Paper
                variant="outlined"
                sx={{ display: "flex", gap: "1rem", padding: "0.5rem" }}
              >
                {formValue.icon}
                {formValue.icon && <MuiIcon iconName={formValue.icon} />}
              </Paper>
              {/* <TextField value={formValue.icon} onChange={handleIconChange} /> */}
              {/* {formValue.icon && <MuiIcon iconName={formValue.icon} />}
              <a
                target="_blank"
                href="https://mui.com/material-ui/material-icons/"
              >
                Icon Names
              </a> */}
              <Tooltip title="Search for an Icon">
                <IconButton
                  onClick={() => {
                    setOpenIcon(true);
                  }}
                >
                  <MuiIcon iconName="Search" />
                </IconButton>
              </Tooltip>
              <MuiIconSelectorDialog
                iconLimit={50}
                open={openIcon}
                onSelect={(val) => {
                  updateFormValue({ icon: val });
                  setOpenIcon(false);
                }}
                onClose={() => setOpenIcon(false)}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="overline">Secondary Description</Typography>
            <TextField
              fullWidth
              //error={!validFields.title}
              value={formValue.secondary_description}
              onChange={handleSecondaryDescriptionChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ marginRight: "auto" }}
          onClick={() => {
            onCancel?.();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" disabled={!isValid} onClick={handleOnSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default JourneyGroupDialogForm;
