import { ErrorBoundary } from "../../../../../components";

interface RuleDependentDisplayProps {
  value: RuleDependent;
}

const RuleDependentDisplay = (props: RuleDependentDisplayProps) => {
  return (
    <ErrorBoundary>
      <>
        Dependant - Display when users item id {props?.value?.item_id} status{" "}
        {props?.value?.operator_type} {props?.value?.status}.
      </>
    </ErrorBoundary>
  );
};

export default RuleDependentDisplay;
