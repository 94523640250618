import { ItemCard } from "../../../../item";

import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
import { useState } from "react";
import { DisplayHtml } from "../../../../../../components";
import CloseIcon from "@mui/icons-material/Close";
import ItemEcentreDataDisplay from "./ItemEcentreDataDisplay";

interface EcentreItemCardProps {
  userItem: ItemUserProps;
  onItemActionPerformed?: (success: boolean) => void;
}

const EcentreItemCard = (props: EcentreItemCardProps) => {
  const { userItem, onItemActionPerformed } = props;
  const [open, setOpen] = useState(false);

  const item = userItem?.item;
  const item_data: ItemEcentreData = item?.item_data;

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = () => {
    setOpen(false);
    onItemActionPerformed?.(true);
  };

  return (
    <ItemCard
      title={item_data?.induction_item_name}
      userItem={userItem}
      actions={
        <Button
          sx={{ marginLeft: "auto" }}
          variant="text"
          //color="secondary"
          endIcon={<SettingsOverscanIcon />}
          onClick={() => {
            setOpen(true);
          }}
        >
          Open
        </Button>
      }
    >
      <DisplayHtml html={item_data?.induction_item_name} />

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{ sx: { minHeight: "50vh" } }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography>{item_data?.induction_item_name}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ItemEcentreDataDisplay item_data={item_data} />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ marginLeft: "auto" }}
            variant="outlined"
            //color="secondary"
            href={item_data?.link}
            target="_blank"
            onClick={handleAction}
          >
            Open In Ecentre
          </Button>
        </DialogActions>
      </Dialog>
    </ItemCard>
  );
};

export default EcentreItemCard;
