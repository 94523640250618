import { useGraphClient } from "../../../../../hooks";
import { format as dateFormat } from "date-fns";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import useGraphUser from "../../../../../hooks/useGraphUser";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton
} from "@mui/material";

interface MeetingItemCardFormProps {
  data: ItemMeetingData;
  onSelectedTime?: (
    selected: boolean,
    targetUserDetails: any,
    selectedTime: any
  ) => void;
}

const MeetingItemCardForm = (props: MeetingItemCardFormProps) => {
  const { data, onSelectedTime } = props;
  const [client, fetchingClient] = useGraphClient("/me/findMeetingTimes");

  const [personProfile, fetchingPerson, refetchPerson] = useGraphUser(
    data.target_value,
    true
  );

  const [meetingTimes, setMeetingTimes] = useState([]);
  const [selectedMeetingtime, setSelectedMeetingTime] = useState<any>();
  const [selectedMeetingIndex, setSelectedMeetingIndex] = useState(-1);
  const [fetchingTimes, setFetchingTimes] = useState(true);

  //   useEffect(() => {
  //     console.log("Target Person Profile", personProfile);
  //   }, [personProfile]);

  useEffect(() => {
    if (client && data.target_value && personProfile) {
      const startDate: Date = new Date();

      const endDate: Date = new Date();
      endDate.setDate(startDate.getDate() + 5);

      const opts = {
        attendees: [
          {
            emailAddress: {
              address: data.target_value || null,
              name: personProfile?.displayName
            },
            type: "Required"
          }
        ],
        timeConstraint: {
          activityDomain: "work",
          timeslots: [
            {
              start: {
                dateTime: startDate.toISOString(), //"2022-01-28T01:45:01.961Z",
                timeZone: "AUS Central Standard Time"
              },
              end: {
                dateTime: endDate.toISOString(), //"2022-02-04T01:45:01.961Z",
                timeZone: "AUS Central Standard Time"
              }
            }
          ]
        },
        meetingDuration: data.duration
      };
      let val = client.post(opts);
      val
        .then((res: any) => {
          //console.log(res);
          setMeetingTimes(res.meetingTimeSuggestions);
          setFetchingTimes(false);
        })
        .catch((err: any) => {
          console.log(err);

          setFetchingTimes(false);
        });
    }
    if (!data.target_value) {
      setFetchingTimes(false);
    }
  }, [client, fetchingClient, personProfile]);

  return (
    <>
      {fetchingTimes ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.4rem",
            paddingTop: "0.5rem"
          }}
        >
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
        </Box>
      ) : (
        <List>
          {meetingTimes.map((meetingTime: any, i) => {
            const dateString = meetingTime.meetingTimeSlot.start.dateTime + "Z";
            //console.log(dateString);
            const meetingDate = new Date(dateString);
            //console.log(meetingDate);
            const formatedDate = dateFormat(meetingDate, "PPPP 'at' pp");
            return (
              <ListItem
                key={dateString}
                secondaryAction={
                  <>{i === selectedMeetingIndex && <CheckIcon />}</>
                }
                dense
              >
                <ListItemButton
                  disableRipple
                  role={undefined}
                  onClick={() => {
                    if (selectedMeetingIndex != i) {
                      setSelectedMeetingTime(meetingTime);
                      setSelectedMeetingIndex(i);
                      onSelectedTime?.(true, personProfile, meetingTime);
                    } else {
                      setSelectedMeetingIndex(-1);
                      setSelectedMeetingTime(null);
                      onSelectedTime?.(false, personProfile, null);
                    }
                  }}
                  selected={i === selectedMeetingIndex}
                >
                  <ListItemText>{formatedDate}</ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
};

export default MeetingItemCardForm;
