import React, { PropsWithChildren } from "react";

interface IErrorDisplay {
  error: string;
}

/**
 * Used to display Errors inline. Should be used to display custom error codes that can be used to resolve issues.
 * @param props IErrorDisplay
 * @returns JSX Element
 */
export default function ErrorDisplay(props: PropsWithChildren<IErrorDisplay>) {
  return <>{props.error}</>;
}
