import { atom } from "jotai";

interface userAdminProps {
  isAdmin: boolean;
  isSysAdmin?: boolean;
  accessLevel?: string;
  allLevels?: string[];
  currentLevel?: string;
  adminDetails?: Administrator;
}

const userAdminAtom = atom<userAdminProps>({ isAdmin: false });

export default userAdminAtom;
