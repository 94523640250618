import { createGuid } from ".";

const createEmptyRule = () => {
  const newEmpty: RuleFormItem | Rule = {
    id: createGuid(),
    rule_type: "",
    operation: "",
    rule_data: null,
    priority: 0,
    visible: true
  };

  return newEmpty;
};

export default createEmptyRule;
