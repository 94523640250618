import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from "@mui/material";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { ITEMTYPES, LookupEnum } from "../../../../../types/enums";
import { ItemIcon } from "../../ui";
import ItemStatusFilterForm from "./ItemStatusFilterForm";
import ItemTypeFilterForm from "./ItemTypeFilterForm";

export interface ItemFilterFormValueProps {
  itemTypes?: string[];
  statuses?: string[];
}

interface ItemFilterFormProps {
  onChange?: (value?: ItemFilterFormValueProps) => void;
  value?: ItemFilterFormValueProps;
}

const defaultValue = {
  itemTypes: ["INFORMATION", "VIDEO", "MEETING", "ECENTRE"],
  statuses: ["DISPLAY", "VIEW"]
};

const ItemFilterForm = (props: ItemFilterFormProps) => {
  const { value = defaultValue, onChange } = props;

  const { itemTypes, statuses } = value;

  const [filters, setFilters] = useState<ItemFilterFormValueProps | undefined>(
    value
  );

  useEffect(() => {
    //console.log("Item Form Filters, filters ", filters);

    onChange?.(filters);
  }, [filters]);

  // useEffect(() => {
  //   console.log("Filters changed", value);
  //   setFilters(value);
  // }, [value]);
  useEffect(() => {
    //console.log("Filters changed props.value", props.value);
    if (!isEqual(props.value, filters)) setFilters(props.value);
  }, [props.value]);

  return (
    <Box sx={{ display: "flex", gap: "5rem" }}>
      <Box>
        <Typography variant="overline">Item Types</Typography>
        <ItemTypeFilterForm
          onChange={(vals) => {
            //console.log("ItemTypeFilterForm onChange vals", vals);
            setFilters((prevState) => {
              return { ...prevState, itemTypes: vals };
            });
          }}
          value={value.itemTypes}
        />
      </Box>
      <Box>
        <Typography variant="overline">Status</Typography>
        <ItemStatusFilterForm
          onChange={(vals) => {
            setFilters((prevState) => {
              return { ...prevState, statuses: vals };
            });
          }}
          value={value.statuses}
        />
      </Box>
    </Box>
  );
};

export default ItemFilterForm;
