import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface IFormItem {
  label?: string;
  labelPosition?: "top" | "left";
}

/*const formItemStyle = {
  borderBottom: "solid 1px #cfe6ff",
  borderRadius: "4px",
  borderLeft: "solid 4px #cfe6ff",
  borderRight: "none",
  borderTop: "solid 1px #F8f8f8",
  padding: "5px 10px",
  marginBottom: "4px",
};*/

const formItemStyle = {
  padding: "5px 10px",
  marginBottom: "4px",
};

export default function FormItem(props: PropsWithChildren<IFormItem>) {
  return (
    <Box
      display="flex" //{props.labelPosition === "left" ? "block" : "flex"}
      flexDirection={props.labelPosition === "left" ? "row" : "column"}
      //alignItems="stretch"
      sx={formItemStyle}
      //alignItems="center"
      className="formItem"
    >
      <Typography
        variant="inherit"
        sx={{ fontWeight: "bolder", paddingRight: "4px" }}
      >
        {props.label ? props.label + ":" : ""}
      </Typography>
      {props.children}
    </Box>
  );
}
