import { format, formatISO, parse } from "date-fns";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { userAtom } from "../../globalstates";
import { createGuid } from "../../utils";
import useApiCall from "./useApiCall";

interface ItemUserEcentreProps extends ItemUserProps {
  item: ItemEcentreProps;
}

export default function useItemsEcentre() {
  const user = useAtomValue(userAtom);

  const { isLoading, error, data, isFetching, refetch, isError, isSuccess } =
    useApiCall({
      name: ["itemsEcentre"],
      api: `/mulesoft/ecentretask/${user?.userPrincipalName}`
    });

  const [items, setItems] = useState<ItemUserEcentreProps[]>([]);

  useEffect(() => {
    if (!isLoading && isSuccess && data?.data) {
      const ecentreUserItems: ItemUserEcentreProps[] = data.data.tasks.map(
        (eItem: ItemEcentreData) => {
          const itemId = +eItem.link.substring(
            eItem.link.lastIndexOf(":") + 1,
            eItem.link.lastIndexOf(",")
          );
          const newItem: ItemUserEcentreProps = {
            id: createGuid(),
            status: "",
            upn: data.data.upn,
            item_id: itemId,
            last_updated_timestamp: "",
            created_date: "",
            due_date: !!eItem.end_date
              ? formatISO(parse(eItem.end_date, "dd/MMM/yy", new Date()))
              : formatISO(parse("9999-12-31", "yyyy-MM-dd", new Date())),
            item: {
              id: itemId,
              item_type: "ECENTRE",
              item_description: eItem.induction_item_name,
              item_title: eItem.induction_item_name,
              item_data: eItem,
              due_period: !!eItem.end_date
                ? format(
                    parse(eItem.end_date, "dd/MMM/yy", new Date()),
                    "yyyy-MM-dd"
                  )
                : "9999-12-31",
              status: "INCOMPLETE"
            }
          };
          return newItem;
        }
      );
      //console.log("ecentreUserItems", ecentreUserItems);
      setItems(ecentreUserItems);
    }
  }, [isLoading, data, isSuccess]);

  // useEffect(() => {
  //   console.log("ecentre Fetch isSuccess", isSuccess);
  // }, [isSuccess]);

  return { items, refetch, isLoading, error, isFetching };
}
