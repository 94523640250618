import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface NotFoundProps {
  path?: string;
  hideButton?: boolean;
  subPage?: boolean;
}

export default function NotFound(props: NotFoundProps) {
  const { path = "/", hideButton = false, subPage = false } = props;
  const nav = useNavigate();
  const handleNav = () => {
    nav(path);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",

        alignItems: "center",
        height: subPage ? "calc( 100vh - 64px )" : "100vh",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ fontSize: "10rem" }}>404</Box>
        <Box>
          <p>Page Not Found</p>
        </Box>
        {!hideButton && (
          <Button variant="contained" onClick={handleNav}>
            Return Home
          </Button>
        )}
      </Box>
    </Box>
  );
}
