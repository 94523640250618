import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

type TextFieldDebounceProps = Omit<TextFieldProps, "onChange" | "value"> & {
  debounceTime?: number;
  onChange?: (value: string) => void;
  value: string;
};

const TextFieldDebounce = (props: TextFieldDebounceProps) => {
  const { debounceTime = 500, onChange, value, ...textFieldProps } = props;
  const [formValue, setFormValue] = useState<string>(value);
  const [debouncedValue] = useDebounce(formValue, debounceTime);
  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormValue(event.target.value);
  };

  useEffect(() => {
    if (typeof debouncedValue === "string") onChange?.(debouncedValue);
  }, [debouncedValue]);

  return (
    <TextField
      {...textFieldProps}
      onChange={handleOnChange}
      value={formValue}
    />
  );
};

export default TextFieldDebounce;
