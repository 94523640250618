/**
 * Replaces # strings in a text with the values from the logged in user.
 * Valid Replacement Values are :
 * #USER# #EMAIL# #UPN# #GIVENNAME# #SURNAME#
 * @param text The text which is to have values replaced into it. Replaced strings are in the format #PROP# e.g #USER# #EMAIL#
 * @param userContext The Global user Context. This contains the values to be inserted into the text string
 * @returns The text value with the # strings replaced with the actual value of the logged in user.
 */
export default function replaceUserValues(
  text: string,
  user: User | null | undefined
) {
  let replacedtext = text;

  if (user) {
    const email = user?.mail;
    const userName = user?.displayName;
    const upn = user?.userPrincipalName;
    const givenName = user?.givenName;
    const surname = user?.surname;

    replacedtext = replaceAllArray(
      replacedtext,
      ["#USER#", "#EMAIL#", "#UPN#", "#GIVENNAME#", "#SURNAME#"],
      [userName, email, upn, givenName, surname]
    );
  }
  return replacedtext;
}

export function replaceAllArray(
  text: string,
  matcharray: string[],
  valuesarray: string[]
) {
  let replacedText = text;
  if (matcharray.length > 0 && matcharray.length === valuesarray.length) {
    matcharray.forEach((item, index) => {
      replacedText = replacedText.replaceAll(item, valuesarray[index]);
    });
  }
  return replacedText;
}
