import { Box } from "@mui/material";

export default function ItemEcentreDataDisplay({
  item_data
}: {
  item_data: ItemEcentreData;
}) {
  const {
    //description,
    id,
    status,
    days_left,
    end_date,
    induction_item_name,
    link
  } = item_data;
  //console.log("Props", props);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>Open the item in eCentre to complete.</Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column"
        }}
      >
        <Box>
          Name: <b>{induction_item_name}</b>
        </Box>
        <Box>
          Status: <b>{status}</b>
        </Box>
        <Box>
          Days Left to Complete : <b>{days_left}</b>
        </Box>
      </Box>
    </Box>
  );
}
