import { useRef, useState, useEffect, ChangeEvent } from "react";
import { Autocomplete, TextField } from "@mui/material";
import useGraph from "../../../../hooks/useGraph";
import useAutocompletePerson from "./useAutocompletePerson";

interface IAutoCompletePerson {
  onChange?: Function;
  value?: any;
  returnFormat?: (person: User) => {};
  onClear?: () => void;
  fullWidth?: boolean;
  error?: boolean;
}

let timer: any;
const typeTimeout = 1000;

export default function AutocompletePerson(props: IAutoCompletePerson) {
  const {
    onChange,
    value,
    returnFormat = undefined,
    onClear,
    fullWidth,
    error
  } = props;

  const formatter = (user: any) => {
    return {
      label: `${user.displayName} (${user.userPrincipalName})`,
      value: user.userPrincipalName
    };
  };

  // const {
  //   people,
  //   searchValue,
  //   setSearchValue,
  //   searchValues,
  //   setSearchValues,
  //   selectedValue,
  //   setSelectedValue,
  //   fetching: fetchingUsers,
  //   setValue,
  //   returnValue
  // } = useAutocompletePerson({
  //   initialValue: value,
  //   returnValueFormat: returnFormat,
  //   onSuccess: (res: any) => {
  //     console.log("Fetch Complete", res);
  //   }
  // });
  const {
    searchResults: searchValues,
    search: setSearchValue,
    selectedValue,
    returnValue,
    fetching: fetchingUsers,
    setSelected
  } = useAutocompletePerson({
    initialValue: value,
    returnValueFormat: returnFormat,
    onSuccess: (res: any) => {
      //console.log("Fetch Complete", res);
    }
  });

  function handleSearchField(event: any, val: string) {
    setSearchValue(val);
  }

  const handleOnChange = (val: any) => {
    onChange?.(val);
  };

  // useEffect(() => {
  //   console.log("searchValues", searchValues);
  // }, [searchValues]);

  // useEffect(() => {
  //   console.log("selectedValue", selectedValue);
  // }, [selectedValue]);

  // useEffect(() => {
  //   console.log("returnValue", returnValue);
  // }, [returnValue]);

  useEffect(() => {
    if (value) {
      //console.log("init value", value);
      setSearchValue(value);
    }
  }, [value]);

  useEffect(() => {
    //console.log(selectedValue, returnValue, returnFormat);
    onChange?.(returnFormat ? returnValue : selectedValue);
  }, [returnValue, selectedValue]);

  return (
    <>
      <Autocomplete
        fullWidth={fullWidth}
        id="auto-complete-person"
        options={searchValues}
        //ref={searchField}
        value={selectedValue}
        getOptionLabel={(option: { label: string; value: string }) =>
          option.label
        }
        loading={fetchingUsers}
        isOptionEqualToValue={(
          option: { label: string; value: string },
          value: { label: string; value: string }
        ) => option.value === value.value}
        onChange={(event, val, reason) => {
          if (reason === "selectOption") {
            //console.info("AutocompletePerson val", val);
            setSelected(val);
          } //console.log(val); //setValue(val);
          if (reason === "clear") {
            setSelected(null);
            //setValue(null);
            onClear?.();
          }
        }}
        onSelect={(e) => {
          //console.log("onSelect", e);
        }}
        onInputChange={handleSearchField}
        renderInput={(params) => (
          <TextField {...params} error={error} label="Person" />
        )}
      />
    </>
  );
}
