import { createTheme } from "@mui/material/styles";

/**
 * Primarty MUI Theme overrides to customise the application.
 */
export const ClientTheme = createTheme({
  /* palette: {
    //type: "light",
    primary: {
      main: "#33691e",
    },
    secondary: {
      main: "#26c6da",
    },
    background: {
      default: "#eeeeee",
    },
    whitePrimary: {
      main: "#fff",
      contrastText: "#333",
    },
  },*/

  palette: {
    primary: {
      main: "#00917D",
    },
    background: {
      default: "inherit",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      .ClientContainer{
        background-color:#f6f3ee;
        background: radial-gradient(circle,rgb(245, 252, 255) 80%,rgb(201, 230, 255) 120%);
      }
      `,
      // styleOverrides: {
      //   body: {
      //     backgroundColor: "red",
      //     background:
      //       "linear-gradient(0deg, rgba(243,237,247,1) 15%, rgba(226,228,247,1) 91%)",
      //   },
      // },
    },
  },
  // palette: {
  //   background: {
  //     default:
  //       "linear-gradient(0deg, rgba(243,237,247,1) 15%, rgba(226,228,247,1) 91%)",
  //   },
  //   whitePrimary: {
  //     main: "#FFF",
  //     contrastText: "#000",
  //   },
  // },
  // typography: {
  //   regionheader: {
  //     fontWeight: "bolder",
  //     fontSize: "1.1em",
  //     opacity: "0.6",
  //   },
  // },
  // components: {
  //   MuiListItemButton: {
  //     styleOverrides: {
  //       root: {
  //         "&.Mui-selected": {
  //           backgroundColor: "rgba(0, 166, 221, 0.2)", //"rgba(8, 0, 255, 0.2)",
  //         },
  //         ":hover": {
  //           backgroundColor: "rgba(0, 166, 2215, 0.1)",
  //         },
  //         "&.Mui-selected:hover": {
  //           backgroundColor: "rgba(255, 184, 203, 0.4)",
  //         },
  //       },
  //     },
  //   },
  //   MuiPaper: {
  //     defaultProps: {
  //       elevation: 3,
  //     },
  //     styleOverrides: {
  //       root: {
  //         // "&.MuiPaper-rounded": { borderRadius: "0.4em" },
  //         // "&.MuiPaper-elevation3": {
  //         //   boxShadow: "0px 3px 3px 0px rgba(0, 166, 221, 0.4)",
  //         // },
  //       },
  //     },
  //   },
  // },
});

export default ClientTheme;
