import { OPERATIONTYPES } from "../../../types/enums";

const audiencePrepareForSubmit = (audience: AudienceFormItem) => {
  let audiencePreparedForSubmit: AudienceSubmittable | undefined = undefined;
  if (audience.operation === OPERATIONTYPES.CREATE.KEY) {
    audiencePreparedForSubmit = { ...audience };
    delete audiencePreparedForSubmit["isNew"];
    delete audiencePreparedForSubmit["id"];
    delete audiencePreparedForSubmit["item_id"];
  }

  if (audience.operation === OPERATIONTYPES.UPDATE.KEY) {
    audiencePreparedForSubmit = { ...audience };
    delete audiencePreparedForSubmit["isNew"];
    delete audiencePreparedForSubmit["audience_type"];
    delete audiencePreparedForSubmit["item_id"];
  }

  if (audience.operation === OPERATIONTYPES.DELETE.KEY) {
    audiencePreparedForSubmit = {
      id: audience.id,
      operation: audience.operation
    };
  }

  return audiencePreparedForSubmit;
};

export default audiencePrepareForSubmit;
