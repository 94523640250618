import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Switch
} from "@mui/material";
import { USERSTATUS } from "../../../../types/enums";

const selectValues = [
  { value: USERSTATUS.COMPLETE.KEY, label: USERSTATUS.COMPLETE.DISPLAY },
  { value: USERSTATUS.INCOMPLETE.KEY, label: USERSTATUS.INCOMPLETE.DISPLAY },
  { value: USERSTATUS.INPROGRESS.KEY, label: USERSTATUS.INPROGRESS.DISPLAY }
];

interface UserStatusSelectProps {
  value: string;
  onChange?: (value: string) => void;
  //selectProps?: SelectProps;//Omit<SelectProps, "onChange">;
}

const UserStatusSelector = (props: UserStatusSelectProps) => {
  const { value, onChange } = props;
  const handleChange = (event: SelectChangeEvent) => {
    onChange?.(event.target.value as string);
  };
  return (
    <FormControl fullWidth>
      <InputLabel>User Item Status</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        label="User Item Status"
        //{...selectProps}
      >
        {selectValues.map((sv) => {
          return (
            <MenuItem key={sv.value} value={sv.value}>
              {sv.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default UserStatusSelector;
