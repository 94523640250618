import {
  Box,
  Button,
  Collapse,
  CardContent,
  IconButton,
  styled,
  IconButtonProps,
  CardActions,
  Typography
} from "@mui/material";
import { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ItemCard } from "../../../item";
import { DisplayHtml } from "../../../../../components";
import ItemIcon from "../../../components/ui/ItemIcons";
import getOverdueStatus from "../../../../../utils/getOverdueStatus";
import { format } from "date-fns";
import { useUserItemSubmit } from "../../../../../hooks/dataHooks";
import { ItemCardInteractionProps } from "../../../item/components/ui";
import OverdueIcon from "../../../../../components/icons/OverdueIcon";

interface VideoItemCardProps extends ItemCardInteractionProps {}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginRight: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest
  })
}));

const VideoItemCard = (props: VideoItemCardProps) => {
  const { userItem, onItemActionPerformed } = props;
  const { isPosting, isPostingError, isPostingSuccess, postItem } =
    useUserItemSubmit({
      onSuccess: () => {
        onItemActionPerformed?.(true, "Video marked as watched");
      }
    });

  const item = userItem?.item;
  const item_data = item?.item_data;
  const [open, setOpen] = useState(false);

  const dueDate = new Date(userItem.due_date);
  const due_status =
    userItem.status === "COMPLETE"
      ? "COMPLETE"
      : getOverdueStatus(userItem.due_date);

  const readableDate = format(dueDate, "dd/MM/yyyy");

  const handleOnItemActionPerformed = () => {
    //onItemActionPerformed?.(true, "Video marked as watched");
    postItem({ id: userItem.id as number, status: "COMPLETE" });
  };

  return (
    <>
      <ItemCard
        title={item_data?.title}
        userItem={userItem}
        hideHeader
        contentSx={{ padding: "0px", "&:last-child": { paddingBottom: "0px" } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexBasis: "100%",
            alignItems: "stretch"
            //height: "100%"
          }}
        >
          <Box sx={{ textAlign: "left", height: "100%" }}>
            <Box sx={{ textAlign: "center" }}>
              {item_data?.url && item_data?.url.indexOf("embed") !== -1 && (
                <Box
                  sx={{
                    //maxWidth: "600px",
                    margin: "auto",
                    flex: "auto",
                    minHeight: "300px",
                    width: "100%"
                  }}
                >
                  <iframe
                    width="100%"
                    height="100%"
                    src={item_data?.url}
                    //title={title}
                    //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      border: "none",
                      minHeight: "inherit"
                      //borderRadius: "1rem",
                      //boxShadow: "3px 3px 7px -3px #666, -1px -3px 10px -2px #eee"
                    }}
                  ></iframe>
                </Box>
              )}

              {item_data?.url && item_data?.url.indexOf(".mp4") !== -1 && (
                <video
                  controls
                  style={{
                    //borderRadius: "1rem",
                    //maxWidth: "600px",
                    width: "100%",
                    margin: "auto"
                    //boxShadow: "3px 3px 7px -3px #666, -1px -3px 10px -2px #eee"
                  }}
                >
                  <source src={item_data?.url} type="video/mp4" />
                </video>
              )}
            </Box>
            <Box
              sx={{ padding: "1rem", display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Box>
                  <Typography variant="overline">{item_data?.title}</Typography>
                  {getOverdueStatus(userItem?.due_date) === "OVERDUE" && (
                    <OverdueIcon dueDate={userItem?.due_date} />
                  )}
                  {/* <ItemIcon name={item.item_type} size="small" /> */}
                  {/* <Box>
                    <Box sx={{ fontSize: "0.5rem" }}>{due_status}</Box>
                    {readableDate !== "31/12/9999" && (
                      <Box sx={{ fontSize: "0.6rem" }}>Due: {readableDate}</Box>
                    )}
                  </Box> */}
                </Box>
              </Box>
              <DisplayHtml html={item_data?.description} />
            </Box>
            {/* <video controls src={item_data.link} style={{ width: "100%" }} /> */}
            <CardActions>
              <>
                {item_data?.content && (
                  <ExpandMore
                    //sx={{ marginRight: "auto" }}
                    expand={open}
                    onClick={() => {
                      setOpen((prev) => !prev);
                    }}
                    aria-expanded={open}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                )}
                {userItem?.status !== "COMPLETE" && (
                  <Button
                    variant="text"
                    //color="secondary"
                    sx={{ marginLeft: "auto" }}
                    onClick={handleOnItemActionPerformed}
                  >
                    Watched
                  </Button>
                )}
              </>
            </CardActions>
            {item_data?.content && (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <CardContent>
                  <DisplayHtml html={item_data.content} />
                </CardContent>
              </Collapse>
            )}
          </Box>
        </Box>
      </ItemCard>
    </>
  );
};

export default VideoItemCard;
