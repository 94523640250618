import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, IconButton, Paper, Tooltip, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";
import { OPERATION, OPERATIONTYPES } from "../../../types/enums";

/**
 *   operation:
    | OPERATION.CREATE
    | OPERATION.DELETE
    | OPERATION.UPDATE
    | OPERATION.NONE
    | null | string;
 */
interface MultiFormDisplayProps {
  operation?: string;
  onDelete: Function;
  onRestore?: Function;
  onEdit?: Function;
  onReset?: Function;
  isNew?: boolean;
  wrapperProps?: any;
}

export default function MultiFormDisplay(
  props: PropsWithChildren<MultiFormDisplayProps>
) {
  const theme = useTheme();

  const {
    operation = OPERATIONTYPES.NONE.KEY,
    onDelete,
    onRestore,
    onEdit,
    children,
    onReset,
    isNew = false
  } = props;

  let borderColor: any;

  let bgColor: any;

  switch (operation) {
    case OPERATIONTYPES.DELETE.KEY: //OPERATION.DELETE:
      bgColor = "#ffd5d5";
      borderColor = theme.palette.error.main;
      break;
    case OPERATIONTYPES.UPDATE.KEY: //OPERATION.UPDATE:
      bgColor = "#e4f1ff";
      borderColor = theme.palette.primary.main;
      break;
    case OPERATIONTYPES.CREATE.KEY: //OPERATION.CREATE:
      borderColor = theme.palette.success.main;
      bgColor = "#efffef";
      break;
    default:
      bgColor = undefined;
      borderColor = theme.palette.primary.main;
  }

  return (
    <Box sx={{ padding: ".5rem" }} {...props.wrapperProps}>
      <Paper
        //key={audience.id}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //border: "solid 1px " + borderColor,
          backgroundColor: bgColor,
          borderRadius: "0.2rem",
          marginBottom: "0.2rem",
          padding: "0.4rem",
          position: "relative"
        }}
      >
        <Box sx={{ position: "absolute", top: -10, left: -10 }}>
          {operation === OPERATION.CREATE && (
            <AddCircleIcon sx={{ color: "green" }} />
          )}
          {operation === OPERATIONTYPES.DELETE.KEY && (
            <RemoveCircleIcon sx={{ color: "red" }} />
          )}
          {operation === OPERATION.UPDATE && (
            <ChangeCircleIcon sx={{ color: "blue" }} />
          )}
        </Box>
        {children}
        <Box>
          {onEdit && !isNew && operation != OPERATION.NONE && (
            <Tooltip title="Reset">
              <IconButton
                onClick={() => {
                  onReset?.();
                }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          )}
          {onEdit && (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  onEdit?.();
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              operation === OPERATIONTYPES.DELETE.KEY ? "Restore" : "Delete"
            }
          >
            <>
              {!!onDelete &&
                !!operation &&
                operation !== OPERATIONTYPES.DELETE.KEY && (
                  <IconButton
                    onClick={() => {
                      onDelete?.();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}

              {!!onRestore &&
                (!operation ||
                  (!!operation && operation == OPERATIONTYPES.DELETE.KEY)) && (
                  <IconButton
                    onClick={() => {
                      onRestore?.();
                    }}
                  >
                    <RestoreFromTrashIcon />
                  </IconButton>
                )}
              {/* <IconButton
              onClick={() => {
                 onDelete?.();
              }}
            >
              {operation === OPERATION.DELETE ? (
                <RestoreFromTrashIcon />
              ) : (
                <DeleteIcon />
              )}
            </IconButton> */}
            </>
          </Tooltip>
        </Box>
      </Paper>
    </Box>
  );
}
