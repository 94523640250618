import { useAtom, useAtomValue } from "jotai";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useApiCall } from ".";
import { userAtom, userPreferences } from "../../globalstates";
import useApiPatch from "./useApiPatch";
import useApiPost from "./useApiPost";

interface UsePreferencesProps {
  preferences?: string[];
  preferenceAsObject?: boolean;
  options?: {
    fetchOptions?: {};
    patchOptions?: {};
    postOptions?: {};
  };
  onPatchSuccess?: (() => void) | ((results: any) => void);
}

const usePreferences = (props?: UsePreferencesProps) => {
  const {
    preferences = [],
    preferenceAsObject = false,
    options,
    onPatchSuccess
  } = props || {
    preferences: [],
    preferenceAsObject: false,
    options: {}
  };

  const userState = useAtomValue(userAtom);

  //const [userPreferences, setPreferences] = useState<any>();
  const [userPrefs, setUserPrefs] = useAtom(userPreferences);
  //const [userPrefsObject, setUserPrefsObject] = useState<any>();
  const [preferencesError, setPreferencesError] = useState(false);

  const fetch = useApiCall({
    api: `/user/preference/${userState?.userPrincipalName}`,
    name: ["preferences"],
    //options: {{onSuccess:(results:any)=>{console.log("Fetched Results onSuccess",results)}},...{...options?.fetchOptions}}}
    options: {
      onSuccess: (results: any) => {
        //console.log("Results Returned");
        try {
          //console.log("results.data", results.data);
          if (results.data.length > 0)
            setPreferenceValues(results.data[0].value);

          if (results.data.length === 0)
            post.mutate({
              value: JSON.stringify([]),
              upn: userState?.userPrincipalName
            });
          //console.log("Fetched Results onSuccess", results.data[0].value);
        } catch (err) {
          //result = err.message; // error under useUnknownInCatchVariables
          if (typeof err === "string") {
            //console.log(err.toUpperCase()); // works, `e` narrowed to string
          } else if (err instanceof Error) {
            //err.message // works, `e` narrowed to Error
            //console.log(err.name, results);
            // post.mutate(
            //   {
            //     value: JSON.stringify({
            //       value: [],
            //       upn: userState?.userPrincipalName
            //     })
            //   },
            //   {
            //     onSuccess: (results) => {
            //       console.log("post results", results);
            //       setPreferenceValues(results.data.value);
            //       //onPatchSuccess?.(results);
            //     }
            //   }
            // );
          }
        }
      },
      ...options?.fetchOptions
    }
  });

  // useEffect(() => {
  //   console.log("atom userPrefs", userPrefs);
  // }, [userPrefs]);

  const patch = useApiPatch({
    api: (patchData) => `/user/preference/${userState?.userPrincipalName}`,

    options: {
      onSuccess: (results: any) => {
        if (results && results.data && results.data.length > 0)
          setPreferenceValues(results.data[0].value);
      }
    },
    ...options?.patchOptions
  });

  // const post = useApiPost({
  //   api: (postData) => `/user/preference/${userState?.userPrincipalName}`
  // });
  const post = useApiPost({
    api: (postData) => `/user/preference`,
    options: {
      onSuccess: (results: any) => {
        if (results && results.data && results.data.length > 0)
          setPreferenceValues(results.data[0].value);
      }
    },
    ...options?.postOptions
  });

  const setPreferenceValues = (data: string) => {
    try {
      //var preferenceData = fetch.data.data[0].value;
      //console.info("setPreferenceValues", data);
      if (data) {
        //console.info("Set with values ", data);
        const parsedPrefs = JSON.parse(data);

        setUserPrefs((prev: any) => {
          // const changed = !isEqual(prev.userPrefs, parsedPrefs);
          // console.info(
          //   "prev/parsed",
          //   changed,
          //   prev.userPrefs,
          //   parsedPrefs,
          //   convertArrayToObject(parsedPrefs)
          // );
          // if (changed) {
          //   console.log("Changed ", {
          //     userPrefs: parsedPrefs,
          //     userPrefsObj: convertArrayToObject(parsedPrefs)
          //   });
          return {
            userPrefs: parsedPrefs,
            userPrefsObj: convertArrayToObject(parsedPrefs)
          };
          //}
          //return prev;
        });
        // setUserPrefsObject((prev: any) => {
        //   const convertedPrefs = convertArrayToObject(parsedPrefs);
        //   if (!isEqual(prev, convertedPrefs)) return convertedPrefs;
        // });
      }
      setPreferencesError(false);
    } catch (err) {
      setPreferencesError(true);
    }
  };

  //   useEffect(() => {
  //     console.log("userPreferences", userPreferences);
  //   }, [userPreferences]);

  //   useEffect(() => {
  //     console.log("userPreferencesObject", userPreferencesObject);
  //   }, [userPreferencesObject]);

  //   useEffect(() => {
  //     //if (fetch.isSuccess && fetch.data) console.log(fetch.data.data);

  //     if (
  //       fetch.isSuccess &&
  //       fetch.data &&
  //       fetch.isSuccess &&
  //       fetch.data.data.length > 0 &&
  //       fetch.data.data[0].value
  //     ) {
  //       //   console.log(
  //       //     "setting user preferences value from api call",
  //       //     fetch.data.data[0].value
  //       //   );
  //       setPreferenceValues(fetch.data.data[0].value);
  //     }
  //   }, [fetch.data, fetch.isFetching, fetch.isLoading, fetch.isSuccess]);

  const getPreference = (pref: string) => {
    if (userPrefs) {
      const value = userPrefs.userPrefs.find(
        (p: { key: string; value: any }) => p.key === pref
      )?.value;

      //   let valueObj: any = {};
      //   valueObj[pref] = value;
      //   console.log(valueObj);

      return value;
    }
  };

  const setPreference = (key: string, value: any) => {
    //console.log("setPreference called", key, value, userPreferences);
    if (userPrefs) {
      let modifiedPrefs = [...userPrefs.userPrefs];
      const ind = modifiedPrefs.findIndex((pref) => pref.key === key);
      if (ind >= 0) {
        modifiedPrefs[ind].value = value;
      } else {
        modifiedPrefs.push({ key: key, value: value });
      }

      //console.log("modifiedPrefs", modifiedPrefs);
      //patch.reset();

      patch.mutate(
        { value: JSON.stringify(modifiedPrefs) },
        {
          onSuccess: (results) => {
            console.log("results", results);
            setPreferenceValues(results.data.value);
            onPatchSuccess?.(results);
          }
        }
      );
    }
  };

  //   useEffect(() => {
  //     console.info("userPreferences", userPreferences);
  //   }, [userPreferences]);

  //   useEffect(() => {
  //     console.log("patch.isSuccess", patch, patch.isIdle, patch.isSuccess);
  //     if (patch.isSuccess && patch.data && patch.data.data.value) {
  //       console.log("Patch Data", patch.data.data.value);

  //       setPreferenceValues(patch.data.data.value);
  //     }
  //   }, [patch.isSuccess, patch.data, patch.isIdle]);

  const [prefs, prefsObject] = [userPrefs.userPrefs, userPrefs.userPrefsObj];

  return {
    prefs,
    prefsObject,
    getPreference,
    setPreference,
    fetch
  };
};

export default usePreferences;

interface PreferenceProp {
  key: string;
  value: string;
}

function convertArrayToObject(arr: PreferenceProp[]) {
  let obj: any = {};
  arr.forEach((ele: PreferenceProp) => {
    obj[ele.key] = ele.value;
  });

  return obj;
}
