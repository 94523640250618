import { Box, Typography } from "@mui/material";
import { DisplayHtml } from "../../../../../components";
import { ItemInformationDataDisplay } from "../../../../../features/items/information/components/ui";
//import { ItemInformationDataDisplay } from "../../../../../items/information";

interface InformationReadOnlyProps {
  item: ItemCompleteFormProps | ItemCompleteProps;
}

export default function InformationReadOnly({
  item
}: InformationReadOnlyProps) {
  return (
    <>
      <Typography variant="overline">Display Title:</Typography>
      <Box sx={{ padding: "1em" }}>
        <DisplayHtml html={item.item_data.title} />
      </Box>
      <Typography variant="overline">Short Description:</Typography>
      <Box sx={{ padding: "1em" }}>
        <DisplayHtml html={item.item_data.description} />
      </Box>
      <Typography variant="overline">Information Content:</Typography>
      <Box sx={{ padding: "1em" }}>
        <ItemInformationDataDisplay item_data={item.item_data} />
      </Box>
    </>
  );
}
