import { differenceInDays } from "date-fns";

const getOverdueStatus = (date: string) => {
  const now = new Date();
  const dueDate = new Date(date);

  const diffInDays = differenceInDays(dueDate, now);

  if (dueDate < now) return "OVERDUE";

  const urgent = diffInDays < 7;

  const due_status = urgent ? "URGENT" : "NORMAL";

  return due_status;
};

export default getOverdueStatus;
