import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { isEqual } from "lodash";
import { equals } from "lodash/fp";
import { useEffect, useState } from "react";
import { ITEMTYPES, LookupEnum } from "../../../../../types/enums";
import { ItemIcon } from "../../ui";

type ItemTypeKey = keyof typeof ITEMTYPES;

interface ItemTypeState {
  [key: string]: boolean;
}

interface ItemFilterFormProps {
  onChange?: (values: string[]) => void;
  value?: string[];
}

const defaults = {
  [ITEMTYPES.INFORMATION.KEY]: true,
  [ITEMTYPES.MEETING.KEY]: true,
  [ITEMTYPES.VIDEO.KEY]: true,
  [ITEMTYPES.ANNOUNCEMENT.KEY]: false,
  [ITEMTYPES.ECENTRE.KEY]: true,
  [ITEMTYPES.JOURNEY.KEY]: false
};

const valueToObj = (value: string[] | undefined) => {
  const initialValue = value?.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as ItemTypeState);
  return initialValue;
};

const objToArray = (value: ItemTypeState) => {
  const result = Object.entries(value)
    .filter(([_, val]) => val === true)
    .map(([key, _]) => key);
  return result;
};

const ItemTypeFilterForm = (props: ItemFilterFormProps) => {
  const { onChange, value } = props;

  const initialValue = valueToObj(value);

  const [selectedItems, setSelectedItems] = useState<ItemTypeState | undefined>(
    initialValue
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: ItemTypeKey
  ) => {
    //setSelectedItems((prev)=>{...prev,[itemType.]:event.target.checked});
    setSelectedItems((prevState) => ({
      ...prevState,
      [ITEMTYPES[key].KEY]: event.target.checked
    }));
  };

  useEffect(() => {
    if (selectedItems) {
      const result = objToArray(selectedItems);

      onChange?.(result);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (value) {
      const initValue = valueToObj(value);
      if (!isEqual(selectedItems, initValue)) setSelectedItems(initValue);
    }
  }, [value]);
  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedItems?.[ITEMTYPES.ECENTRE.KEY] || false}
            onChange={(event) => {
              handleChange(event, ITEMTYPES.ECENTRE.KEY);
            }}
            inputProps={{ "aria-label": "Video" }}
            value={undefined}
          />
        }
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <ItemIcon name={ITEMTYPES.ECENTRE.KEY} />
            {ITEMTYPES.ECENTRE.DISPLAY}
          </Box>
        }
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedItems?.[ITEMTYPES.INFORMATION.KEY] || false}
              onChange={(event) => {
                handleChange(event, ITEMTYPES.INFORMATION.KEY);
              }}
              inputProps={{ "aria-label": "Information" }}
            />
          }
          label={
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <ItemIcon name={ITEMTYPES.INFORMATION.KEY} />
              {ITEMTYPES.INFORMATION.DISPLAY}
            </Box>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedItems?.[ITEMTYPES.MEETING.KEY] || false}
              onChange={(event) => {
                handleChange(event, ITEMTYPES.MEETING.KEY);
              }}
              inputProps={{ "aria-label": "Meeting" }}
            />
          }
          label={
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <ItemIcon name={ITEMTYPES.MEETING.KEY} />
              {ITEMTYPES.MEETING.DISPLAY}
            </Box>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedItems?.[ITEMTYPES.VIDEO.KEY] || false}
              onChange={(event) => {
                handleChange(event, ITEMTYPES.VIDEO.KEY);
              }}
              inputProps={{ "aria-label": "Video" }}
            />
          }
          label={
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <ItemIcon name={ITEMTYPES.VIDEO.KEY} />
              {ITEMTYPES.VIDEO.DISPLAY}
            </Box>
          }
        />
      </FormGroup>
    </Box>
  );
};

export default ItemTypeFilterForm;
