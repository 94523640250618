import {
  Drawer,
  Box,
  Typography,
  Button,
  Divider,
  IconButton,
  AppBar,
  Toolbar,
  useTheme,
} from "@mui/material";
//import zIndex from "@mui/material/styles/zIndex";
import { PropsWithChildren } from "react";

import CloseIcon from "@mui/icons-material/Close";

export interface IFormDrawer {
  title?: string;
  open?: boolean;
  onBeforeClose?(): boolean;
  onClose?: Function;
  onSave?: Function;
  onCancel?: Function;
  buttonValues?: {
    save?: { name?: string; disabled?: boolean };
    cancel?: { name?: string; disabled?: boolean };
  };
}

export default function FormDrawer({
  open,
  onClose,
  onBeforeClose,
  ...props
}: PropsWithChildren<IFormDrawer>) {
  const theme = useTheme();

  const handlOnBeforeClose = () => {
    return onBeforeClose?.();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          minWidth: 300,
          width: { xs: 1, s: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 },
        },
      }}
      sx={{ zIndex: theme.zIndex.drawer + 2 }}
      onClose={() => {
        if (typeof onBeforeClose === "function") {
          if (onBeforeClose?.()) {
            onClose?.();
          }
        } else {
          onClose?.();
        }
      }}
    >
      <AppBar position="sticky" sx={{ top: 0 }}>
        <Toolbar>
          <Typography variant="h5">{props.title}</Typography>

          <IconButton
            onClick={() => {
              if (typeof onBeforeClose === "function") {
                if (onBeforeClose?.()) {
                  onClose?.();
                }
              } else {
                onClose?.();
              }
            }}
            sx={{ marginLeft: "auto" }}
          >
            <CloseIcon sx={{ color: "#FFF" }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Divider></Divider>

      <Box sx={{ padding: "0.6rem", paddingBottom: "6rem" }}>
        {props.children}
      </Box>

      <Box
        sx={{
          marginTop: "auto",
          position: "sticky",
          bottom: 0,
          backgroundColor: "#FFF",
          boxShadow: "0 0 5px #333",
          zIndex: 10,
        }}
      >
        <Divider />
        <Box
          sx={{
            display: "flex",
            padding: "1rem",
            gap: "0.4rem",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              props.onCancel?.();
            }}
            disabled={props.buttonValues?.cancel?.disabled || false}
          >
            {props.buttonValues?.cancel?.name || "Cancel"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              props.onSave?.();
            }}
            disabled={props.buttonValues?.save?.disabled || false}
          >
            {props.buttonValues?.save?.name || "Save"}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
