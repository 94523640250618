import globalAtom from "./globalAtom";
import userAtom from "./userAtom";
import adminNavAtom from "./adminNavAtom";
import notificationsAtom from "./notificationsAtom";
import userAdminAtom from "./userAdminAtom";
import journeyAtom from "./journeyAtom";
import userPreferences from "./userPreferences";

export {
  adminNavAtom,
  globalAtom,
  journeyAtom,
  notificationsAtom,
  userAtom,
  userAdminAtom,
  userPreferences
};

export default globalAtom;
