import { Box } from "@mui/material";
import { isEmpty, isEqual, isNil, isNull } from "lodash";
import { useEffect, useState } from "react";
import {
  OperatorSelector,
  UserStatusSelector
} from "../../../../../components";
import { USERSTATUS, USERSTATUSOPERATOR } from "../../../../../types/enums";
import { AutocompleteItem } from "../../../../items/components";

const DEFAULT_VALUE = {
  operator_type: USERSTATUSOPERATOR.NE.KEY,
  status: USERSTATUS.COMPLETE.KEY,
  item_id: null
};

const RuleDependentForm = (props: RuleTypeFormProps) => {
  const { itemId, value, onChange, onValid, rule } = props;

  const [formValue, setFormValue] = useState<RuleDependent>(
    value || DEFAULT_VALUE
  );
  const [validFields, setValidFields] = useState({
    item_id: false,
    status: false,
    operator_type: false
  });

  const [isValid, setIsValid] = useState(false);

  const validate = () => {
    const validObj = {
      item_id: !isNil(formValue?.item_id),
      status: !isNil(formValue?.status),
      operator_type: !isNil(formValue?.operator_type)
    };

    setValidFields(validObj);
  };

  useEffect(() => {
    setIsValid(
      validFields.item_id && validFields.status && validFields.operator_type
    );
  }, [validFields]);

  useEffect(() => {
    onValid?.(isValid);
  }, [isValid]);

  // const handleOnItemSelect = (
  //   val: Partial<ItemCompleteFormProps> | undefined | null
  // ) => {
  //   //console.log("selected Item ", val);
  // };

  const handleOnItemIdSelect = (val: number) => {
    setFormValue((prev) => ({ ...prev, item_id: val }));
  };
  const handleOperatorChange = (val: string) => {
    setFormValue((prev) => ({ ...prev, operator_type: val }));
  };

  const handleStatusChange = (val: string) => {
    setFormValue((prev) => ({ ...prev, status: val }));
  };

  useEffect(() => {
    setFormValue(isEmpty(value) ? DEFAULT_VALUE : value);
  }, [value]);

  useEffect(() => {
    validate();
    onChange?.(formValue);
  }, [formValue]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <AutocompleteItem
          onChange={handleOnItemIdSelect}
          //onChangeItem={handleOnItemSelect}
          value={formValue?.item_id}
        />

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <OperatorSelector
            value={formValue?.operator_type || ""}
            onChange={handleOperatorChange}
            excludedOperators={["GE", "LE", "GT", "LT"]}
          />
          <UserStatusSelector
            value={formValue?.status || ""}
            onChange={handleStatusChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default RuleDependentForm;
