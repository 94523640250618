import { InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";

import { getGraphClient } from "../utils";
import useUpdateEffect from "./useUpdateEffect";

export default function useGraph(
  url: string,
  options?: any,
  enabled?: boolean,
  onSuccess?: (results: any) => void
): [any, boolean, (content?: any) => void, any] {
  const { instance, accounts, inProgress } = useMsal();
  //console.log("useGraph -> useMsal", instance.getActiveAccount());

  const [doRequest, setDoRequest] = useState(enabled);
  const [result, setResult] = useState<any>(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<any>(null);

  const handleDoRequest = useCallback(() => {
    setFetching(true);
    setDoRequest(true);
    setFetching(false);
  }, []);

  useEffect(() => {
    if (doRequest) {
      setFetching(true);

      const fetchGraph = async () => {
        try {
          //console.log("inProgress", inProgress);
          // if (inProgress === "none") {
          let acc = await instance.getActiveAccount(); //instance.getActiveAccount();
          //console.log("acc", acc);
          const scopes = options?.scopes || [
            "User.Read",
            process.env.REACT_APP_API_SCOPE as string //"api://0de5c018-c659-434a-9120-ff200d5eadb0/Invoke"
          ]; //protectedResources.graphMe.scopes,

          const option = {
            account: acc, //instance.getActiveAccount(),
            scopes: scopes,
            interactionType: InteractionType.Redirect // CHECK
          };
          //console.log("useGraph option", option);
          let res = await getGraphClient(option)
            .api(url)
            .header(
              options?.eventual ? "ConsistencyLevel" : "ConsistencyLevel",
              options?.eventual ? "eventual" : "eventual"
            )
            .get(); //.then((res)=>{setResult(res);setFetching(false);console.log('Get CLient Result',res);});
          //console.log("graph res", res);
          onSuccess?.(res);
          await setResult(res);
          setFetching(false);
          setDoRequest(false);
          //}
        } catch (err) {
          console.log("FetchGraph called Error");

          await setError(err);
          await setFetching(false);
          setDoRequest(false);
        }
      };

      fetchGraph();
    }
  }, [url, options, doRequest, accounts]);

  return [result, fetching, handleDoRequest, error];
}
