import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useInfiniteQuery } from "@tanstack/react-query";

interface useApiCallProps {
  name?: string[];
  api: string;
  pageParam: number;
  options?: {};
}

const loginRequests = {
  scopes: [process.env.REACT_APP_API_SCOPE as string]
};

const API_PATH = process.env.REACT_APP_API_PATH;

const useApiInfiniteCall = (props: useApiCallProps) => {
  const { name, api, options, pageParam: pageRequest } = props;

  const { instance, accounts } = useMsal();

  const queryObjInfinite = useInfiniteQuery(
    name ? [...name] : ["apiCall"],
    ({ pageParam = 1 }) => {
      const size = api.slice(api.search("size=") + 5, api.search("size=") + 6); //api.replace(new RegExp("size=(.*)&"), `aaaaaaaa`);

      const apiWithPage = api.replace(
        new RegExp("page=(.*)&"),
        `page=${Number(size) > 0 ? pageParam : 0}&`
      );
      //console.log(pageParam, apiWithPage, api, Number(size));
      //() => {
      const path = api.indexOf("http") > -1 ? api : API_PATH + api;
      const pathWithPage =
        apiWithPage.indexOf("http") > -1 ? apiWithPage : API_PATH + apiWithPage;
      //console.info("path", path, pathWithPage);
      return instance
        .acquireTokenSilent({ ...loginRequests, account: accounts[0] })
        .then((response) => {
          return axios
            .get(pathWithPage, {
              headers: {
                Authorization: "Bearer " + response.accessToken,
                Accept: "application/json"
              }
            })
            .then((res: any) => res);
        });
    },
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        // console.log(
        //   name?.[0] + "lastPage",
        //   lastPage,
        //   pages,
        //   lastPage.data.page + 1
        // );
        const flattened = pages.flatMap((pg) => pg.data.items);
        //console.log("flattened, total", flattened, lastPage.data.total);
        if (flattened.length <= lastPage.data.total) {
          //console.log("Has Pages");
          return lastPage.data.page + 1;
        }
      } //(lastPage) =>

      //lastPage !== pageRequest ? pageRequest : undefined
    }
  );

  return queryObjInfinite;
};
export default useApiInfiniteCall;
